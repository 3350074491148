import { Typography, MenuItem, Stack } from "@mui/material"
import PendingIcon from "@mui/icons-material/Pending"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

export enum EAppointmentState {
  ACCEPTED = "Accepted",
  DECLINED = "Abgelehnt",
  PENDING = "Unbestätigt",
}

export const generateAppointmentStatus = (status: string) => {
  switch (status) {
    case EAppointmentState.PENDING:
      return (
        <MenuItem value={EAppointmentState.PENDING}>
          <Stack sx={{ flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <Typography>Pending</Typography>
            <PendingIcon color="primary" fontSize="small" />
          </Stack>
        </MenuItem>
      )
    case EAppointmentState.ACCEPTED:
      return (
        <MenuItem value={EAppointmentState.ACCEPTED}>
          <Stack sx={{ flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <Typography>Accepted</Typography>
            <CheckCircleOutlineIcon fontSize="small" style={{ color: "#177321" }} />
          </Stack>
        </MenuItem>
      )
    case EAppointmentState.DECLINED:
      return (
        <MenuItem value={EAppointmentState.DECLINED}>
          <Stack sx={{ flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <Typography>Declined</Typography>
            <CancelOutlinedIcon fontSize="small" style={{ color: "#D92121" }} />
          </Stack>
        </MenuItem>
      )
    default:
      return (
        <MenuItem value={status}>
          <Stack sx={{ flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <Typography>{status}</Typography>
          </Stack>
        </MenuItem>
      )
  }
}
