export const apiUrl = import.meta.env.VITE_REACT_APP_BASE_URL as string
export const alertHint = () => {
  window.alert("Bitte deaktivieren Sie für diese Seite den Popup Blocker")
}

export const classes = {
  title: {
    marginRight: "5px",
    maxWidth: "200px",
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  chipContainer: {
    display: "flex",
  },
  badge: {
    marginRight: "4px",
    marginTop: "2px",
  },
  icon1: {
    height: "17px",
  },
  chip: {},
}
