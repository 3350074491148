import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Content } from "@frontend/extweb-lib/components/content/Content"
import FileOverview from "@frontend/extweb-lib/views/file-overview/FileOverview"
import Login from "@frontend/extweb-lib/views/login/Login"
import Details from "@frontend/extweb-lib/views/details/Details"
import Logout from "@frontend/extweb-lib/views/login/Logout"

const Skeleton: React.FC = () => {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },

      {
        path: "/auth/mfa",
        element: <Login />,
      },
      {
        path: "/auth",
        element: <Login />,
      },
      {
        path: "/overview",
        element: (
          <Content>
            <FileOverview />
          </Content>
        ),
      },
      {
        path: "/details/:fileid",
        element: (
          <Content>
            <Details />
          </Content>
        ),
      },
      {
        path: "/*",
        element: <Login />,
      },
    ],
    {
      basename: import.meta.env.BASE_URL,
    }
  )

  return <RouterProvider router={router} />
}

export default Skeleton
