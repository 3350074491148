import { messagesEn } from "@frontend/shared/contexts/language-context/translations/en"
import home from "@frontend/shared/contexts/language-context/translations/de/home"
import login from "@frontend/shared/contexts/language-context/translations/de/login"
import header from "@frontend/shared/contexts/language-context/translations/de/header"
import fileOverview from "@frontend/shared/contexts/language-context/translations/de/file-overview"
import details from "@frontend/shared/contexts/language-context/translations/de/details"

export const messagesDe: typeof messagesEn = {
  testMessage: "De",
  home,
  login: login,
  header,
  fileOverview,
  details,
}
