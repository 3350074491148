import {
  AdditonalParameter,
  AdditonalParameterMeta,
  AdditonalParameterType,
} from "@frontend/extweb-api/swagger/model/dataModel"

import { DatePicker } from "@frontend/extweb-lib/components/date-picker/DatePicker"
import { TimePicker } from "@frontend/extweb-lib/components/time-picker/TimePicker"
import { Select } from "@frontend/extweb-lib/components/select/Select"
import { format, parseISO } from "date-fns"
import { TextInput } from "@frontend/extweb-lib/components/text/TextInput"
import { TextField } from "@mui/material"
import { SeperatedDropdown } from "@frontend/extweb-lib/components/group-select/SeperatedDropdown"

export interface AdditionalFieldProps {
  fieldDefinition: AdditonalParameterMeta
  parameter: AdditonalParameter | undefined
  errors: { [key: string]: string }
  isFormChecked: boolean
  onChange: (value: string, parentId: number) => void
  readOnly?: boolean
}

export const AdditionalField = (props: AdditionalFieldProps) => {
  const label = props.fieldDefinition.Title

  const readOnly = props.readOnly
  const error = props.errors["" + props.fieldDefinition.Id]
  switch (props.fieldDefinition.ParameterType) {
    case AdditonalParameterType.Date:
      return (
        <DatePicker
          label={label + " *"}
          readOnly={readOnly}
          error={error}
          isFormChecked={props.isFormChecked}
          value={props.parameter?.Value ? parseISO(props.parameter?.Value) : undefined}
          onChange={(value) => {
            try {
              const val = value ? value.toISOString() : ""
              props.onChange(val.substring(0, 10), 0)
            } catch (e: any) {
              // not all values are valid values (e.g. incomplete timestrings)
              // .toISOString() is then not working
            }
          }}
        />
      )
    case AdditonalParameterType.Time:
      return (
        <TimePicker
          error={error}
          label={label + " *"}
          readOnly={readOnly}
          value={props.parameter?.Value || ""}
          isFormChecked={props.isFormChecked}
          onChange={(value) => {
            if (!value) {
              props.onChange("", 0)
              return
            }
            const d = parseISO(value)
            props.onChange(format(d, "HH:mm"), 0)
          }}
        />
      )
    case AdditonalParameterType.Select:
      return (
        <Select
          label={label}
          readOnly={readOnly}
          value={props.parameter?.Value || ""}
          onChange={(value) => props.onChange(value, 0)}
          error={error}
          isFormChecked={props.isFormChecked}
          items={props.fieldDefinition.Values!}
        />
      )
    case AdditonalParameterType.DoubleSelect:
      return (
        <SeperatedDropdown
          label={label}
          readOnly={readOnly}
          value={props.parameter ? props.parameter.Value : ""}
          id={props.parameter ? props.parameter.Id : 0}
          parentId={props.parameter ? props.parameter.ParentId : 0}
          errors={props.errors}
          onChange={(parentId, value) => props.onChange("" + value, parentId)}
          groupItems={props.fieldDefinition.Values!}
          isFormChecked={props.isFormChecked}
        />
      )
    case AdditonalParameterType.RichText:
      return (
        <TextInput
          label={label}
          readOnly={readOnly}
          value={props.parameter?.Value || ""}
          onChange={(value) => props.onChange(value, 0)}
          isFormChecked={props.isFormChecked}
          error={error}
          rows={5}
        />
      )
    case AdditonalParameterType.Text:
      return (
        <TextField
          variant="standard"
          fullWidth={true}
          label={label}
          required={true}
          disabled={readOnly}
          error={error !== undefined}
          helperText={!!error && error}
          value={props.parameter?.Value || ""}
          onChange={(e) => {
            props.onChange(e.target.value, 0)
          }}
        />
      )
    default:
      break
  }
}
