import React, { useState } from "react"
import { styles } from "@frontend/extweb-lib/views/details/components/dialog/DialogStyles"
import { FormControl, FormHelperText } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import { useTranslation } from "react-i18next"

interface DatePickerProps {
  label: string
  value: Date | undefined
  error: string | undefined
  onChange: (value: Date) => void
  isFormChecked?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  initialOpen?: boolean
  tabIndex?: number
  onClose?: () => void
}

export const DatePicker = ({
  label,
  value,
  onChange,
  error,
  readOnly,
  autoFocus,
  initialOpen,
  tabIndex,
  onClose,
}: DatePickerProps) => {
  const [open, setOpen] = useState(!!initialOpen)
  return (
    <FormControl fullWidth variant="standard" sx={styles.inputFieldsWidth}>
      <DesktopDatePicker
        label={label}
        format="dd.MM.yyyy"
        readOnly={readOnly}
        sx={styles.calendar}
        slotProps={{
          textField: {
            variant: "standard",
            error: typeof error === "string",
            disabled: readOnly,
            tabIndex: tabIndex,
          },
        }}
        onClose={() => {
          setOpen(false)
          onClose?.()
        }}
        onOpen={() => {
          setOpen(true)
        }}
        open={open}
        autoFocus={autoFocus}
        value={!value ? null : value}
        onChange={(value: any) => {
          onChange(value)
        }}
      />
      {!!error && <FormHelperText sx={{ color: "red" }}>{error}</FormHelperText>}
    </FormControl>
  )
}
