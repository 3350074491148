import React, { PropsWithChildren, Suspense } from "react"
import i18next, { InitOptions } from "i18next"
import { I18nextProvider } from "react-i18next"
import { LanguageContext, LanguageContextValue } from "./LanguageContext"
import { LanguageKeys } from "@frontend/shared/contexts/language-context/utils"
import { resources } from "@frontend/shared/contexts/language-context/translations/i18n"

export const defaultNS = "translation"

const i18nOptions: InitOptions = {
  resources: resources,
  lng: LanguageKeys.de,
  fallbackLng: LanguageKeys.de,
  ns: ["translation"],
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
}

interface LanguageContextProviderProps {
  language: LanguageKeys
}

class LanguageContextProvider extends React.Component<PropsWithChildren<LanguageContextProviderProps>, {}> {
  readonly i18nInstance = i18next.createInstance()
  private mounted = false
  private initLang?: LanguageKeys
  context: any

  handleLanguageChange = (language: LanguageKeys): void => {
    void this.i18nInstance.changeLanguage(language)
    if (this.mounted) {
      this.forceUpdate()
    } else {
      this.initLang = language
    }
  }

  onLanguageChangeEvent: EventListener = (event: Event): void => {
    if ("detail" in event) {
      this.handleLanguageChange((event as CustomEvent<LanguageKeys>).detail)
    }
  }

  componentDidMount(): void {
    this.mounted = true
    i18nOptions.lng = this.initLang ?? this.props.language
    void this.i18nInstance.init(i18nOptions)
  }

  render(): React.ReactNode {
    const contextObject: LanguageContextValue = {
      language: (this.i18nInstance.language as LanguageKeys) ?? LanguageKeys.en,
      setLanguage: this.handleLanguageChange,
    }

    return (
      <Suspense fallback={""}>
        <I18nextProvider i18n={this.i18nInstance}>
          <LanguageContext.Provider value={contextObject}>{this.props.children}</LanguageContext.Provider>
        </I18nextProvider>
      </Suspense>
    )
  }
}

export default LanguageContextProvider
