import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Breadcrumbs, Button, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material"
import { Title } from "@frontend/extweb-lib/components/title/Title"
import { useTranslation } from "react-i18next"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { MainTable } from "@frontend/extweb-lib/components/main-table/MainTable"
import { MainTableColumnsConfig } from "@frontend/extweb-lib/components/main-table/main-table-config"
import useTable from "@frontend/extweb-lib/hooks/useTable.hook"
import { Link as RouterLink, useParams } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import {
  AttachmentItem,
  FileListItemSchema,
  FileListResponseSchema,
  FileRecordListResponseSchema,
  PersonListItem,
  RecordItem,
  RecordType,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { LoadingInfo, LoadingMeta } from "@frontend/extweb-lib/components/LoadingMeta/LoadingMeta"
import { NewRecordDialog } from "./components/dialog/new-record-dialog/NewRecordDialog"
import { EditRecordDialog } from "@frontend/extweb-lib/views/details/components/dialog/edit-record-dialog/EditRecordDialog"
import { RecordActions } from "./components/record-actions/record-actions"
import { RecordDownload } from "@frontend/extweb-lib/views/details/components/record-download"
import { requests } from "@frontend/shared/hooks/requests"

const styles = {
  container: { background: "#FFFFFF", padding: "32px", paddingBottom: 0 },
  newHistoryContainer: { padding: "32px", paddingBottom: "16px", textAlign: "right" },
  searchContainer: { padding: "16px 0" },
  subtitle: { flexDirection: "row", gap: "8px" },
  tableContainer: {
    padding: "32px",
    paddingTop: 0,
    "& .Test": {
      "& label": {
        display: "block",
        margin: "0 0 10px 0",
        fontWeight: "bold",
      },
      "& p": {
        display: "block",
        margin: "0 0 5px 0",
      },
    },
  },
  newHistoryButton: { textTransform: "none", background: "#0048B5", height: "31px" },
  addIcon: { paddingLeft: "4px" },
}

const Details = () => {
  const params = useParams()
  const { t } = useTranslation()
  const api = useExtWebApi()

  const [newRecordCounter, setNewRecordCounter] = useState(1)
  const [editRecordCounter, setEditRecordCounter] = useState(1)
  const [recordType, setRecordType] = useState<RecordType>()
  const [fileId, setFileId] = useState<number>(0)
  const [recordId, setRecordId] = useState<number>(0)
  const [attachmentDetails, setAttachmentDetails] = useState<AttachmentItem[]>([])

  const [fileDetails, setFileDetails] = useState<FileListItemSchema>()
  const [isNewRecordDialogOpen, setIsNewRecordDialogOpen] = useState(false)
  const [isEditRecordDialogOpen, setIsEditRecordDialogOpen] = useState(false)

  const reset = 1
  const fileList = requests.useFileList(reset)
  const recordList = requests.useRecordList(reset)

  const { tableData, tableColumnsSort, handleTableSort, handleTableDataUpdate } = useTable<RecordItem>(
    recordList?.data?.Records
  )

  useEffect(() => {
    if (fileId === 0) {
      return
    }
    recordList.request({
      FileId: fileId,
      Page: 1,
      PageSize: 10000,
    })
    fileList.request(
      {
        Page: 1,
        PageSize: 1000,
        Query: {},
      },
      true
    )
  }, [fileList, fileList.request, recordList, recordList.request, fileId])

  useEffect(() => {
    if (!fileList.data?.Files || fileId === 0) {
      return
    }
    const file = fileList.data.Files.find((x) => x.FileId === fileId)
    setFileDetails(file)
  }, [fileList.data?.Files, fileId])

  useEffect(() => {
    const _fileId = parseInt(params.fileid || "0", 10)
    setFileId(_fileId)
  }, [params.fileid])

  useEffect(() => {
    if (fileId === 0) {
      return
    }
    const t = async () => {
      const details = await api.webDavFileApiService.getAttachmentDetails_POST(fileId)
      if (details.result?.attachments) {
        setAttachmentDetails(details.result.attachments)
      }
    }
    t()
  }, [api.webDavFileApiService, fileId])

  const renderRecordType = (type: RecordType) => {
    switch (type) {
      case RecordType.Contact:
        return "Kontakt"
      case RecordType.PastContact:
        return "Kontakt"
      case RecordType.Correspondence:
        return "Schriftverkehr"
      default:
        return ""
    }
  }

  const renderInvitationState = (item: PersonListItem) => {
    return null
    /*
     switch (item.invitationState) {
    case InvitationState.Accepted:
      return <CheckCircleOutlineIcon fontSize="small" style={{ color: "#177321" }} />
    case InvitationState.Pending:
      return <CancelOutlinedIcon fontSize="small" style={{ color: "#C31E1E" }} />
    case InvitationState.Declined:
      return <NotInterestedIcon fontSize="small" style={{ color: "#ADADAD" }} />
    default:
      return null
  }*/
  }
  const recordListReload = useCallback(() => {
    recordList.request(
      {
        FileId: fileId,
        Page: 1,
        PageSize: 10000,
      },
      false,
      undefined,
      true
    )
  }, [recordList, fileId])

  const dummyColumns: MainTableColumnsConfig<RecordItem>[] = useMemo(
    () => [
      {
        label: t("details.recordType"),
        withSort: true,
        columnForSort: tableColumnsSort.RecordType,
        handleSort: handleTableSort,
        key: "recordType",
        value: (item) => renderRecordType(item.RecordType),
      },
      {
        label: t("details.content"),
        key: "content",
        value: (item) => <RecordDownload fileId={fileId} recordItem={item} atachmentDetails={attachmentDetails} />,
      },
      {
        label: t("details.participants"),
        key: "participants",
        value: (item) => (
          <Stack gap="4px">
            {item?.Participants?.map((item, index) => (
              <Stack direction="row" gap="8px" alignItems="center" key={index}>
                <Stack width="152px">
                  <Typography variant="body2" fontSize="14px">
                    {item.Name}
                  </Typography>
                </Stack>
                <Stack>{renderInvitationState(item)}</Stack>
              </Stack>
            ))}
          </Stack>
        ),
      },
      {
        label: t("details.location"),
        key: "location",
        value: (item) => item.Location,
      },
      {
        label: t("details.contactForm"),
        key: "contactForm",
        value: (item) => item.ContactType?.Name ?? null,
      },
      {
        key: "edit",
        value: (item) => {
          return (
            <Stack direction={"row"}>
              <RecordActions recordItem={item} fileId={fileId} reloadRecordList={recordListReload} />
              {item.ReadOnly ? (
                <Tooltip title={t("details.editHistoryEntry")}>
                  <IconButton
                    onClick={() => {
                      setRecordType(item.RecordType)
                      setRecordId(item.RecordId)
                      setIsEditRecordDialogOpen(true)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("details.editHistoryEntry")}>
                  <IconButton
                    onClick={() => {
                      setRecordType(item.RecordType)
                      setRecordId(item.RecordId)
                      setIsEditRecordDialogOpen(true)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          )
        },
      },
    ],
    [recordListReload, attachmentDetails, fileId, handleTableSort, t, tableColumnsSort.RecordType]
  )

  if (!fileDetails) {
    return <LoadingInfo />
  }

  return (
    <Box>
      {recordType && (
        <NewRecordDialog
          isOpen={isNewRecordDialogOpen}
          fileId={fileId}
          recordType={recordType}
          fileDetails={fileDetails}
          newRecordCounter={newRecordCounter}
          close={() => {
            setIsNewRecordDialogOpen(false)
            setTimeout(() => {
              setNewRecordCounter((prev) => prev + 1)
            }, 100)
          }}
        />
      )}
      {recordType && !!recordId && (
        <EditRecordDialog
          isOpen={isEditRecordDialogOpen}
          fileId={fileId}
          editRecordCounter={editRecordCounter}
          recordType={recordType}
          recordId={recordId}
          fileDetails={fileDetails}
          close={() => {
            setEditRecordCounter((prev) => prev + 1)
            setIsEditRecordDialogOpen(false)
          }}
        />
      )}
      <Box sx={styles.container}>
        <Stack gap="8px">
          <Breadcrumbs aria-label="breadcrumb" sx={styles.subtitle}>
            <Link component={RouterLink} to="/" color="rgba(0, 0, 0, 0.60)">
              {t("fileOverview.fileOverview")}
            </Link>
            <Link component={RouterLink} to={"/details/" + fileDetails?.FileId} color="rgba(0, 0, 0, 0.60)">
              {fileDetails?.FileNumber}
            </Link>
            <Typography color="text.primary"> {t("details.progress")}</Typography>
          </Breadcrumbs>

          <Title
            text={`Akte ${fileDetails.FileNumber} | ${fileDetails.ClientDetails?.LastName || ""}, ${
              fileDetails.ClientDetails?.FirstName || ""
            }`}
          />
        </Stack>
        {/* <Box sx={styles.searchContainer}>
          <Search />
        </Box> */}
      </Box>
      <Box sx={styles.newHistoryContainer}>
        <Button
          variant="contained"
          sx={styles.newHistoryButton}
          onClick={() => {
            setRecordType(RecordType.PastContact)
            setRecordId(0)
            setIsNewRecordDialogOpen(true)
          }}
        >
          {t("details.createNewHistoryEntry")}
          <AddCircleOutlineIcon sx={styles.addIcon} />
        </Button>
      </Box>
      <Box sx={styles.tableContainer}>
        <LoadingMeta event={recordList.event} />
        {recordList.event.isLoaded && <MainTable data={tableData} columns={dummyColumns} groupRendering={true} />}
      </Box>
    </Box>
  )
}

export default Details
