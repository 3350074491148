import React from "react"
import MuiTableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { MainTableRowProps } from "./main-table-config"

const styles = {
  firstColumn: { fontSize: "14px" },
  firstColumnColored: {
    fontWeight: 700,
    color: "#0048B5",
  },
}

const MainTableRow = <T,>({ item, columns, onRowClickNavigation }: MainTableRowProps<T>) => {
  return (
    <MuiTableRow
      sx={onRowClickNavigation && { cursor: "pointer" }}
      onClick={() => {
        onRowClickNavigation?.(item)
      }}
    >
      {columns.map((columnItem, index) => (
        <TableCell
          key={columnItem.key}
          sx={{
            ...styles.firstColumn,
            ...(index === 0 ? { ...styles.firstColumnColored } : undefined),
          }}
        >
          {columnItem.value(item)}
        </TableCell>
      ))}
    </MuiTableRow>
  )
}

export default MainTableRow
