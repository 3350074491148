import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"
import CircularProgress from "@mui/material/CircularProgress"
import { green } from "@mui/material/colors"
import { useNavigate, useSearchParams } from "react-router-dom"
import { matchPath } from "react-router"
import { getBaseName } from "@frontend/shared/utils/path.utils"
import { authConstants, useExtWebUserContext } from "@frontend/extweb-lib/contexts/user-context/ExtWebUserContext"

const useStyles = makeStyles()((theme) => ({
  forgotPassword: {
    color: "#0048B5",
    fontSize: 12,
    marginTop: 4,
    cursor: "pointer",
  },
  loginButton: {
    width: "100%",
    textTransform: "none",
    fontSize: 13,
  },
}))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginInputsProps {}

const expired = document.location.search.includes("expired")

const LoginInputs: React.FC<LoginInputsProps> = (props: LoginInputsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [stayLoggedInChecked, setStayLoggedInChecked] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState(false)
  const { doLogin, mafResult, loading, reset, isLoggedIn, stayLoginDetails, removeStayLogin } = useExtWebUserContext()
  const usernameRef = useRef<HTMLInputElement>()
  const stayUsernameRef = useRef<HTMLInputElement>()
  const passwordRef = useRef<HTMLInputElement>()
  const stayLoggedInRef = useRef<HTMLInputElement>()
  const stayLoggedIn = stayLoginDetails?.username && stayLoginDetails?.token

  let isMfa = false
  const path = (getBaseName() + "/auth/:mfa").replace("//", "/")
  const pathParams = matchPath(path, window.location.pathname)
  if (pathParams?.params?.mfa === "mfa") {
    isMfa = true
  }

  useEffect(() => {
    localStorage.removeItem(authConstants.localStorageLogoutAsked)

    if (!isLoggedIn) {
      return
    }
    const path = searchParams.get("success")
    if (path) {
      navigate(path)
      return
    }

    navigate("/overview")
  }, [navigate, isLoggedIn, searchParams])

  useEffect(() => {
    if (usernameRef.current) {
      if (!usernameRef.current.value) {
        //usernameRef.current.value = "HURichter"
      }
    }
    if (passwordRef.current) {
      if (!passwordRef.current.value) {
        //passwordRef.current.value = "Gr&F7hpg8a"
      }
    }
  }, [usernameRef, passwordRef])

  const loginAction = async () => {
    const username = usernameRef.current?.value
    const password = passwordRef.current?.value
    const stayLogged = stayLoggedInRef.current?.checked ?? false

    const result = await doLogin(username, password, stayLogged)
    const mfaResult = result.mfaResult
    if (mfaResult.sessionId) {
      let p = "/auth/mfa"
      if (window.location.search) {
        p = p + window.location.search
      }
      navigate(p)
    }
  }
  const backToLogin = async () => {
    navigate("/auth")
    await reset()
  }

  if (mafResult?.state && isMfa) {
    return (
      <div>
        <Box>
          {!loading && mafResult.state.pending && <span>{t("login.pendingMessage")}</span>}
          <Box>{mafResult.code}</Box>
          {!!mafResult.state.errorMessage && (
            <>
              <span>{mafResult.state.errorMessage}</span>
              <Button
                disabled={loading}
                variant="contained"
                color={"primary"}
                className={classes.loginButton}
                onClick={() => {
                  backToLogin()
                }}
              >
                {t("login.backToLogin")}
              </Button>
            </>
          )}
        </Box>
      </div>
    )
  }
  if (isMfa) {
    return <div></div>
  }

  if (stayLoggedIn) {
    return (
      <form
        onSubmit={(evt) => {
          evt.stopPropagation()
          evt.preventDefault()
          void loginAction()
        }}
      >
        <div>
          {expired && <div className={"session-expired"}>{t("login.sessionExpired")}</div>}
          <FormControl sx={{}} variant="standard" fullWidth={true}>
            <InputLabel htmlFor="standard-adornment-stausername">{t("login.username")}</InputLabel>
            <Input
              disabled={true}
              id={"stayUsername"}
              name={"stayUsername"}
              key={"stayUsername"}
              inputRef={stayUsernameRef}
              defaultValue={stayLoginDetails?.username}
              fullWidth={true}
              sx={{ marginTop: "10px" }}
            />
          </FormControl>
          <FormControlLabel
            inputRef={stayLoggedInRef}
            checked={stayLoggedInChecked}
            onChange={(e) => {
              setStayLoggedInChecked(!stayLoggedInChecked)
            }}
            control={<Checkbox />}
            label={t("login.stayLoggedIn")}
          />
          <Box
            sx={{
              position: "relative",
              marginTop: "40px",
            }}
          >
            <Button
              disabled={loading}
              variant="contained"
              color={"primary"}
              className={classes.loginButton}
              type={"submit"}
            >
              {t("login.logInActionButton")}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            {!loading && (
              <>
                <br /> <br />
                <Button
                  color={"primary"}
                  className={classes.loginButton}
                  onClick={() => {
                    if (usernameRef.current) {
                      usernameRef.current.value = ""
                    }
                    void removeStayLogin()
                  }}
                >
                  {t("login.switchUser")}
                </Button>
              </>
            )}
          </Box>
          {!!mafResult?.state && (
            <Box sx={{ paddingTop: "8px" }}>
              {!loading && mafResult.state.pending && <span>Pending</span>}
              {!loading && !mafResult.state.pending && <span>{mafResult.state.errorMessage}</span>}
            </Box>
          )}
        </div>
      </form>
    )
  }
  return (
    <form
      onSubmit={(evt) => {
        evt.stopPropagation()
        evt.preventDefault()
        void loginAction()
      }}
    >
      <div>
        <FormControl sx={{}} variant="standard" fullWidth={true}>
          <InputLabel htmlFor="standard-adornment-password">{t("login.username")}</InputLabel>
          <Input
            id={"username"}
            name={"username"}
            key={"username"}
            disabled={loading}
            inputRef={usernameRef}
            defaultValue=""
            fullWidth={true}
            sx={{ marginTop: "10px" }}
          />
        </FormControl>
        <FormControl sx={{ marginTop: "10px" }} fullWidth={true} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">{t("login.password")}</InputLabel>
          <Input
            inputRef={passwordRef}
            disabled={loading}
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((show) => !show)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControlLabel
          inputRef={stayLoggedInRef}
          disabled={loading}
          control={<Checkbox />}
          label={t("login.stayLoggedIn")}
        />
        <Box
          sx={{
            position: "relative",
            marginTop: "40px",
          }}
        >
          <Button
            disabled={loading}
            variant="contained"
            color={"primary"}
            className={classes.loginButton}
            type={"submit"}
          >
            {t("login.logInActionButton")}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
        {!!mafResult?.state && (
          <Box sx={{ paddingTop: "8px" }}>
            {!loading && mafResult.state.pending && <span>Pending</span>}
            {!loading && !mafResult.state.pending && <span>{mafResult.state.errorMessage}</span>}
          </Box>
        )}
      </div>
    </form>
  )
}

export default LoginInputs
