import React from "react"
import Skeleton from "./views/skeleton/Skeleton"
import LanguageContextProvider from "@frontend/shared/contexts/language-context/LanguageContextProvider"
import { LanguageKeys } from "@frontend/shared/contexts/language-context/utils"
import { ScopedCssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material"
import extWebTheme from "@frontend/shared/theme"
import { ExtWebApiProvider } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { EventQueueProvider } from "@frontend/shared/contexts/event-queue-context/EventQueueContext"
import { ExtWebUserProvider } from "@frontend/extweb-lib/contexts/user-context/ExtWebUserContext"
import ConfirmationDialogContextProvider from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContextProvider"

const App: React.FC = () => {
  return (
    <LanguageContextProvider language={LanguageKeys.de}>
      <ThemeProvider theme={extWebTheme}>
        <StyledEngineProvider injectFirst>
          <ScopedCssBaseline style={{ height: "100%", backgroundColor: "white" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ExtWebApiProvider>
                <EventQueueProvider>
                  <ConfirmationDialogContextProvider>
                    <ExtWebUserProvider>
                      <Skeleton />
                    </ExtWebUserProvider>
                  </ConfirmationDialogContextProvider>
                </EventQueueProvider>
              </ExtWebApiProvider>
            </LocalizationProvider>
          </ScopedCssBaseline>
        </StyledEngineProvider>
      </ThemeProvider>
    </LanguageContextProvider>
  )
}

export default App
