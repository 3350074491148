import { Autocomplete, FormControl, Grid, Stack, TextField } from "@mui/material"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { styles } from "../DialogStyles"
import { CreateCorrespondenceListItems, UpsertCorrespondenceRecord } from "@frontend/extweb-api/swagger/model/dataModel"
import { ValidationSimpleResults } from "@frontend/shared/validation/base-form-validation/base-form-validation"
import { TimePicker } from "../../../../../components/time-picker/TimePicker"
import { DatePicker } from "../../../../../components/date-picker/DatePicker"
import { TextEditor } from "../../../../../components/text-editor/TextEditor"
import { add, format, parse, parseISO } from "date-fns"
import { AdditionalErrors, DateState } from "../../../models"
import { useTranslation } from "react-i18next"

interface CorrespondenceFormDocumentProps {
  selections: CreateCorrespondenceListItems
  setDateState: React.Dispatch<React.SetStateAction<DateState>>

  correspondenceForm: UpsertCorrespondenceRecord
  setCorrespondenceForm: Dispatch<SetStateAction<UpsertCorrespondenceRecord>>
  correspondenceFormErrors: ValidationSimpleResults<UpsertCorrespondenceRecord & AdditionalErrors>
  readOnly?: boolean
  fromToRequired?: boolean
  clientName: string | undefined
}

export const CorrespondenceForm = (props: CorrespondenceFormDocumentProps) => {
  const [fromDate, setFromDate] = useState<Date>()
  const [fromTime, setFromTime] = useState<string>()
  const [toTime, setToTime] = useState<string>()
  const [toTimeChanged, setToTimeChanged] = useState(false)
  const { readOnly, setCorrespondenceForm } = props

  const { t } = useTranslation()
  const timeInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    const from = parseISO(props.correspondenceForm.FromDateUtc)
    if (!isNaN(from.getTime())) {
      setFromTime(format(from, "HH:mm"))
      setFromDate(from)
    }
    const to = parseISO(props.correspondenceForm.ToDateUtc)
    if (!isNaN(to.getTime())) {
      setToTime(format(to, "HH:mm"))
    }
  }, [props.correspondenceForm?.FromDateUtc, props.correspondenceForm?.ToDateUtc])

  const modifyCorrespondenceForm = useCallback(
    (values: Partial<UpsertCorrespondenceRecord>) => {
      setCorrespondenceForm((prev) => ({
        ...prev,
        ...values,
      }))
    },
    [setCorrespondenceForm]
  )

  useEffect(() => {
    modifyCorrespondenceForm({ Title: props.clientName })
  }, [modifyCorrespondenceForm, props.clientName])

  return (
    <Stack sx={styles.thirdStepContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                tabIndex={10}
                fullWidth={true}
                required={true}
                variant="standard"
                disabled={readOnly}
                error={!!props.correspondenceFormErrors.Title}
                helperText={!!props.correspondenceFormErrors.Title && props.correspondenceFormErrors.Title}
                label={t("details.titel")}
                value={props.correspondenceForm.Title || ""}
                onChange={(e) => {
                  props.setCorrespondenceForm((prev) => ({
                    ...prev,
                    Title: e.target.value,
                  }))
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            tabIndex={20}
            initialOpen={!fromDate}
            autoFocus={true}
            readOnly={readOnly}
            error={props.correspondenceFormErrors["FromDateUtc"]}
            label={t("details.date") + " *"}
            value={fromDate}
            onClose={() => {
              if (timeInputRef.current) {
                setTimeout(() => {
                  timeInputRef.current?.focus()
                }, 100)
              }
            }}
            onChange={(value) => {
              setFromDate(value)
              props.setDateState((prev) => ({
                ...prev,
                fromDate: value,
              }))
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            tabIndex={30}
            inputRef={timeInputRef}
            label={t("details.from") + (props.fromToRequired ? " *" : "")}
            readOnly={readOnly}
            error={props.correspondenceFormErrors["FromTimeUtc"]}
            value={fromTime || ""}
            onChange={(value) => {
              setFromTime(value)
              props.setDateState((prev) => ({
                ...prev,
                fromTime: value,
              }))
              let _toTime: string | undefined
              if (value) {
                if (!toTimeChanged) {
                  const ftime = parse(value, "HH:mm", new Date())
                  const toTime = add(ftime, {
                    hours: 1,
                  })
                  _toTime = format(toTime, "HH:mm")
                  setToTime(_toTime)
                  props.setDateState((prev) => ({
                    ...prev,
                    toTime: _toTime,
                  }))
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            tabIndex={40}
            label={t("details.to") + (props.fromToRequired ? " *" : "")}
            readOnly={readOnly}
            error={props.correspondenceFormErrors["ToTimeUtc"]}
            value={toTime || ""}
            onChange={(value) => {
              setToTime(value)
              props.setDateState((prev) => ({
                ...prev,
                toTime: value,
              }))
              setToTimeChanged(true)
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              value={
                props.correspondenceForm?.LocationId
                  ? {
                      Id: props.correspondenceForm?.LocationId || 0,
                      Name:
                        props.selections.Locations?.find((x) => x.Id == props.correspondenceForm?.LocationId)?.Name ||
                        "",
                    }
                  : null
              }
              disabled={readOnly}
              onChange={(e, value) => modifyCorrespondenceForm({ LocationId: value ? value.Id : 0 })}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(a, b) => a.Id === b.Id}
              options={props.selections.Locations || []}
              tabIndex={50}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={false}
                  variant="standard"
                  label={t("details.location")}
                  placeholder={t("details.location")}
                  error={!!props.correspondenceFormErrors.LocationId}
                  helperText={!!props.correspondenceFormErrors.LocationId && props.correspondenceFormErrors.LocationId}
                />
              )}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              disabled={readOnly}
              value={
                props.correspondenceForm?.ContactTypeId
                  ? {
                      Id: props.correspondenceForm?.ContactTypeId || 0,
                      Name:
                        props.selections.ContactTypes?.find((x) => x.Id == props.correspondenceForm?.ContactTypeId)
                          ?.Name || "",
                    }
                  : null
              }
              onChange={(e, value) => modifyCorrespondenceForm({ ContactTypeId: value ? value.Id : 0 })}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(a, b) => a.Id === b.Id}
              options={props.selections.ContactTypes || []}
              tabIndex={60}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  variant="standard"
                  label={t("details.contactForm")}
                  placeholder={t("details.contactForm")}
                  error={!!props.correspondenceFormErrors.ContactTypeId}
                  helperText={
                    !!props.correspondenceFormErrors.ContactTypeId && props.correspondenceFormErrors.ContactTypeId
                  }
                />
              )}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              readOnly={readOnly}
              disabled={readOnly}
              value={
                props.correspondenceForm?.SupervisorId
                  ? {
                      PersonId: props.correspondenceForm?.SupervisorId,
                      Name:
                        props.selections.Supervisors?.find((x) => x.PersonId == props.correspondenceForm?.SupervisorId)
                          ?.Name || "",
                    }
                  : null
              }
              onChange={(e, value) => modifyCorrespondenceForm({ SupervisorId: value ? value.PersonId : 0 })}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(a, b) => a.PersonId === b.PersonId}
              options={props.selections.Supervisors || []}
              tabIndex={70}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  variant="standard"
                  label={t("details.supervisor")}
                  placeholder={t("details.supervisor")}
                  error={!!props.correspondenceFormErrors.SupervisorId}
                  helperText={
                    !!props.correspondenceFormErrors.SupervisorId && props.correspondenceFormErrors.SupervisorId
                  }
                />
              )}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
            />
          </FormControl>
        </Grid>
      </Grid>
      <FormControl variant="standard" sx={styles.textarea}>
        <TextEditor
          tabIndex={80}
          readOnly={readOnly}
          error={undefined}
          isFormChecked={false}
          label={"Inhalt"}
          withToolbar={true}
          value={props.correspondenceForm?.ContentBody}
          onChange={(value) => modifyCorrespondenceForm({ ContentBody: value })}
        />
      </FormControl>
    </Stack>
  )
}
