import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Stack,
  Tab,
  Tabs,
  FormControlLabel,
  TextField,
  Typography,
  Checkbox,
  InputAdornment,
  Input,
  DialogActions,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { styles } from "../DialogStyles"
import { ValidationSimpleResults } from "@frontend/shared/validation/base-form-validation/base-form-validation"
import { TextEditor } from "@frontend/extweb-lib/components/text-editor/TextEditor"
import {
  CreateContactListItems,
  CreateCorrespondenceListItems,
  FileListItemSchema,
  FileRecordListResponseSchema,
  RecordType,
  SendToAppResponseSchema,
  UpsertContactRecord,
  UpsertContactResponseSchema,
  UpsertCorrespondenceRecord,
  UpsertCorrespondenceResponseSchema,
  ViewContactResponseSchema,
  ViewCorrespondenceResponseSchema,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { Title } from "@frontend/extweb-lib/components/title/Title"
import { LoadingMeta } from "@frontend/extweb-lib/components/LoadingMeta/LoadingMeta"

import { ParticipantSelect } from "@frontend/extweb-lib/views/details/components/participant-select/ParticipantSelect"
import { format, parseISO } from "date-fns"
import { DatePicker } from "@frontend/extweb-lib/components/date-picker/DatePicker"
import { TimePicker } from "@frontend/extweb-lib/components/time-picker/TimePicker"
import {
  getUpsertContactRecordFromView,
  getUpsertCorrespondenceRecordFromView,
} from "@frontend/extweb-lib/views/details/components/dialog/new-record-dialog/form.utils"
import {
  AdditionalErrors,
  CorrespondenceAndContact,
  CorrespondenceAndContactView,
  DateState,
  GeneralListItems,
} from "../../../models"
import {
  validateEditContactForm,
  validateEditCorrespondenceForm,
} from "@frontend/extweb-lib/views/details/components/dialog/new-record-dialog/validate"
import { MyFileUploader } from "@frontend/extweb-lib/components/file-upload/file-upload"
import {
  hasError,
  isObjectEmpty,
  parseGenericError,
} from "@frontend/extweb-lib/views/details/components/dialog/Dialog.utils"
import {
  EAppointmentState,
  generateAppointmentStatus,
} from "@frontend/extweb-lib/views/details/components/dialog/edit-record-dialog/EditRecord.utils"
import EventIcon from "@mui/icons-material/Event"
import { useConfirmationDialogContext } from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"

export interface EditRecordProps {
  isOpen: boolean
  close: () => void
  recordType: RecordType
  fileId: number
  editRecordCounter: number
  recordId: number
  fileDetails: FileListItemSchema
  setCloseable?: (closeable: boolean) => void
}

const tabs = ["fileOverview.involved", "header.contactDocumentation"]

export const EditRecord = (props: EditRecordProps) => {
  const dialogRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(1)
  const { showYesNoDialog } = useConfirmationDialogContext()

  const api = useExtWebApi()

  const [contactDocumentationHasError, setContactDocumentationHasError] = useState(false)
  const [involvedHasError, setInvolvedHasError] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [fromDate, setFromDate] = useState<Date>()
  const [fromTime, setFromTime] = useState<string>()
  const [toTime, setToTime] = useState<string>()
  const [isFormChecked, setIsFormChecked] = useState(false)

  const [contactListItems, setContactListItems] = useState<CreateContactListItems>({} as any)
  const [correspondenceListItems, setCorrespondenceListItems] = useState<CreateCorrespondenceListItems>({} as any)
  const [contactForm, setContactForm] = useState<UpsertContactRecord>({} as any)
  const [correspondenceForm, setCorrespondenceForm] = useState<UpsertCorrespondenceRecord>({} as any)

  const [contactFormErrors, setContactFormErrors] = useState<ValidationSimpleResults<UpsertContactRecord>>({} as any)
  const [correspondenceFormErrors, setCorrespondenceFormErrors] = useState<
    ValidationSimpleResults<UpsertCorrespondenceRecord>
  >({} as any)

  const reset = props.editRecordCounter

  const [contactResponse, requestContact] = useSync(
    reset,
    api.contactApiService,
    api.contactApiService.getViewContact_POST,
    null as unknown as ViewContactResponseSchema
  )

  const [correspondenceResponse, requestCorrespondence] = useSync(
    reset,
    api.correspondenceApiService,
    api.correspondenceApiService.viewCorrespondence_POST,
    null as unknown as ViewCorrespondenceResponseSchema
  )

  const [updateContactResponse, requestUpdateContact] = useSync(
    reset,
    api.contactApiService,
    api.contactApiService.upsertContact_POST,
    null as unknown as UpsertContactResponseSchema
  )

  const [updateCorrespondenceResponse, requestUpdateCorrespondence] = useSync(
    reset,
    api.correspondenceApiService,
    api.correspondenceApiService.upsertCorrespondence_POST,
    null as unknown as UpsertCorrespondenceResponseSchema
  )
  const [recordList, loadRecordList] = useSync(
    reset,
    api.fileRecordListApiService,
    api.fileRecordListApiService.getFileRecordList_POST,
    null as unknown as FileRecordListResponseSchema
  )

  const [appointmentAcceptResponse, doAppointmentAccept] = useSync(
    reset,
    api.contactApiService,
    api.contactApiService.acceptAppointment_POST,
    null as unknown as SendToAppResponseSchema
  )

  const [deleteRecordResponse, requestDeleteRecord] = useSync(
    reset,
    api.fileRecordListApiService,
    api.fileRecordListApiService.deleteRecord_POST,
    null as unknown as SendToAppResponseSchema
  )

  const showContactForm = props.recordType === RecordType.Contact || props.recordType === RecordType.PastContact

  useEffect(() => {
    if (!props.isOpen) {
      return
    }
    if (showContactForm) {
      requestContact(
        {
          FileId: props.fileId,
          RecordId: props.recordId,
        },
        false,
        undefined,
        true
      )
    } else {
      requestCorrespondence(
        {
          FileId: props.fileId,
          RecordId: props.recordId,
          TemplateId: 0, // zero for existing records
        },
        false,
        undefined,
        true
      )
    }
    setIsInitialized(true)
  }, [props.isOpen, showContactForm, props.fileId, props.recordId, requestCorrespondence, requestContact])

  useEffect(() => {
    if (!contactResponse.data?.Contact) {
      return
    }

    const newForm = getUpsertContactRecordFromView(contactResponse.data.Contact)
    setContactForm(newForm)
    setContactListItems(contactResponse.data?.ListItems || {})
  }, [contactResponse.data])

  useEffect(() => {
    if (!correspondenceResponse.data?.Correspondence) {
      return
    }
    const newForm = getUpsertCorrespondenceRecordFromView(correspondenceResponse.data.Correspondence)
    setCorrespondenceForm(newForm)
    setCorrespondenceListItems(correspondenceResponse.data?.ListItems || {})
  }, [correspondenceResponse.data])

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setSelectedTab(value)
  }

  const form = (showContactForm ? contactForm : correspondenceForm) as CorrespondenceAndContact
  const formView = (
    showContactForm ? contactResponse.data?.Contact : correspondenceResponse.data?.Correspondence
  ) as CorrespondenceAndContactView
  const formErrors = (showContactForm ? contactFormErrors : correspondenceFormErrors) as ValidationSimpleResults<
    CorrespondenceAndContact & AdditionalErrors
  >
  const generalListItems = (showContactForm ? contactListItems : correspondenceListItems) as GeneralListItems

  useEffect(() => {
    const from = parseISO(form.FromDateUtc)
    if (!isNaN(from.getTime())) {
      setFromTime(format(from, "HH:mm"))
      setFromDate(from)
    }
    const to = parseISO(form.ToDateUtc)
    if (!isNaN(to.getTime())) {
      setToTime(format(to, "HH:mm"))
    }
  }, [form?.FromDateUtc, form?.ToDateUtc])

  useEffect(() => {
    if (
      updateCorrespondenceResponse.data?.Success ||
      updateContactResponse.data?.Success ||
      deleteRecordResponse.data.Success
    ) {
      // reload table
      loadRecordList(
        {
          FileId: props.fileId,
          Page: 1,
          PageSize: 10000,
        },
        false,
        undefined,
        true
      )
    }
  }, [
    loadRecordList,
    props.fileId,
    updateCorrespondenceResponse.data?.Success,
    updateContactResponse.data?.Success,
    deleteRecordResponse.data.Success,
  ])

  const renderTabs = () => {
    return (
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: "divider" }}>
        {tabs.map((tab) => {
          let _hasError = false
          if (
            (tab === "fileOverview.involved" && involvedHasError) ||
            (tab === "header.contactDocumentation" && contactDocumentationHasError)
          ) {
            _hasError = true
          }
          return (
            <Tab
              key={tab}
              label={t(tab as any)}
              sx={{ textTransform: "none", color: _hasError ? "#d32f2f !important" : undefined }}
            />
          )
        })}
      </Tabs>
    )
  }
  const modifyForm = useCallback(
    (partial: Partial<CorrespondenceAndContact>) => {
      if (showContactForm) {
        setContactForm((prev) => ({
          ...prev,
          ...partial,
        }))
        return
      } else {
        setCorrespondenceForm((prev) => ({
          ...prev,
          ...partial,
        }))
      }
    },
    [showContactForm]
  )

  const modifyCb = (showContactForm ? setContactForm : setCorrespondenceForm) as Dispatch<
    SetStateAction<CorrespondenceAndContact>
  >

  const isUpdateLoading =
    updateContactResponse?.isLoading || updateCorrespondenceResponse.isLoading || deleteRecordResponse?.isLoading
  const isUpdateLoaded = updateContactResponse?.isLoaded || updateCorrespondenceResponse.isLoaded
  const isSuccess =
    updateContactResponse?.data?.Success ||
    updateCorrespondenceResponse?.data?.Success ||
    deleteRecordResponse.data.Success
  const readOnly = isUpdateLoading || correspondenceResponse.data.Correspondence?.ReadOnly
  const isEntityLoading = contactResponse.isLoading || correspondenceResponse.isLoading
  const closeable = !isUpdateLoading

  const setCloseable = props.setCloseable
  useEffect(() => {
    setCloseable?.(closeable)
  }, [setCloseable, closeable])

  const onSaveEdit = () => {
    const datePicker: DateState = {
      fromDate: fromDate,
      fromTime: fromTime,
      toTime: toTime,
    }
    if (showContactForm) {
      const validateFormResult = validateEditContactForm(t, contactForm, datePicker)
      const contactNewForm = { ...contactForm }

      if (!isObjectEmpty(validateFormResult._contactFormErrors)) {
        setContactFormErrors(validateFormResult._contactFormErrors)
        setContactDocumentationHasError(true)
      } else {
        setContactFormErrors({})
        setContactDocumentationHasError(false)
        contactNewForm.FromDateUtc = validateFormResult.fromToResult!.fromUtc
        contactNewForm.ToDateUtc = validateFormResult.fromToResult!.toUtc

        requestUpdateContact(
          {
            Contact: contactNewForm,
            RecordType: RecordType.PastContact,
          },
          false
        )
      }
    } else {
      const validateFormResult = validateEditCorrespondenceForm(t, correspondenceForm, datePicker)

      const correspondenceNewForm = { ...correspondenceForm }
      if (!isObjectEmpty(validateFormResult._correspondenceFormErrors)) {
        setCorrespondenceFormErrors(validateFormResult._correspondenceFormErrors)
        setContactDocumentationHasError(true)
      } else {
        setContactDocumentationHasError(false)
        correspondenceNewForm.FromDateUtc = validateFormResult.fromToResult!.fromUtc
        correspondenceNewForm.ToDateUtc = validateFormResult.fromToResult!.toUtc

        requestUpdateCorrespondence(
          {
            Correspondence: correspondenceNewForm,
            IsAppointmentInvite: false,
          },
          false
        )
      }
    }

    if (dialogRef.current) {
      dialogRef.current.scrollTop = 0
    }
  }

  const deleteRecord = () => {
    if (dialogRef.current) {
      dialogRef.current.scrollTop = 0
    }

    if (props.recordType === RecordType.Contact || props.recordType === RecordType.PastContact) {
      requestDeleteRecord({ RecordId: contactForm.RecordId })
    } else {
      requestDeleteRecord({ RecordId: correspondenceForm.RecordId })
    }
  }

  const renderAlert = () => {
    if (showContactForm) {
      return renderAlertContact()
    }
    return renderAlertCorrespondence()
  }

  const renderAlertCorrespondence = () => {
    if (deleteRecordResponse?.isLoading) {
      return (
        <>
          <Alert severity="info">{t("details.formSaving")}</Alert>
          <LoadingMeta eventMeta={updateCorrespondenceResponse} noDetailsBox={true} />
        </>
      )
    }
    if (updateCorrespondenceResponse?.isLoading) {
      return (
        <>
          <Alert severity="info">{t("details.formSaving")}</Alert>
          <LoadingMeta eventMeta={updateCorrespondenceResponse} noDetailsBox={true} />
        </>
      )
    } else if (!hasError(updateCorrespondenceResponse.data?.Error) && updateCorrespondenceResponse?.data?.Success) {
      return <Alert severity="success">{t("details.successSave")}</Alert>
    } else if (!hasError(deleteRecordResponse.data?.ErrorMessage) && deleteRecordResponse?.data?.Success) {
      return <Alert severity="success">{t("details.successSave")}</Alert>
    } else if (hasError(deleteRecordResponse?.data?.ErrorMessage)) {
      return <Alert severity="error">{deleteRecordResponse.data.ErrorMessage}</Alert>
    } else if (hasError(updateCorrespondenceResponse.data?.Error)) {
      return (
        <Alert severity="error">
          {t("details.unexpectedError")}
          {parseGenericError(updateCorrespondenceResponse.data.Error)}
        </Alert>
      )
    }
  }

  const renderAlertContact = () => {
    if (deleteRecordResponse?.isLoading) {
      return (
        <>
          <Alert severity="info">{t("details.formSaving")}</Alert>
          <LoadingMeta eventMeta={updateCorrespondenceResponse} noDetailsBox={true} />
        </>
      )
    }
    if (updateContactResponse?.isLoading) {
      return (
        <>
          <Alert severity="info">{t("details.formSaving")}</Alert>
          <LoadingMeta eventMeta={updateContactResponse} noDetailsBox={true} />
        </>
      )
    } else if (!hasError(updateContactResponse.data?.Error) && updateContactResponse?.data?.Success) {
      return <Alert severity="success">{t("details.successSave")}</Alert>
    } else if (!hasError(deleteRecordResponse.data?.ErrorMessage) && deleteRecordResponse?.data?.Success) {
      return <Alert severity="success">{t("details.successSave")}</Alert>
    } else if (hasError(deleteRecordResponse?.data?.ErrorMessage)) {
      return <Alert severity="error">{deleteRecordResponse.data.ErrorMessage}</Alert>
    } else if (hasError(updateContactResponse.data?.Error)) {
      return (
        <Alert severity="error">
          {t("details.unexpectedError")}
          {parseGenericError(updateContactResponse.data.Error)}
        </Alert>
      )
    }
  }

  const renderFirstTab = () => {
    if (showContactForm) {
      if (contactResponse.isLoading) {
        return <LoadingMeta eventMeta={contactResponse} noDetailsBox={true} />
      }
      if (contactResponse.data) {
        return (
          <Stack sx={{ ...styles.stepContent, paddingTop: "0" }}>
            <ParticipantSelect
              readOnly={readOnly}
              hideParticipantSelection={true}
              error={contactFormErrors["Participants"]}
              selections={contactResponse.data?.ListItems?.Participants || []}
              participants={contactForm.Participants || []}
              changeParticipantStatus={true}
              setParticipants={(item) => {
                setContactForm((prev) => ({
                  ...prev,
                  Participants: item,
                }))
              }}
            />
          </Stack>
        )
      }
    }

    if (!showContactForm) {
      if (correspondenceResponse.isLoading) {
        return <LoadingMeta eventMeta={correspondenceResponse} noDetailsBox={true} />
      }
      if (correspondenceResponse.data) {
        return (
          <Stack sx={{ ...styles.stepContent, paddingTop: "0" }}>
            <ParticipantSelect
              readOnly={readOnly}
              error={correspondenceFormErrors["Participants"]}
              changeParticipantStatus={true}
              hideParticipantSelection={true}
              selections={correspondenceResponse.data.Template?.Participants || []}
              participants={correspondenceForm.Participants || []}
              setParticipants={(item) => {
                setCorrespondenceForm((prev) => ({
                  ...prev,
                  Participants: item,
                }))
              }}
            />
          </Stack>
        )
      }
    }
    return null
  }

  const renderSecondTab = () => {
    if (showContactForm) {
      if (contactResponse.isLoading) {
        return <LoadingMeta eventMeta={contactResponse} noDetailsBox={true} />
      }
    }

    if (!showContactForm) {
      if (correspondenceResponse.isLoading) {
        return <LoadingMeta eventMeta={correspondenceResponse} noDetailsBox={true} />
      }
    }

    return (
      <Stack sx={{ ...styles.thirdStepContainer, padding: "0 32px 28px 32px" }}>
        <Grid container>
          <Grid xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DatePicker
                  readOnly={readOnly}
                  error={formErrors["FromDateUtc"]}
                  label={t("details.date") + " *"}
                  value={fromDate}
                  onChange={(value) => {
                    setFromDate(value)
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  readOnly={readOnly}
                  label={t("details.from") + " *"}
                  error={formErrors["FromTimeUtc"]}
                  value={fromTime || ""}
                  onChange={(value) => {
                    setFromTime(value)
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {showContactForm && (
                  <TimePicker
                    readOnly={readOnly}
                    label={t("details.to") + " *"}
                    error={formErrors["ToTimeUtc"]}
                    value={toTime || ""}
                    onChange={(value) => {
                      setToTime(value)
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {showContactForm && (
            <Grid container xs={4} justifyContent="end" alignItems="end">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.SuppressSyncAppointmentWithApp || false}
                      color="primary"
                      onChange={(e) => modifyForm({ SuppressSyncAppointmentWithApp: e.target.checked })}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "16px" }}>{t("details.suppressSyncAppointmentWithApp")}</Typography>
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container xs={12}>
          <Grid container spacing={2} xs={8}>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Autocomplete
                  readOnly={readOnly}
                  value={
                    form?.LocationId
                      ? {
                          Id: form?.LocationId || 0,
                          Name: generalListItems.Locations?.find((x) => x.Id == form?.LocationId)?.Name || "",
                        }
                      : null
                  }
                  onChange={(e, value) => modifyForm({ LocationId: value ? value.Id : 0 })}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(a, b) => a.Id === b.Id}
                  options={generalListItems.Locations || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={showContactForm}
                      variant="standard"
                      label={t("details.location")}
                      placeholder={t("details.location")}
                      error={!!formErrors.LocationId}
                      helperText={!!formErrors.LocationId && formErrors.LocationId}
                    />
                  )}
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  openOnFocus={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Autocomplete
                  readOnly={readOnly}
                  value={
                    form?.ContactTypeId
                      ? {
                          Id: form?.ContactTypeId || 0,
                          Name: generalListItems.ContactTypes?.find((x) => x.Id == form?.ContactTypeId)?.Name || "",
                        }
                      : null
                  }
                  onChange={(e, value) => modifyForm({ ContactTypeId: value ? value.Id : 0 })}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(a, b) => a.Id === b.Id}
                  options={generalListItems.ContactTypes || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      variant="standard"
                      label={t("details.contactForm")}
                      placeholder={t("details.contactForm")}
                      error={!!formErrors.ContactTypeId}
                      helperText={!!formErrors.ContactTypeId && formErrors.ContactTypeId}
                    />
                  )}
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  openOnFocus={true}
                />
              </FormControl>
            </Grid>

            {!showContactForm && (
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  label={t("details.supervisor")}
                  defaultValue={formView?.SupervisorName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <FormControl variant="standard" sx={styles.textarea}>
              <TextEditor
                readOnly={readOnly}
                label="Inhalt"
                error={undefined}
                isFormChecked={isFormChecked}
                withToolbar={true}
                value={form?.ContentBody}
                onChange={(value) => modifyForm({ ContentBody: value })}
              />
            </FormControl>
          </Grid>
          {showContactForm && (
            <Grid item xs={4} justifyContent="center">
              <Stack gap={2}>
                {contactForm.ShowAppointmentState && (
                  <Stack sx={styles.appointmentBox}>
                    <InputLabel sx={{ fontSize: "14px" }} id="appointment-label">
                      {t("details.stateAppointment")}
                    </InputLabel>
                    <Select
                      variant="standard"
                      disabled={true}
                      inputProps={{ IconComponent: () => null }}
                      labelId="appointment-label"
                      defaultValue={contactForm.AppointmentState}
                    >
                      {generateAppointmentStatus(contactForm.AppointmentState)}
                    </Select>
                  </Stack>
                )}

                {contactForm.AppointmentState === EAppointmentState.DECLINED && (
                  <>
                    {contactForm.AppointmentAlternativeTimeslot && (
                      <>
                        <FormControl variant="standard">
                          <InputLabel htmlFor="standard-adornment-1">{t("details.stateAppointmentReason")}</InputLabel>
                          <Input
                            id="standard-adornment-1"
                            type={"text"}
                            inputProps={{
                              readOnly: true,
                              disabled: true,
                            }}
                            multiline={true}
                            rows={3}
                            value={contactForm.AppointmentDeclinedReason}
                          />
                        </FormControl>
                        <FormControl variant="standard">
                          <InputLabel htmlFor="standard-adornment-1">
                            {t("details.appointmentAlternativeTimeslot")}
                          </InputLabel>
                          <Input
                            id="standard-adornment-1"
                            type={"text"}
                            inputProps={{
                              readOnly: true,
                              disabled: true,
                            }}
                            value={format(
                              new Date(`${contactForm.AppointmentAlternativeTimeslot}`),
                              "dd.MM.yyyy HH:mm"
                            )}
                            endAdornment={
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        {contactForm.ShowAppointmentAccept && (
                          <>
                            {appointmentAcceptResponse.isLoading && (
                              <LoadingMeta eventMeta={appointmentAcceptResponse} asIcon={true} />
                            )}
                            {!appointmentAcceptResponse.isLoading && (
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  doAppointmentAccept({
                                    RecordId: props.recordId,
                                  })
                                }}
                              >
                                {t("details.acceptAppointmentAlternativeTimeslot")}
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid container xs={8}>
          <MyFileUploader
            readOnly={readOnly}
            allowedAttachmentTypes={
              showContactForm ? contactListItems.AllowedAttachmentTypes : correspondenceListItems.AllowedAttachmentTypes
            }
            recordId={props.recordId}
            form={form}
            formErrors={formErrors}
            modifyForm={modifyCb}
          />
        </Grid>
      </Stack>
    )
  }
  const close = () => {
    if (isUpdateLoading) {
      return
    }
    props.close()
  }

  return (
    <>
      <Box
        sx={isUpdateLoading ? { pointerEvents: "none", ...styles.dialogBox } : { ...styles.dialogBox }}
        ref={dialogRef}
      >
        <CloseIcon
          fontSize="small"
          sx={styles.closeIcon}
          onClick={() => {
            close()
          }}
        />
        <Stack sx={styles.dialogContent}>
          <Stack sx={styles.titleAndStepperBox}>
            <Stack gap="8px">
              <Title
                text={
                  props.recordType === RecordType.Correspondence
                    ? t("details.editCorrespondence")
                    : t("details.editContact")
                }
              />
              <Box>
                <Typography component="span" variant="body1" fontSize="16px">
                  für
                </Typography>{" "}
                <Typography component="span" variant="subtitle1" border="none" fontSize="16px">
                  {props.fileDetails.FileNumber} | {props.fileDetails.ClientDetails?.LastName},{" "}
                  {props.fileDetails.ClientDetails?.FirstName}
                </Typography>
              </Box>
            </Stack>
            {renderTabs()}
            {renderAlert()}
          </Stack>
          {!isSuccess && isInitialized && (selectedTab === 0 ? renderFirstTab() : renderSecondTab())}
          {isSuccess && (
            <Box sx={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  close()
                }}
                variant="contained"
              >
                {t("details.closeWindow")}
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
      {!isEntityLoading && !isSuccess && isInitialized && (
        <DialogActions>
          <Stack
            sx={{
              ...styles.buttonsBox,
              width: "100%",
              padding: "0 22px 9px 22px",
            }}
          >
            {!isUpdateLoading && (
              <Stack sx={styles.leftButtonsBox}>
                <Button tabIndex={-11} onClick={() => props.close()} sx={styles.cancelButton}>
                  <Typography fontSize="14px" fontWeight="700">
                    {t("details.cancel")}
                  </Typography>
                </Button>
                <Button
                  tabIndex={-10}
                  sx={{ ...styles.cancelButton, color: "#D92121", borderColor: "#D92121" }}
                  onClick={() => {
                    showYesNoDialog({
                      title: t("details.messageFromPage"),
                      text: t("details.deleteHistoryEntryConfirm"),
                      onConfirm: () => deleteRecord(),
                    })
                  }}
                >
                  <Typography fontSize="14px" fontWeight="700">
                    {t("details.deleteHistoryEntry")}
                  </Typography>
                </Button>
              </Stack>
            )}
            {!isUpdateLoading && (
              <Button onClick={onSaveEdit} sx={styles.nextButton} variant="contained">
                <Typography fontSize="14px" fontWeight="500">
                  {t("details.saveChanges")}
                </Typography>
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}
    </>
  )
}
