import React, { useMemo } from "react"
import { MainTableGroupRowProps } from "./main-table-config"
import { groupItemsByDate, objectSlice } from "@frontend/extweb-lib/utils/object-utils"
import MuiTableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { format } from "date-fns"

const styles = {
  firstColumn: { fontSize: "14px" },
  firstColumnColored: {
    fontWeight: 700,
    color: "#0048B5",
  },
}
const today = format(new Date(), "dd.MM.yyyy")

const MainTableGroupRow = <T,>({ data, page, rowsPerPage, columns }: MainTableGroupRowProps<T>) => {
  const groupedData = useMemo(() => groupItemsByDate(data, "DateUtc" as keyof T), [data])
  const pagedGroupItems = useMemo(() => {
    return Object.entries(objectSlice(groupedData, page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [groupedData, page, rowsPerPage])

  const renderGroup = (date: string, groupItems: T[], groupHiglightIndex: number) => {
    const groupDate = format(new Date(date), "dd.MM.yyyy")
    const headerRowCells = [
      {
        key: "recordType",
        value: () => groupDate,
      },
      ...Array.from({ length: columns.length - 1 }, (_, index) => ({
        key: `emptyCell${index}`,
        value: () => "",
      })),
    ]
    const rowClassName = today === groupDate ? "highlight" : undefined
    return (
      <React.Fragment key={groupHiglightIndex}>
        <MuiTableRow className={rowClassName}>
          {headerRowCells.map((columnItem, index) => (
            <TableCell
              key={index}
              colSpan={index === 0 ? 2 : undefined}
              sx={{
                ...styles.firstColumn,
                borderBottom: "none",
                paddingBottom: "0",
                ...(index === 0 ? { ...styles.firstColumnColored } : undefined),
              }}
            >
              {columnItem.value()}
            </TableCell>
          ))}
        </MuiTableRow>

        {groupItems.map((item, index) => (
          <MuiTableRow key={index} className={rowClassName}>
            <TableCell
              sx={{
                width: "20px",
                borderBottom: "none",
              }}
            ></TableCell>
            {columns.map((columnItem) => (
              <TableCell
                key={columnItem.key}
                sx={{
                  ...styles.firstColumn,
                  borderBottom: "1px solid #848484",
                }}
              >
                {columnItem.value(item)}
              </TableCell>
            ))}
          </MuiTableRow>
        ))}
      </React.Fragment>
    )
  }

  return <>{pagedGroupItems.map(([key], index) => renderGroup(key, groupedData[key], index))}</>
}

export default MainTableGroupRow
