import React, { useState } from "react"
import { Dialog } from "@mui/material"
import { EditRecord, EditRecordProps } from "./EditRecord"

export const EditRecordDialog = (props: EditRecordProps) => {
  const [closeable, setCloseable] = useState(false)

  const close = () => {
    if (!closeable) {
      return
    }
    props.close()
  }

  return (
    <Dialog sx={{ zIndex: 999 }} open={props.isOpen} fullWidth maxWidth="lg" onClose={close}>
      {props.isOpen && <EditRecord {...props} setCloseable={setCloseable} />}
    </Dialog>
  )
}
