/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { FileListRequestDto } from "../model/dataModel"

export class FileListApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {FileListRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // FileListController.GetFileList  (BgbwExtWeb.Web.Controllers)
  // apiV1FilesPost
  public getFileList_POST(body?: FileListRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/files`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"FileListRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("FileList", "apiV1FilesPost", httpOptions)
  }
}
