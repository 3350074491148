import { createContext, PropsWithChildren, useContext, useMemo } from "react"
import ExtWebApi from "@frontend/extweb-api/ext-web-api"
import { httpClient } from "@frontend/extweb-api/client/http-client.instance"

export const ExtWebApiContext = createContext<ExtWebApi>(new ExtWebApi(httpClient))

export const useExtWebApi = (): ExtWebApi => useContext(ExtWebApiContext)

export const ExtWebApiProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const instance = useMemo(() => new ExtWebApi(httpClient), [])

  return <ExtWebApiContext.Provider value={instance}>{props.children}</ExtWebApiContext.Provider>
}
