import React from "react"
import { Typography } from "@mui/material"

interface TitleProps {
  text: string
}

export const Title = ({ text }: TitleProps) => {
  return (
    <Typography variant="h4" fontFamily="EB Garamond SemiBold" fontSize="32px">
      {text}
    </Typography>
  )
}
