/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { DeleteRecordRequestDto } from "../model/dataModel"
import { FileRecordListRequestDto } from "../model/dataModel"
import { SendToAppRequestDto } from "../model/dataModel"

export class FileRecordListApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {DeleteRecordRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // FileRecordListController.DeleteRecord  (BgbwExtWeb.Web.Controllers)
  // apiV1FileRecordsDeleteRecordPost
  public deleteRecord_POST(body?: DeleteRecordRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/file-records/delete-record`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"DeleteRecordRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("FileRecordList", "apiV1FileRecordsDeleteRecordPost", httpOptions)
  }

  /**
   *
   * @param {FileRecordListRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // FileRecordListController.GetFileRecordList  (BgbwExtWeb.Web.Controllers)
  // apiV1FileRecordsPost
  public getFileRecordList_POST(body?: FileRecordListRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/file-records`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"FileRecordListRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("FileRecordList", "apiV1FileRecordsPost", httpOptions)
  }

  /**
   *
   * @param {SendToAppRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // FileRecordListController.SendToApp  (BgbwExtWeb.Web.Controllers)
  // apiV1FileRecordsSendToAppPost
  public sendToApp_POST(body?: SendToAppRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/file-records/send-to-app`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"SendToAppRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("FileRecordList", "apiV1FileRecordsSendToAppPost", httpOptions)
  }
}
