import { useEffect, useState } from "react"
import { TableColumnsSortType } from "../components/main-table/main-table-config"
import { ETableSortType } from "../enums/e-table-sort-type"

const useTable = <T>(data: T[] | undefined, filter: ((items: T[]) => T[]) | undefined = undefined) => {
  const [tableData, setTableData] = useState<T[]>(data || [])
  const [tableColumnsSort, setTableColumnsSortType] = useState<TableColumnsSortType<T>>({})

  useEffect(() => {
    if (!data) {
      setTableData([])
      return
    }
    if (filter) {
      const _data = filter(data)
      setTableData(_data)
      return
    }
    setTableData(data)
  }, [data, filter])
  const handleTableDataUpdate = (newData: T[]) => {
    setTableData(newData)
  }

  const handleTableSort = (sortBy: keyof T, sortType: ETableSortType) => {
    const newData = [...tableData]

    newData.sort((item1, item2) => {
      const value1 = item1[sortBy]
      const value2 = item2[sortBy]

      if (typeof value1 === "string" && typeof value2 === "string") {
        if (value1 === null && value2 === null) return 0
        if (value1 === null) return sortType === ETableSortType.Ascending ? 1 : -1
        if (value2 === null) return sortType === ETableSortType.Descending ? 1 : -1

        if (sortType === ETableSortType.Ascending) {
          return value1.localeCompare(value2)
        } else {
          return value2.localeCompare(value1)
        }
      }

      if (typeof value1 === "number" && typeof value2 === "number") {
        if (value1 === null && value2 === null) return 0
        if (value1 === null) return sortType === ETableSortType.Ascending ? 1 : -1
        if (value2 === null) return sortType === ETableSortType.Descending ? 1 : -1

        if (sortType === ETableSortType.Ascending) {
          return value1 - value2
        } else {
          return value2 - value1
        }
      }

      return 0
    })

    setTableColumnsSortType((previous) => ({ ...previous, [sortBy]: sortType }))
    setTableData(newData)
  }

  return {
    tableData,
    tableColumnsSort,
    handleTableSort,
    handleTableDataUpdate,
  }
}

export default useTable
