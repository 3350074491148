import { EventResponseDto, EventResponseModel2 } from "@frontend/shared/models/EventResponseDto"
import { useCallback, useEffect, useMemo, useState } from "react"
import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import { DataResult, useEventQueueContext } from "@frontend/shared/contexts/event-queue-context/EventQueueContext"

export const useGeneralSync = (refreshIterator: number | string): EventResponseModel2 => {
  const { subscribe, unsubscribe, reloadData } = useEventQueueContext()
  const [eventResult, setEventResult] = useState<{ [cacheKey: string]: EventResponseDto<any> }>({})

  const doUnsubscribe = useCallback(
    (subscription: (data: DataResult) => void) => {
      unsubscribe(subscription)
      setEventResult({})
    },
    [unsubscribe]
  )

  const subscription = useCallback((data: DataResult) => {
    setEventResult((prev) => ({
      ...prev,
      [data.hashedCacheKey]: {
        isLoaded: true,
        isLoading: false,
        data: data.data,
        createDate: data.createDate,
        expireDate: data.expireDate,
        refreshDate: data.pendingStartDate,
        hashedCacheKey: data.hashedCacheKey,
        reload: () => {},
      },
    }))
  }, [])

  useEffect(() => {
    return () => {
      doUnsubscribe(subscription)
    }
  }, [doUnsubscribe, subscription])

  const subscribeCallback = useCallback(
    (model: any, cacheKey: string | number | undefined | null = undefined) => {
      if (!refreshIterator) {
        return
      }

      let cacheHashKey: string
      let cacheKeyFull: string | undefined

      if (cacheKey !== undefined && cacheKey !== null) {
        cacheHashKey = "" + cacheKey
        cacheKeyFull = "" + cacheKey
      } else {
        cacheKeyFull = JSON.stringify(model)
        cacheHashKey = hashedCacheKey(cacheKeyFull)
      }

      const subscriptionCallback = async (): Promise<void> => {}
      setEventResult((prev) => {
        const model = { ...prev }
        const old = prev[cacheHashKey]
        model[cacheHashKey] = {
          isLoaded: false,
          isLoading: true,
          data: old?.data,
          createDate: old?.createDate,
          expireDate: old?.expireDate,
          refreshDate: old?.refreshDate,
          hashedCacheKey: old?.hashedCacheKey,
          reload: () => {},
        }
        return model
      })

      setEventResult((old) => ({}))
      subscribe(subscription, cacheHashKey, cacheKeyFull, subscriptionCallback, true, true)
    },
    [subscribe, subscription, refreshIterator]
  )

  const model = useMemo(() => {
    return [eventResult, subscribeCallback] as EventResponseModel2
  }, [eventResult, subscribeCallback])

  return model
}
