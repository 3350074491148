import { format } from "date-fns"

export const groupItemsByDate = <T>(items: T[], dateProperty: keyof T) => {
  const groupedItems: { [date: string]: T[] } = {}

  items.forEach((item) => {
    const date = item[dateProperty] as string
    const gDate = format(new Date(`${date}`), "yyyy-MM-dd")
    if (groupedItems[gDate] === undefined) {
      groupedItems[gDate] = []
    }
    groupedItems[gDate].push(item)
  })

  return sortDatesInDescendingOrder(groupedItems)
}

export const objectSlice = <T>(obj: Record<string, T>, start: number, end: number): Record<string, T> => {
  const keys = Object.keys(obj).slice(start, end)

  return keys.reduce((result: Record<string, T>, key) => {
    result[key] = obj[key]
    return result
  }, {})
}

export const sortDatesInDescendingOrder = <T>(groupedItems: { [date: string]: T[] }) => {
  const sortedDates = Object.keys(groupedItems).sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  const sortedGroupedItems: { [date: string]: T[] } = {}

  sortedDates.forEach((date) => {
    sortedGroupedItems[date] = groupedItems[date]
  })

  return sortedGroupedItems
}
