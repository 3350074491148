import {
  CreateCorrespondenceMetaResponseSchema,
  RecordType,
  UpsertContactRecord,
  UpsertCorrespondenceRecord,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { DateState, GeneralForm } from "../../../models"
import { ValidationSimpleResults } from "@frontend/shared/validation/base-form-validation/base-form-validation"
import { TFunction } from "react-i18next"
import { FromToResult, getFromTo } from "./date.utils"

export interface ValidateFormResult {
  _generalErrors: ValidationSimpleResults<GeneralForm>
  _contactFormErrors: ValidationSimpleResults<UpsertContactRecord>
  _correspondenceFormErrors: ValidationSimpleResults<UpsertCorrespondenceRecord>
  fromToResult: FromToResult | undefined
}

export const validateNewContactWizard = (
  t: TFunction<"translation", undefined>,
  activeStep: number,
  generalForm: GeneralForm,
  contactForm: UpsertContactRecord,
  datePicker: DateState
): ValidateFormResult => {
  const _generalErrors: ValidationSimpleResults<GeneralForm> = {}
  const _contactFormErrors: ValidationSimpleResults<UpsertContactRecord> = {}
  const _correspondenceFormErrors: ValidationSimpleResults<UpsertCorrespondenceRecord> = {}
  let fromToResult: FromToResult | undefined
  if (activeStep === 1) {
    if (!contactForm.Participants?.length) {
      _contactFormErrors["Participants"] = t("details.pleaseChooseParticipants")
    }
  }
  if (activeStep === 2) {
    const fromToErrors = getFromTo(t, datePicker, false)
    if (fromToErrors.errors) {
      for (const key of Object.keys(fromToErrors.errors)) {
        // @ts-ignore
        _contactFormErrors[key] = fromToErrors.errors[key]
      }
    } else {
      fromToResult = fromToErrors.result
    }
    if (!contactForm.LocationId) {
      _contactFormErrors["LocationId"] = t("details.pleaseChooseLocation")
    }
    if (!contactForm.ContactTypeId) {
      _contactFormErrors["ContactTypeId"] = t("details.pleaseChooseContactType")
    }
  }

  return {
    _generalErrors: _generalErrors,
    _contactFormErrors: _contactFormErrors,
    _correspondenceFormErrors: _correspondenceFormErrors,
    fromToResult: fromToResult,
  }
}

export const validateNewCorrespondenceWizard = (
  t: TFunction<"translation", undefined>,
  activeStep: number,
  generalForm: GeneralForm,
  correspondenceForm: UpsertCorrespondenceRecord,
  correspondenceMeta: CreateCorrespondenceMetaResponseSchema,
  datePicker: DateState
): ValidateFormResult => {
  const _generalErrors: ValidationSimpleResults<GeneralForm> = {}
  const _contactFormErrors: ValidationSimpleResults<UpsertContactRecord> = {}
  const _correspondenceFormErrors: ValidationSimpleResults<UpsertCorrespondenceRecord> = {}
  let fromToResult: FromToResult | undefined

  if (activeStep == 0) {
    if (correspondenceForm.DocumentTypeId === 0) {
      _correspondenceFormErrors["DocumentTypeId"] = t("details.pleaseChooseDocumentType")
    }
    if (correspondenceForm.TemplateId === 0) {
      _correspondenceFormErrors["TemplateId"] = t("details.pleaseChooseTemplate")
    }
  }
  if (activeStep === 1) {
    if (!correspondenceForm.Participants?.length) {
      _correspondenceFormErrors["Participants"] = t("details.pleaseChooseParticipants")
    }
  }
  if (activeStep === 2) {
    const fromToErrors = getFromTo(t, datePicker, false)
    if (fromToErrors.errors) {
      for (const key of Object.keys(fromToErrors.errors)) {
        // @ts-ignore
        _correspondenceFormErrors[key] = fromToErrors.errors[key]
      }
    } else {
      fromToResult = fromToErrors.result
    }

    if (!correspondenceForm.ContactTypeId) {
      _correspondenceFormErrors["ContactTypeId"] = t("details.pleaseChooseContactType")
    }
  }

  return {
    _generalErrors: _generalErrors,
    _contactFormErrors: _contactFormErrors,
    _correspondenceFormErrors: _correspondenceFormErrors,
    fromToResult: fromToResult,
  }
}

export interface ValidateFormContactResult {
  _contactFormErrors: ValidationSimpleResults<UpsertContactRecord>
  fromToResult: FromToResult | undefined
}

export interface ValidateFormCorrespondenceResult {
  _correspondenceFormErrors: ValidationSimpleResults<UpsertCorrespondenceRecord>
  fromToResult: FromToResult | undefined
}

export const validateEditContactForm = (
  t: TFunction<"translation", undefined>,
  contactForm: UpsertContactRecord,
  datePicker: DateState,
  fromToRequired: boolean
): ValidateFormContactResult => {
  const _contactFormErrors: ValidationSimpleResults<UpsertContactRecord> = {}
  let fromToResult: FromToResult | undefined

  const fromToErrors = getFromTo(t, datePicker, fromToRequired)
  if (fromToErrors.errors) {
    for (const key of Object.keys(fromToErrors.errors)) {
      // @ts-ignore
      _contactFormErrors[key] = fromToErrors.errors[key]
    }
  } else {
    fromToResult = fromToErrors.result
  }

  if (!contactForm.LocationId) {
    _contactFormErrors["LocationId"] = t("details.pleaseChooseLocation")
  }
  if (!contactForm.ContactTypeId) {
    _contactFormErrors["ContactTypeId"] = t("details.pleaseChooseContactType")
  }
  if (!contactForm.SupervisorId) {
    _contactFormErrors["SupervisorId"] = t("details.pleaseChooseSupervisor")
  }

  return {
    _contactFormErrors: _contactFormErrors,
    fromToResult: fromToResult,
  }
}

export const validateEditCorrespondenceForm = (
  t: TFunction<"translation", undefined>,
  correspondenceForm: UpsertCorrespondenceRecord,
  datePicker: DateState,
  fromToRequired: boolean
): ValidateFormCorrespondenceResult => {
  const _correspondenceFormErrors: ValidationSimpleResults<UpsertCorrespondenceRecord> = {}
  let fromToResult: FromToResult | undefined

  const fromToErrors = getFromTo(t, datePicker, fromToRequired)
  if (fromToErrors.errors) {
    for (const key of Object.keys(fromToErrors.errors)) {
      // @ts-ignore
      _contactFormErrors[key] = fromToErrors.errors[key]
    }
  } else {
    fromToResult = fromToErrors.result
  }

  if (!correspondenceForm.ContactTypeId) {
    _correspondenceFormErrors["ContactTypeId"] = t("details.pleaseChooseContactType")
  }
  if (!correspondenceForm.SupervisorId) {
    _correspondenceFormErrors["SupervisorId"] = t("details.pleaseChooseSupervisor")
  }

  return {
    _correspondenceFormErrors: _correspondenceFormErrors,
    fromToResult: fromToResult,
  }
}

export const validateStep = (
  step: number,
  generalForm: GeneralForm,
  contactForm: UpsertContactRecord,
  correspondenceForm: UpsertCorrespondenceRecord,
  correspondenceMeta: CreateCorrespondenceMetaResponseSchema
): boolean => {
  if (generalForm.recordType === RecordType.Contact) {
    if (!contactForm) {
      return false
    }
    if (step === 0) {
      return true
    }
    if (step === 1) {
      if (contactForm.Participants?.length) {
        return true
      }
    }
    if (step === 2) {
      if (
        contactForm.FromDateUtc &&
        contactForm.ToDateUtc &&
        contactForm.ContentBody &&
        contactForm.ContactTypeId &&
        contactForm.LocationId
      ) {
        return true
      }
    }
  }

  if (generalForm.recordType === RecordType.Correspondence) {
    if (!correspondenceForm) {
      return false
    }

    if (step === 0) {
      if (correspondenceForm.DocumentTypeId > 0) {
        if (correspondenceForm.TemplateId > 0) {
          const documentType = correspondenceMeta?.DocumentTypes?.find(
            (x) => x.DocumentTypeId === correspondenceForm.DocumentTypeId
          )
          if (documentType) {
            return true
          }
        }
      }
    }
    if (step === 1) {
      if (correspondenceForm?.Participants?.length) {
        return true
      }
    }
    if (step === 2) {
      if (
        correspondenceForm.FromDateUtc &&
        correspondenceForm.ToDateUtc &&
        correspondenceForm.ContentBody &&
        correspondenceForm.ContactTypeId &&
        correspondenceForm.LocationId
      ) {
        return true
      }
    }
  }
  return false
}
