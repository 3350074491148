/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { AttachmentDetails } from "../model/dataModel"
import { AttachmentDownloadRequestDto } from "../model/dataModel"
import { ExtWebFileResponseDto } from "../model/extWebDocuments"
import { WebDavApprovalRequestDto } from "../model/dataModel"

export class WebDavFileApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {WebDavApprovalRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // WebDavFileController.WebDavApproval  (BgbwExtWeb.Web.Controllers)
  // apiV1WebdavApprovalPost
  public webDavApproval_POST(body?: WebDavApprovalRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/webdav/approval`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"WebDavApprovalRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("WebDavFile", "apiV1WebdavApprovalPost", httpOptions)
  }

  /**
   *
   * @param {number} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // WebDavFileController.GetAttachmentDetails  (BgbwExtWeb.Web.Controllers)
  // apiV1WebdavFileFileIdPost
  public getAttachmentDetails_POST(fileId: number, options: any = {}): Promise<ResponseWrap<AttachmentDetails>> {
    // verify required parameter 'fileId' is not null or undefined
    if (fileId === null || fileId === undefined) {
      throw new RequiredError(
        "fileId",
        "Required parameter fileId was null or undefined when calling apiV1WebdavFileFileIdPost."
      )
    }
    const _path = `/api/v1/webdav/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<AttachmentDetails>("WebDavFile", "apiV1WebdavFileFileIdPost", httpOptions)
  }

  /**
   *
   * @param {AttachmentDownloadRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // WebDavFileController.RequestFile  (BgbwExtWeb.Web.Controllers)
  // apiV1WebdavPost
  public requestFile_POST(
    body?: AttachmentDownloadRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<ExtWebFileResponseDto>> {
    const _path = `/api/v1/webdav`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"AttachmentDownloadRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<ExtWebFileResponseDto>("WebDavFile", "apiV1WebdavPost", httpOptions)
  }
}
