/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { ExtWebLoginRequestDto } from "../model/accessControl"
import { ExtWebPollRequest } from "../model/accessControl"
import { LoginResponseDto } from "../model/accessControl"
import { LoginStateDto } from "../model/accessControl"
import { MfaStateDto } from "../model/accessControl"

export class AuthenticationApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {ExtWebLoginRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AuthenticationController.Authenticate  (BgbwExtWeb.Web.Controllers)
  // apiV1AuthenticationAuthenticatePost
  public authenticate_POST(body?: ExtWebLoginRequestDto, options: any = {}): Promise<ResponseWrap<LoginResponseDto>> {
    const _path = `/api/v1/authentication/authenticate`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"ExtWebLoginRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<LoginResponseDto>("Authentication", "apiV1AuthenticationAuthenticatePost", httpOptions)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AuthenticationController.Logout  (BgbwExtWeb.Web.Controllers)
  // apiV1AuthenticationLogoutGet
  public logout_GET(options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/authentication/logout`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "GET" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Authentication", "apiV1AuthenticationLogoutGet", httpOptions)
  }

  /**
   *
   * @param {ExtWebPollRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AuthenticationController.AuthenticatePoll  (BgbwExtWeb.Web.Controllers)
  // apiV1AuthenticationPollPost
  public authenticatePoll_POST(body?: ExtWebPollRequest, options: any = {}): Promise<ResponseWrap<MfaStateDto>> {
    const _path = `/api/v1/authentication/poll`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"ExtWebPollRequest" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<MfaStateDto>("Authentication", "apiV1AuthenticationPollPost", httpOptions)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AuthenticationController.GetLoginDetails  (BgbwExtWeb.Web.Controllers)
  // apiV1AuthenticationStatePost
  public getLoginDetails_POST(options: any = {}): Promise<ResponseWrap<LoginStateDto>> {
    const _path = `/api/v1/authentication/state`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<LoginStateDto>("Authentication", "apiV1AuthenticationStatePost", httpOptions)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AuthenticationController.LogoutUi  (BgbwExtWeb.Web.Controllers)
  // staticLogoutGet
  public logoutUi_GET(options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/static-logout`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "GET" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Authentication", "staticLogoutGet", httpOptions)
  }
}
