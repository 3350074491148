import { AdditonalParameter, AdditonalParameterMeta } from "@frontend/extweb-api/swagger/model/dataModel"
import React from "react"
import { AdditionalField } from "./AdditionalField"
import { Stack, Typography } from "@mui/material"

export interface AdditionalFormProps {
  additonalParametersList?: AdditonalParameterMeta[]
  errors: { [key: string]: string }
  change: (model: AdditonalParameter[]) => void
  additonalParameters: AdditonalParameter[]
  isFormChecked: boolean
  readOnly?: boolean
}

export const AdditionalForm = (props: AdditionalFormProps) => {
  return (
    <Stack gap={2}>
      {(props.additonalParametersList || []).map((additionalParameter) => {
        return (
          <AdditionalField
            key={additionalParameter.Id}
            readOnly={props.readOnly}
            fieldDefinition={additionalParameter}
            errors={props.errors}
            onChange={(value, parentId) => {
              const updatedParameters = props.additonalParameters || []
              const replace = updatedParameters.find((x) => x.Id === additionalParameter.Id)
              if (replace) {
                const index = updatedParameters.indexOf(replace)
                if (index > -1) {
                  updatedParameters.splice(index, 1)
                }
              }
              updatedParameters.push({
                Id: additionalParameter.Id,
                ParentId: parentId,
                Value: value,
              })
              props.change(updatedParameters)
            }}
            isFormChecked={props.isFormChecked}
            parameter={props.additonalParameters?.find((x) => x.Id === additionalParameter.Id)}
          />
        )
      })}
      <Typography fontSize={"13px"} mt={2}>
        Bitte alle Parameter angeben.
      </Typography>
    </Stack>
  )
}
