import { createContext, useContext } from "react"
import { LanguageKeys } from "@frontend/shared/contexts/language-context/utils"

export interface LanguageContextValue {
  language: LanguageKeys
  setLanguage: (language: LanguageKeys) => void
}

const defaultValue: LanguageContextValue = {
  language: LanguageKeys.de,
  setLanguage: () => {},
}

export const LanguageContext = createContext<LanguageContextValue>(defaultValue)
export const useLanguageContext = (): LanguageContextValue => useContext(LanguageContext)
