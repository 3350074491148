import { styles } from "@frontend/extweb-lib/views/details/components/dialog/DialogStyles"
import { FormControl, FormHelperText } from "@mui/material"
import { TimePicker as MuiTimePicker, renderTimeViewClock } from "@mui/x-date-pickers"
import { format, parse } from "date-fns"
import React from "react"

interface TimePickerProps {
  label: string
  value: string
  error: string | undefined
  isFormChecked?: boolean
  readOnly?: boolean
  tabIndex?: number
  onChange: (value: string | undefined) => void
  inputRef?: React.Ref<HTMLInputElement> | null
}

export const TimePicker = ({ label, value, onChange, error, readOnly, tabIndex, inputRef }: TimePickerProps) => {
  return (
    <FormControl fullWidth variant="standard" sx={styles.inputFieldsWidth}>
      <MuiTimePicker
        readOnly={readOnly}
        closeOnSelect={false}
        label={label}
        ampm={false}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        inputRef={inputRef}
        sx={styles.calendar}
        slotProps={{
          textField: {
            variant: "standard",
            disabled: readOnly,
            error: typeof error === "string",
            tabIndex: tabIndex,
          },
        }}
        value={!value ? null : parse(value, "HH:mm", new Date())}
        onChange={(value: any) => {
          if (value instanceof Date) {
            if (!isNaN(value.getTime())) {
              onChange(format(value, "HH:mm"))
            } else {
              onChange("")
            }
          }
        }}
      />
      {!!error && <FormHelperText sx={{ color: "red" }}>{error}</FormHelperText>}
    </FormControl>
  )
}
