const fileOverview = {
  fileOverview: "",
  fileNumber: "",
  clientName: "",
  lastPersonalContact: "",
  lastReport: "",
  personalData: "",
  processEmployee: "",
  involved: "",
  furnishings: "",
  department: "",
  fewerOptions: "",
  moreOptions: "",
  reset: "",
  search: "",
  performanceType: "",
  clientDateBirth: "",
  clientPhoneNumber: "",
  clerk: "",
  includeClosedFiles: "",
  includeFilesCompletedBySocialWorkers: "",
  rowsPerPage: "",
  of: "",
  noTableEntries: "",
  myDocuments: "",
}

export default fileOverview
