import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { useTranslation } from "react-i18next"
import { Guid } from "@customTypes"
import {
  EventQueueEventAction,
  useEventQueueContext,
} from "@frontend/shared/contexts/event-queue-context/EventQueueContext"
import Login from "@frontend/extweb-lib/views/login/Login"
import { AuthState } from "@frontend/extweb-api/swagger/model/accessControl"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { matchPath } from "react-router"
import { getBaseName } from "@frontend/shared/utils/path.utils"
import {
  InitEncryptionRequest,
  InitEncryptionResult,
  LoginResult,
  MfaResultState,
  MfaServiceResult,
} from "@frontend/shared/models/auth.models"
import { AuthService } from "@frontend/shared/services/auth.service"
import { decryptModel } from "@frontend/shared/crypto-new/aesCrypto"
import { ExtWebUserResponseSchema } from "@frontend/extweb-api/swagger/model/dataModel"
import { UserDto } from "@frontend/extweb-lib/models/user-dto"
import Logout from "@frontend/extweb-lib/views/login/Logout"
import { useConfirmationDialogContext } from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"

interface ExtWebUserContextValue {
  doLogin: (username: string | undefined, password: string | undefined, stayLogged: boolean) => Promise<LoginResult>
  doLogout: () => Promise<void>
  doLogoutQuestion: () => void
  reset: () => Promise<void>
  mafResult: MfaResultState | undefined
  logoutSuccess: boolean
  loading: boolean
  isLoggedIn: boolean
  user: UserDto | undefined
  removeStayLogin: () => Promise<void>
  stayLoginDetails: StayLoginDetails | undefined
}

export interface ExtWebUserResponseSchema2 extends ExtWebUserResponseSchema {
  firstname: string
  lastname: string
}

export interface StayLoginSetDetails {
  username: string | undefined
}

export interface StayLoginDetails {
  username: string | undefined
  token: string | undefined
}

export const ExtWebUserContext = createContext<ExtWebUserContextValue>({} as any)

export const useExtWebUserContext = (): ExtWebUserContextValue => useContext(ExtWebUserContext)

export const authConstants = {
  localStorageSessionKey: "sessionId",
  localStorageStayLoggedInToken: "stayLoggedInToken",
  localStorageStayLoggedInUsername: "stayLoggedInUsername",
  localStorageLogoutAsked: "logoutAsked",
}
export const ExtWebUserProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const isInitializedRef = useRef(false)
  const authServiceRef = useRef<AuthService>()
  const api = useExtWebApi()
  const { t } = useTranslation()
  const [code, setCode] = useState<string>()
  const [sessionId, setSessionId] = useState<string>()
  const [loginPollActive, setLoginPollActive] = useState(false)
  const [loginPollStopped, setLoginPollStopped] = useState<string>()
  const [mafResult, setMfaResult] = useState<MfaResultState>()
  const [loading, setLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [refreshNumber, setRefreshNumber] = useState(1)
  const [initialLoading, setInitialLoading] = useState(true)
  const [user, setUser] = useState<UserDto>()
  const [logoutSuccess, setLogoutSuccess] = useState(false)
  const logoutProgress = useRef(false)
  const [stayLoginSetDetails, setStayLoginSetDetails] = useState<StayLoginSetDetails>()
  const [stayLoginDetails, setStayLoginDetails] = useState<StayLoginDetails>()
  const { showYesNoDialog } = useConfirmationDialogContext()

  const { eventHandler } = useEventQueueContext()
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/auth/mfa",
        element: <Login />,
      },
      {
        path: "/auth",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/*",
        element: <Login />,
      },
    ],
    {
      basename: getBaseName(),
    }
  )
  const initLogin = useCallback(
    async (request: InitEncryptionRequest) => {
      if (!authServiceRef.current) {
        return
      }
      try {
        let _result2: InitEncryptionResult | undefined
        try {
          _result2 = await authServiceRef.current.initEncryption(request)
        } catch (e1: any) {
          console.log(e1)
          throw e1
        }
        if (_result2?.success) {
          await eventHandler({
            action: EventQueueEventAction.EncryptionInitialized,
          })
          let userModel: ExtWebUserResponseSchema2 | undefined
          try {
            userModel = await decryptModel<ExtWebUserResponseSchema2>(request.userPayload)
          } catch (e1: any) {
            console.log(e1)
          }

          let _user: UserDto | undefined
          if (userModel) {
            _user = {
              firstName: userModel.firstname,
              lastName: userModel.lastname,
            }
          }

          setIsLoggedIn(true)
          setUser(_user)
          return
        }
        setMfaResult((prev) => ({
          ...prev,
          ...{
            state: {
              errorMessage: _result2?.errorMessage,
            },
          },
        }))
      } catch (e: any) {
        console.error(e)
      }
    },
    [eventHandler]
  )
  const startMfaPoll = useCallback(
    async (sessionId: Guid | undefined) => {
      if (loginPollActive || !authServiceRef.current || !sessionId) {
        return
      }
      if (loginPollStopped === sessionId) {
        return
      }
      const result = await authServiceRef.current.startPoll(sessionId)
      setCode(result.code)
      setSessionId(sessionId)
      setLoginPollActive(true)
    },
    [authServiceRef, loginPollActive, loginPollStopped]
  )

  const doLogoutQuestion = useCallback(async () => {
    showYesNoDialog({
      title: t("login.logoutConfirmationTitle"),
      text: t("login.logoutConfirmation"),
      agreeText: t("login.logoutConfirmationOk"),
      discardText: t("login.logoutConfirmationCancel"),
      onConfirm: () => {
        const baseName = getBaseName()
        localStorage.setItem(authConstants.localStorageLogoutAsked, "1")

        const url = document.location.protocol + "//" + document.location.host + baseName + "logout"
        document.location.href = url
      },
    })
  }, [t, showYesNoDialog])

  const doLogout2 = useCallback(async () => {
    if (!authServiceRef.current) {
      return
    }
    if (logoutProgress.current) {
      return
    }
    logoutProgress.current = true
    const sessId = localStorage.getItem(authConstants.localStorageSessionKey)
    localStorage.removeItem(authConstants.localStorageSessionKey)
    await authServiceRef.current.remove(sessId)

    setLoading(true)
    const result = await authServiceRef.current.logout()
    setMfaResult({ state: result.mfaResult })
    setIsLoggedIn(false)
    setLoading(false)
    setLogoutSuccess(true)
    logoutProgress.current = false
  }, [])

  const doLogout = useCallback(async () => {
    const logoutAsked = localStorage.getItem(authConstants.localStorageLogoutAsked)

    if (!logoutAsked) {
      showYesNoDialog({
        title: t("login.logoutConfirmationTitle"),
        text: t("login.logoutConfirmation"),
        agreeText: t("login.logoutConfirmationOk"),
        discardText: t("login.logoutConfirmationCancel"),
        onConfirm: () => {
          void doLogout2()
        },
      })
    } else {
      void doLogout2()
    }
  }, [doLogout2, showYesNoDialog, t])

  useEffect(() => {
    if (isInitializedRef.current || refreshNumber < 0) {
      return
    }
    const effect = async () => {
      try {
        const _loginService = new AuthService("keyPair", api.authenticationApiService, t)
        isInitializedRef.current = true
        authServiceRef.current = _loginService

        const stayLoggedInToken = localStorage.getItem(authConstants.localStorageStayLoggedInToken) || undefined
        const username = localStorage.getItem(authConstants.localStorageStayLoggedInUsername) || undefined
        setStayLoginDetails({
          token: stayLoggedInToken,
          username: username,
        })
        const currentPathName = document.location.pathname

        if (currentPathName.includes("logout")) {
          setInitialLoading(false)
          return
        }

        const result = await _loginService.getLoginDetails()
        if (result?.state === AuthState.LoggedIn && result.sessionId && result.encryptedPayload) {
          await initLogin({
            sessionId: result.sessionId,
            encryptedPayload: result.encryptedPayload,
            userPayload: result.encryptedUserDetails,
          })
          setInitialLoading(false)
          return
        }
        const baseName = getBaseName()

        const path = (baseName + "/auth/:mfa").replace("//", "/")

        const pathParams = matchPath(path, currentPathName)
        if (pathParams?.params?.mfa === "mfa") {
          const sessId = localStorage.getItem(authConstants.localStorageSessionKey)
          if (sessId) {
            void startMfaPoll(sessId)
          }
          return
        }

        if (!currentPathName.includes(baseName + "auth") && currentPathName.startsWith(baseName)) {
          const successRedirect = currentPathName.substring(baseName.length - 1)

          if (successRedirect && successRedirect.length > 1) {
            const url =
              document.location.protocol +
              "//" +
              document.location.host +
              baseName +
              "auth?success=" +
              encodeURIComponent(successRedirect)
            document.location.href = url
          }
        }
        setInitialLoading(false)
      } catch (e: any) {
        console.log(e)
      }
    }
    void effect()
  }, [api, t, refreshNumber, isInitializedRef, authServiceRef, initLogin, startMfaPoll, doLogout])

  const doLogin = useCallback(
    async (username: string | undefined, password: string | undefined, stayLoggedIn: boolean): Promise<LoginResult> => {
      if (!authServiceRef.current) {
        return {
          mfaResult: {
            errorMessage: "no auth service",
          },
        }
      }

      setLogoutSuccess(false)
      setLoading(true)

      let result: MfaServiceResult | undefined
      if (stayLoginDetails?.username && stayLoginDetails?.token) {
        result = await authServiceRef.current.login(
          undefined,
          undefined,
          stayLoggedIn,
          stayLoginDetails.username,
          stayLoginDetails.token
        )
      } else {
        result = await authServiceRef.current.login(username, password, stayLoggedIn, undefined, undefined)
      }

      if (stayLoggedIn) {
        setStayLoginSetDetails({
          username: username,
        })
      }

      setMfaResult({
        state: result.mfaResult,
        code: result.code,
      })

      setCode(result.code)
      setSessionId(result.mfaResult.sessionId)
      setLoading(false)

      if (result.mfaResult.pending && result.mfaResult.sessionId) {
        startMfaPoll(result.mfaResult.sessionId).then(() => {})
      }

      if (result.mfaResult?.sessionId) {
        localStorage.setItem(authConstants.localStorageSessionKey, result.mfaResult.sessionId)
      }

      return {
        mfaResult: result.mfaResult,
      }
    },
    [stayLoginDetails, startMfaPoll]
  )

  const removeStayLogin = useCallback(async () => {
    localStorage.removeItem(authConstants.localStorageStayLoggedInToken)
    localStorage.removeItem(authConstants.localStorageStayLoggedInUsername)
    setStayLoginDetails(undefined)
  }, [])

  const pollInterval = useCallback(async () => {
    if (!sessionId || !authServiceRef.current) {
      return
    }
    const result = await authServiceRef.current.loginPoll(sessionId)
    const mfaResult = result.mfaResult
    if (!mfaResult.pending) {
      setLoginPollActive(false)
      setLoginPollStopped(sessionId)
    }
    if (result.mfaResult.approved && result.mfaResult.sessionId && result.mfaResult.encryptedPayload) {
      await initLogin({
        sessionId: result.mfaResult.sessionId,
        encryptedPayload: result.mfaResult.encryptedPayload,
        userPayload: result.mfaResult.encryptedUserDetails || "",
      })
      if (result.mfaResult.stayLoggedInToken && stayLoginSetDetails?.username) {
        localStorage.setItem(authConstants.localStorageStayLoggedInToken, result.mfaResult.stayLoggedInToken)
        localStorage.setItem(authConstants.localStorageStayLoggedInUsername, stayLoginSetDetails.username)

        setStayLoginDetails({
          token: result.mfaResult.stayLoggedInToken,
          username: stayLoginSetDetails.username,
        })
      }
      return
    }
    setMfaResult({
      state: mfaResult,
      code: code,
    })
  }, [stayLoginSetDetails, sessionId, code, initLogin])

  useEffect(() => {
    if (!loginPollActive) {
      return
    }

    const interval = setInterval(() => {
      void pollInterval()
    }, 5 * 1000)
    //   const delay = import.meta.env.VITE_REACT_APP_SYNC_REFRESH_TIME
    void pollInterval()

    return () => {
      clearInterval(interval)
    }
  }, [loginPollActive, pollInterval])

  const reset = useCallback(async () => {
    setMfaResult(undefined)
    setCode(undefined)
    setSessionId(undefined)
    setLoginPollActive(false)
    setLoginPollStopped(undefined)
    setLogoutSuccess(false)
    setLoading(false)
    isInitializedRef.current = false
    setRefreshNumber((prev) => prev + 1)
    setStayLoginSetDetails(undefined)
  }, [])

  return (
    <ExtWebUserContext.Provider
      value={{
        doLogin: doLogin,
        doLogout: doLogout,
        doLogoutQuestion: doLogoutQuestion,
        mafResult: mafResult,
        loading: loading,
        reset: reset,
        isLoggedIn: isLoggedIn,
        user: user,
        logoutSuccess: logoutSuccess,
        stayLoginDetails: stayLoginDetails,
        removeStayLogin: removeStayLogin,
      }}
    >
      {initialLoading && <div>Loading</div>}
      {!initialLoading && !isLoggedIn && (
        <div>
          <RouterProvider router={router} />
        </div>
      )}
      {isLoggedIn && !initialLoading && props.children}
    </ExtWebUserContext.Provider>
  )
}
