import { GenericEventResponseDto, GenericEventResponseModel } from "@frontend/shared/models/EventResponseDto"
import { useCallback, useEffect, useRef, useState } from "react"
import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import { DataResult, useEventQueueContext } from "@frontend/shared/contexts/event-queue-context/EventQueueContext"

export const useGeneralSync = (refreshIterator: number | string): GenericEventResponseModel => {
  const { subscribe, unsubscribe } = useEventQueueContext()
  const [eventResult, setEventResult] = useState<{ [cacheKey: string]: GenericEventResponseDto<any> }>({})

  const doUnsubscribe = useCallback(
    (subscription: (data: DataResult) => void) => {
      unsubscribe(subscription)
      setEventResult({})
    },
    [unsubscribe]
  )

  const subscription = useCallback((data: DataResult) => {
    setEventResult((prev) => ({
      ...prev,
      [data.hashedCacheKey]: {
        event: {
          isLoaded: true,
          isLoading: false,
          createDate: data.createDate,
          expireDate: data.expireDate,
          refreshDate: data.pendingStartDate,
          hashedCacheKey: data.hashedCacheKey,
          reload: () => {},
        },
        data: data.data as any,
      },
    }))
  }, [])

  useEffect(() => {
    return () => {
      doUnsubscribe(subscription)
    }
  }, [doUnsubscribe, subscription])

  const subscribeCallback = useCallback(
    (model: any, cacheKey: string | number | undefined | null = undefined) => {
      if (!refreshIterator) {
        return
      }

      let cacheHashKey: string
      let cacheKeyFull: string | undefined

      if (cacheKey !== undefined && cacheKey !== null) {
        cacheHashKey = "" + cacheKey
        cacheKeyFull = "" + cacheKey
      } else {
        cacheKeyFull = JSON.stringify(model)
        cacheHashKey = hashedCacheKey(cacheKeyFull)
      }

      const subscriptionCallback = async (): Promise<void> => {}
      setEventResult((prev) => {
        const model = { ...prev }
        const old = prev[cacheHashKey]
        model[cacheHashKey] = {
          event: {
            isLoaded: true,
            isLoading: false,
            createDate: old?.event?.createDate,
            expireDate: old?.event?.expireDate,
            refreshDate: old?.event?.refreshDate,
            hashedCacheKey: old?.event?.hashedCacheKey,
            reload: () => {},
          },
          data: old?.data,
        }
        return model
      })

      setEventResult((old) => ({}))
      subscribe(subscription, cacheHashKey, cacheKeyFull, subscriptionCallback, true, true)
    },
    [subscribe, subscription, refreshIterator]
  )

  const responseModel = useRef<GenericEventResponseModel>([eventResult, subscribeCallback])
  responseModel.current[0] = eventResult
  responseModel.current[1] = subscribeCallback

  return responseModel.current
}
