import { Typography } from "@mui/material"
import { Editor } from "@tinymce/tinymce-react"
import "./TextEditor.css"
import React from "react"

interface TextEditorProps {
  label: string
  withToolbar: boolean
  value: string | undefined
  error: string | undefined
  onChange: (value: string) => void
  isFormChecked?: boolean
  readOnly?: boolean
  tabIndex?: number
}

export const getBaseHref = (): string => {
  return import.meta.env.BASE_URL + "tinymce/tinymce.min.js"
}

const baseHrefEditor = getBaseHref()
const plugins = ["lists"]

export const TextEditor = ({ label, withToolbar, value, onChange, readOnly, tabIndex }: TextEditorProps) => {
  return (
    <>
      <Typography variant="subtitle1" fontSize="16px" borderBottom="none" margin={0}>
        {label}
      </Typography>
      <Editor
        disabled={readOnly}
        value={value}
        onEditorChange={(newContent) => onChange(newContent)}
        tinymceScriptSrc={baseHrefEditor}
        init={{
          min_height: 300,
          menubar: false,
          statusbar: false,
          language: "de",
          plugins: plugins,
          toolbar: withToolbar
            ? "undo redo | blocks | bold italic | " +
              "alignleft aligncenter alignright alignjustify | " +
              " bullist numlist outdent indent |"
            : false,
        }}
      />
    </>
  )
}
