import CryptoES from "crypto-es"

class CryptoLib {
  public async generateKeys(): Promise<CryptoKeyPair> {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!window.crypto || !window.crypto.subtle) {
      throw Error("Your current browser does not support the Web Cryptography API! This page will not work.")
    }
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!window.indexedDB) {
      throw Error("Your current browser does not support IndexedDB. This page will not work.")
    }

    // https://blog.engelke.com/2014/09/19/saving-cryptographic-keys-in-the-browser/
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/exportKey
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]), // 24 bit representation of 65537
        hash: {
          name: "SHA-1",
        },
      },
      false, // Cannot extract new key
      ["encrypt", "decrypt"]
    )
    return keyPair
  }

  public async exportPublicKey(key: CryptoKey): Promise<string> {
    const exported = await window.crypto.subtle.exportKey("spki", key)
    const exportedAsString = this.ab2str(exported)
    const exportedAsBase64 = window.btoa(exportedAsString)
    const pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`

    return pemExported
  }

  public async decryptRSA(privateKey: CryptoKey, messageEncrypted: string): Promise<string> {
    const messageEncryptedBytes = this.base64ToArrayBuffer(messageEncrypted)
    const decryptedText = await window.crypto.subtle.decrypt(
      {
        name: "RSA-OAEP",
      },
      privateKey,
      messageEncryptedBytes
    )
    const dec = new TextDecoder()
    const result = dec.decode(decryptedText)
    return result
  }

  public async encryptAes(pkdbfKey5: CryptoKey, message: string): Promise<string> {
    const ivBuff = window.crypto.getRandomValues(new Uint8Array(16))
    const textEncoder = new TextEncoder()
    const plainBytes = textEncoder.encode(message)

    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv: ivBuff,
      },
      pkdbfKey5,
      plainBytes
    )

    const tmp = new Uint8Array(ivBuff.byteLength + encrypted.byteLength)
    tmp.set(new Uint8Array(ivBuff), 0)
    tmp.set(new Uint8Array(encrypted), ivBuff.byteLength)

    const result = this.arrayBufferToBase64(tmp)

    return result
  }

  public async decryptAes(pkdbfKey5: CryptoKey, messageEncrypted: string): Promise<string> {
    const messageBytes = this.base64ToArrayBuffer(messageEncrypted)

    const ivBuff = messageBytes.slice(0, 16)
    const ciphertext = messageBytes.slice(16)

    // const decrypted = await this.AES_CBC_DECRYPT_NO_PADDING(ciphertext,pkdbfKey5,ivBuff )
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-CBC",
        iv: ivBuff,
      },
      pkdbfKey5,
      ciphertext
    )
    const textEncoder = new TextDecoder("utf-8")
    const result1 = textEncoder.decode(new Uint8Array(decrypted))
    return result1
  }

  public async getAesCryptoKey(base64encodedKey: string): Promise<CryptoKey | undefined> {
    const aesKeyBytes = this.base64ToArrayBuffer(base64encodedKey)

    const pkdbfKey5 = await window.crypto.subtle.importKey(
      "raw",
      aesKeyBytes,
      {
        name: "AES-CBC",
      },
      false,
      ["encrypt", "decrypt"]
    )
    return pkdbfKey5
  }

  public async getAesCryptoKeyPassphrase(saltAndPassphrase: string): Promise<CryptoKey | undefined> {
    const aesSalt = saltAndPassphrase.substring(0, 32)
    const aesPassphrase = saltAndPassphrase.substring(32)

    const encoder = new TextEncoder()
    const passphraseKey = encoder.encode(aesPassphrase)
    const saltBuffer = encoder.encode(aesSalt)

    const pkdbfKey = await window.crypto.subtle.importKey("raw", passphraseKey, { name: "PBKDF2" }, false, [
      "deriveBits",
      "deriveKey",
    ])

    const aesKey = await window.crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: saltBuffer,
        iterations: 10000,
        hash: "SHA-1",
      },
      pkdbfKey,
      { name: "AES-CBC", length: 256 },
      true,
      ["encrypt", "decrypt"]
    )

    const aesKeyBytes = await crypto.subtle.exportKey("raw", aesKey)
    const aesKeyBytes2 = aesKeyBytes.slice(0, 32)

    const pkdbfKey5 = await window.crypto.subtle.importKey("raw", aesKeyBytes2, { name: "AES-CBC" }, false, [
      "encrypt",
      "decrypt",
    ])
    return pkdbfKey5
  }

  public sha256(value: string): string {
    const plain = CryptoES.SHA256(value)
    const hexPlain = CryptoES.enc.Hex.stringify(plain)
    return hexPlain
  }

  /*
  Convert an ArrayBuffer into a string
  from https://developer.chrome.com/blog/how-to-convert-arraybuffer-to-and-from-string/
  */
  public ab2str(buf: ArrayBuffer) {
    // @ts-ignore
    return String.fromCharCode.apply(null, new Uint8Array(buf))
  }

  private arrayBufferToBase64(bytes: Uint8Array) {
    let binary = ""
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  private base64ToArrayBuffer(base64: string) {
    const binaryString = atob(base64)
    const bytes = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  }

  private logAsHexString(message: string, bytes: ArrayBuffer): void {
    const view = new Uint8Array(bytes)
    // @ts-ignore
    const str = view
      // @ts-ignore
      .map((byte) => {
        return (byte & 0xff).toString(10)
      })
      .join(" ")
  }

  public showThumbNailFromPublicKey(sessionId: string, publicKey: string): string | undefined {
    const z = publicKey.match(/[\\-]{4,8}[^-]*[\\-]{4,8}(.*)[\\-]{4,9}[^-]*[\\-]{3,8}/s)
    if (z && z[1]) {
      const result1 = z[1].replace(/[^A-Za-z0-9+/=]/g, "")

      const code = this.hashCode(result1)
      const result = Math.abs(code) % 999999

      return `${result}`
    }
  }

  public showThumbNailFromPublicKeyV2(sessionId: string, publicKey: string | undefined): string | undefined {
    const alphabet = "1234567890ABCDEFGHIJKLMNOPRSTUVWXYZ"

    let normalizedPublicKey = ""
    if (publicKey) {
      const z = publicKey.match(/[\\-]{4,8}[^-]*[\\-]{4,8}(.*)[\\-]{4,9}[^-]*[\\-]{3,8}/s)
      if (z && z[1]) {
        normalizedPublicKey = z[1].replace(/[^A-Za-z0-9+/=]/g, "")
      } else {
        throw Error("Public Key Wrong")
      }
    }

    const fingerPrint1 = sessionId + (normalizedPublicKey ? "-" + normalizedPublicKey : "")

    const sha256Hash = CryptoES.SHA256(fingerPrint1).toString().toLowerCase()

    const len = 4
    const n = sha256Hash.length / len
    let result = ""
    for (let i = 0; i < len; i++) {
      const part = sha256Hash.substring(i * n, i * n + n)
      let hash = this.hashCode(part)
      if (hash < 0) {
        hash = -hash
      }
      const ch = alphabet[hash % alphabet.length]
      result += ch
    }
    return `${result}`
  }

  public hashCode(value: string): number {
    let hash = 0
    for (let i = 0; i < value.length; i++) {
      const code = value.charCodeAt(i)
      hash = (hash << 5) - hash + code
      hash = hash & hash // Convert to 32bit integer
    }
    return hash
  }
}

export const cryptoLib = new CryptoLib()
