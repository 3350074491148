import React from "react"
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import Logo from "../../../assets/logo.png"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import { useTranslation } from "react-i18next"
import { useExtWebUserContext } from "@frontend/extweb-lib/contexts/user-context/ExtWebUserContext"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"

const styles = {
  container: {
    position: "sticky",
    top: 0,
    zIndex: 998,
    backgroundColor: "#0048B5",
    height: "64px",
    padding: "0 24px 0 24px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "0 0 8px 8px",
    color: "#FFFFFF",
  },
  firstBox: { flexDirection: "row", gap: "12px", alignItems: "center" },
  secondBox: { flexDirection: "row", gap: "11px" },
  iconContainer: { flexDirection: "row", paddingRight: "11px" },
  icon: { color: "#FFFFFF" },
}

const Header = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, doLogoutQuestion } = useExtWebUserContext()

  return (
    <Stack data-testid="test-header" component="header" sx={styles.container}>
      <Stack sx={styles.firstBox}>
        <Box component="img" src={Logo}></Box>
        <Typography variant="h5" fontFamily="EB Garamond Regular">
          {t("header.contactDocumentation")}
        </Typography>
      </Stack>
      <Stack sx={styles.secondBox}>
        <Stack sx={styles.iconContainer}>
          <Tooltip title={user && `${user?.firstName} ${user?.lastName}`}>
            <IconButton>
              <AccountCircleOutlinedIcon sx={styles.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("header.logout")}>
            <IconButton
              onClick={() => {
                doLogoutQuestion()
              }}
            >
              <LogoutIcon sx={styles.icon} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Header
