/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate, ModelObject } from "@customTypes"
import { Schema } from "./shared"

export interface AcceptAppointmentRequestDto {
  encryptedData: string
  __Schema?: AcceptAppointmentRequestSchema
  hashedCacheKey: Guid
}

export interface AcceptAppointmentRequestSchema {
  RecordId: number
}

export interface AcceptAppointmentResponseDto {
  encryptedData: string
  __Schema?: AcceptAppointmentResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface AcceptAppointmentResponseSchema {
  RecordId: number
  Success: boolean
  ErrorMessage: string
}

export interface AdditonalParameter {
  ParentId: number
  Value: string
  Id: number
}

export interface AdditonalParameterMeta {
  Title: string
  ParameterType: AdditonalParameterType
  Values?: SelectValue[]
  PresetTime: boolean
  PresetDate: boolean
  Id: number
}

export type AdditonalParameterType = 0 | 10 | 20 | 30 | 40 | 50 | 60

export const AdditonalParameterType = {
  None: 0 as AdditonalParameterType,
  Text: 10 as AdditonalParameterType,
  RichText: 20 as AdditonalParameterType,
  Date: 30 as AdditonalParameterType,
  Time: 40 as AdditonalParameterType,
  Select: 50 as AdditonalParameterType,
  DoubleSelect: 60 as AdditonalParameterType,
}
export interface AttachmentDeleteRequestDto {
  encryptedData: string
  __Schema?: AttachmentDeleteRequestSchema
  hashedCacheKey: Guid
}

export interface AttachmentDeleteRequestSchema {
  AttachmentId: number
}

export interface AttachmentDeleteResponseDto {
  encryptedData: string
  __Schema?: AttachmentDeleteResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface AttachmentDeleteResponseSchema {
  RecordId: number
  Success: boolean
  ErrorMessage: string
}

export interface AttachmentDetails {
  attachments?: AttachmentItem[]
}

export interface AttachmentDownloadRequestDto {
  encryptedData: string
  __Schema?: AttachmentDownloadRequestSchema
  hashedCacheKey: Guid
  dokuAttachmentId: number
  dokuAttachmentGuid: Guid
}

export interface AttachmentDownloadRequestSchema {
  AttachmentId: number
}

export interface AttachmentDownloadResponseDto {
  encryptedData: string
  __Schema?: AttachmentDownloadResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface AttachmentDownloadResponseSchema {
  Success: boolean
  ErrorMessage: string
}

export interface AttachmentItem {
  dokuFileId: number
  dokuRecordId: number
  dokuAttachmentGuid?: Guid
  downloadAvailable: boolean
  sizeInKb: number
  isWebDav: boolean
  webDav?: WebDavAttachmentItem
}

export interface AttachmentListItem {
  Name: string
  Extension: string
  CanBeDeleted: boolean
  AttachmentGuid: Guid
  Id: number
}

export interface AttachmentMapping {
  NewAttachmentGuid: Guid
  AttachmentGuid: Guid
}

export interface AttachmentType {
  FileExtension: string
}

export interface AttachmentUploadFileResponseDto {
  fileKey: string
  success: boolean
  error: string
}

export interface AttachmentUploadRequestDto {
  encryptedData: string
  __Schema?: AttachmentUploadRequestSchema
  hashedCacheKey: Guid
  fileKey: string
}

export interface AttachmentUploadRequestSchema {
  RecordId: number
  FileName: string
}

export interface AttachmentUploadResponseDto {
  encryptedData: string
  __Schema?: AttachmentUploadResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface AttachmentUploadResponseSchema {
  RecordId: number
  AttachmentId: number
  ErrorMessage: string
}

export interface ClientDetails {
  FirstName: string
  LastName: string
  Birthday: string
  Phone: string
  MobilePhone: string
  Street: string
  ZipCode: string
  City: string
  PersonId: number
}

export interface ContactType {
  Name: string
  Id: number
}

export interface CorrespondenceDocumentType {
  Name: string
  Templates?: CorrespondenceTemplateBase[]
  DocumentTypeId: number
}

export interface CorrespondenceTemplate {
  Name: string
  Description: string
  Participants?: CorrespondenceTemplateParticipants[]
  AdditonalParameters?: AdditonalParameterMeta[]
  Flags?: FormFlags[]
  TemplateId: number
}

export interface CorrespondenceTemplateBase {
  Name: string
  Description: string
  TemplateId: number
}

export interface CorrespondenceTemplateParticipants {
  ParticipantId: string
  Name: string
  Selected: boolean
  PersonId: number
}

export interface CreateContactListItems {
  Locations?: Location[]
  ContactTypes?: ContactType[]
  AllowedAttachmentTypes?: AttachmentType[]
  Participants?: PersonListItem[]
}

export interface CreateCorrespondenceListItems {
  ContactTypes?: ContactType[]
  Supervisors?: EmployeeListItem[]
  AllowedAttachmentTypes?: AttachmentType[]
  Locations?: Location[]
}

export interface CreateCorrespondenceMetaRequestDto {
  encryptedData: string
  __Schema?: CreateCorrespondenceMetaRequestSchema
  hashedCacheKey: Guid
}

export interface CreateCorrespondenceMetaRequestSchema {
  FileId: number
}

export interface CreateCorrespondenceMetaResponseDto {
  encryptedData: string
  __Schema?: CreateCorrespondenceMetaResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface CreateCorrespondenceMetaResponseSchema {
  DocumentTypes?: CorrespondenceDocumentType[]
}

export interface CreateParticipantItem {
  ParticipantId: string
  InvitationState: InvitationState
  PersonId: number
}

export interface DeleteRecordRequestDto {
  encryptedData: string
  __Schema?: DeleteRecordRequestSchema
  hashedCacheKey: Guid
}

export interface DeleteRecordRequestSchema {
  RecordId: number
}

export interface DeleteRecordResponseDto {
  encryptedData: string
  __Schema?: DeleteRecordResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface DeleteRecordResponseSchema {
  RecordId: number
  Success: boolean
  ErrorMessage: string
}

export interface DocuDtos {
  fileListRequestDto?: FileListRequestDto
  fileListResponseDto?: FileListResponseDto
  fileRecordListRequestDto?: FileRecordListRequestDto
  fileRecordListResponseDto?: FileRecordListResponseDto
  deleteRecordRequestDto?: DeleteRecordRequestDto
  deleteRecordResponseDto?: DeleteRecordResponseDto
  upsertContactRequestDto?: UpsertContactRequestDto
  viewContactRequestDto?: ViewContactRequestDto
  viewContactResponseDto?: ViewContactResponseDto
  createCorrespondenceMetaRequestDto?: CreateCorrespondenceMetaRequestDto
  createCorrespondenceMetaResponseDto?: CreateCorrespondenceMetaResponseDto
  upsertCorrespondenceRequestDto?: UpsertCorrespondenceRequestDto
  viewCorrespondenceRequestDto?: ViewCorrespondenceRequestDto
  viewCorrespondenceResponseDto?: ViewCorrespondenceResponseDto
  extWebAttachmentUploadRequestDto?: AttachmentUploadRequestDto
  extWebAttachmentUploadResponseDto?: AttachmentUploadResponseDto
  extWebAttachmentDeleteRequestDto?: AttachmentDeleteRequestDto
  extWebAttachmentDeleteResponseDto?: AttachmentDeleteResponseDto
  extWebAttachmentDownloadRequestDto?: AttachmentDownloadRequestDto
  extWebAttachmentDownloadResponseDto?: AttachmentDownloadResponseDto
  extWebWebDavUpdateRequestSchemaDto?: WebDavUpdateRequestSchemaDto
  extWebWebDavUpdateResponseDto?: WebDavUpdateResponseDto
  sendToAppRequestDto?: SendToAppRequestDto
  sendToAppResponseDto?: SendToAppResponseDto
  webDavApprovalRequestDto?: WebDavApprovalRequestDto
  webDavApprovalResponseDto?: WebDavApprovalResponseDto
  acceptAppointmentRequestDto?: AcceptAppointmentRequestDto
  acceptAppointmentResponseDto?: AcceptAppointmentResponseDto
}

export interface DocuSchemas {
  ExtWebUserResponseSchema?: ExtWebUserResponseSchema
  Schema: Schema
  FileListRequestSchema?: FileListRequestSchema
  FileListResponseSchema?: FileListResponseSchema
  FileRecordListRequestSchema?: FileRecordListRequestSchema
  FileRecordListResponseSchema?: FileRecordListResponseSchema
  UpsertContactRequestSchema?: UpsertContactRequestSchema
  UpsertContactResponseSchema?: UpsertContactResponseSchema
  ViewContactRequestSchema?: ViewContactRequestSchema
  ViewContactResponseSchema?: ViewContactResponseSchema
  CreateCorrespondenceMetaRequestSchema?: CreateCorrespondenceMetaRequestSchema
  CreateCorrespondenceMetaResponseSchema?: CreateCorrespondenceMetaResponseSchema
  UpsertCorrespondenceRequestSchema?: UpsertCorrespondenceRequestSchema
  UpsertCorrespondenceResponseSchema?: UpsertCorrespondenceResponseSchema
  ViewCorrespondenceRequestSchema?: ViewCorrespondenceRequestSchema
  ViewCorrespondenceResponseSchema?: ViewCorrespondenceResponseSchema
  AttachmentUploadRequestSchema?: AttachmentUploadRequestSchema
  AttachmentUploadResponseSchema?: AttachmentUploadResponseSchema
  AttachmentDeleteRequestSchema?: AttachmentDeleteRequestSchema
  AttachmentDeleteResponseSchema?: AttachmentDeleteResponseSchema
  AttachmentDownloadRequestSchema?: AttachmentDownloadRequestSchema
  AttachmentDownloadResponseSchema?: AttachmentDownloadResponseSchema
  WebDavUpdateRequestSchema?: WebDavUpdateRequestSchema
  WebDavUpdateResponseSchema?: WebDavUpdateResponseSchema
  SchemaType: Schema
}

export type EWebDavSate = 0 | 10 | 20

export const EWebDavSate = {
  NotModified: 0 as EWebDavSate,
  SavedAndWaitingForUpload: 10 as EWebDavSate,
  SavedAndUploadedToDoku: 20 as EWebDavSate,
}
export interface EmployeeListItem {
  Name: string
  PersonId: number
}

export interface ErrorMessage {
  Message: string
  Path: string
}

export interface ExtWebUserResponseSchema {
  Firstname: string
  Lastname: string
  Username: string
}

export interface FileExistsResponse {
  exists: boolean
}

export interface FileListItemSchema {
  FileNumber: string
  ClientDetails?: ClientDetails
  SupervisorDetails?: EmployeeListItem
  AdditionalSupervisorDetails?: EmployeeListItem
  LastReportUtc?: StringDate
  LastPersonalConctactUtc?: StringDate
  FileId: number
}

export interface FileListRequestDto {
  encryptedData: string
  __Schema?: FileListRequestSchema
  hashedCacheKey: Guid
}

export interface FileListRequestSchema {
  Query?: FileSearchQuery
  PageSize: number
  Page: number
}

export interface FileListResponseDto {
  encryptedData: string
  __Schema?: FileListResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface FileListResponseSchema {
  Files?: FileListItemSchema[]
  ItemsCount: number
  PageSize: number
  Page: number
}

export interface FileRecordListRequestDto {
  encryptedData: string
  __Schema?: FileRecordListRequestSchema
  hashedCacheKey: Guid
}

export interface FileRecordListRequestSchema {
  FileId: number
  PageSize: number
  Page: number
}

export interface FileRecordListResponseDto {
  encryptedData: string
  __Schema?: FileRecordListResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface FileRecordListResponseSchema {
  Records?: RecordItem[]
  FileId: number
  ItemsCount: number
  PageSize: number
  Page: number
}

export interface FileSearchQuery {}

export type FormFlags = 0 | 10

export const FormFlags = {
  None: 0 as FormFlags,
  SyncAppointmentWithAppCheckbox: 10 as FormFlags,
}
export type InvitationState = 0 | 10 | 20 | 30 | 40 | 50

export const InvitationState = {
  None: 0 as InvitationState,
  Invited: 10 as InvitationState,
  Missed: 20 as InvitationState,
  Accepted: 30 as InvitationState,
  Declined: 40 as InvitationState,
  Cancelled: 50 as InvitationState,
}
export interface Location {
  Name: string
  Id: number
}

export interface PersonListItem {
  ParticipantId: string
  Name: string
  InvitationState: InvitationState
  PersonId: number
}

export interface RecordItem {
  RecordType: RecordType
  Title: string
  DateUtc: StringDate
  FirstName: string
  LastName: string
  Location: string
  ContactType?: ContactType
  Participants?: PersonListItem[]
  CanSetReminder: boolean
  HasReminderSet: boolean
  ReminderValue: string
  ShowAppointmentState: boolean
  AppointmentState: string
  AppointmentAlternativeTimeslot: string
  AppointmentDeclinedReason: string
  ShowSendToApp: boolean
  Attachments?: AttachmentListItem[]
  ReadOnly: boolean
  CanRequestApproval: boolean
  RecordId: number
}

export type RecordType = 0 | 10 | 15 | 20

export const RecordType = {
  None: 0 as RecordType,
  Contact: 10 as RecordType,
  PastContact: 15 as RecordType,
  Correspondence: 20 as RecordType,
}
export interface SelectValue {
  Value: string
  IsDefault: boolean
  ShowClosedIcon: boolean
  Values?: SelectValue[]
  Id: number
}

export interface SendToAppRequestDto {
  encryptedData: string
  __Schema?: SendToAppRequestSchema
  hashedCacheKey: Guid
}

export interface SendToAppRequestSchema {
  RecordId: number
}

export interface SendToAppResponseDto {
  encryptedData: string
  __Schema?: SendToAppResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface SendToAppResponseSchema {
  RecordId: number
  Success: boolean
  ErrorMessage: string
}

export interface UpsertContactRecord {
  RecordId: number
  Participants?: CreateParticipantItem[]
  SupervisorId: number
  LocationId: number
  ContactTypeId: number
  FromDateUtc: StringDate
  ToDateUtc: StringDate
  ContentBody: string
  LastChangeDateUtc?: StringDate
  SuppressSyncAppointmentWithApp: boolean
  ShowSuppressSyncAppointmentWithApp: boolean
  ShowAppointmentState: boolean
  ShowAppointmentAccept: boolean
  AppointmentState: string
  AppointmentAlternativeTimeslot?: StringDate
  AppointmentDeclinedReason: string
  Attachments?: AttachmentListItem[]
  FileId: number
}

export interface UpsertContactRequestDto {
  encryptedData: string
  __Schema?: UpsertContactRequestSchema
  hashedCacheKey: Guid
  hashedTagKey: Guid
}

export interface UpsertContactRequestSchema {
  Contact?: UpsertContactRecord
  RecordType: RecordType
}

export interface UpsertContactResponseSchema {
  Contact?: UpsertContactRecord
  RecordType: RecordType
  Errors?: ErrorMessage[]
  Error: string
  Success: boolean
}

export interface UpsertCorrespondenceRecord {
  RecordId: number
  Participants?: CreateParticipantItem[]
  DocumentTypeId: number
  TemplateId: number
  SupervisorId: number
  SupervisorName?: string
  ContactTypeId: number
  LocationId: number
  FromDateUtc: StringDate
  ToDateUtc: StringDate
  Title: string
  ContentBody: string
  AdditonalParameters?: AdditonalParameter[]
  LastChangeDateUtc?: StringDate
  Attachments?: AttachmentListItem[]
  ReadOnly: boolean
  FileId: number
}

export interface UpsertCorrespondenceRequestDto {
  encryptedData: string
  __Schema?: UpsertCorrespondenceRequestSchema
  hashedCacheKey: Guid
  hashedTagKey: Guid
}

export interface UpsertCorrespondenceRequestSchema {
  Correspondence?: UpsertCorrespondenceRecord
  IsAppointmentInvite: boolean
}

export interface UpsertCorrespondenceResponseSchema {
  Correspondence?: UpsertCorrespondenceRecord
  Errors?: ErrorMessage[]
  Error: string
  Success: boolean
}

export interface ViewContactRecord {
  RecordId: number
  Participants?: CreateParticipantItem[]
  SupervisorId: number
  SupervisorName: string
  LocationId: number
  ContactTypeId: number
  FromDateUtc: StringDate
  ToDateUtc: StringDate
  ContentBody: string
  LastChangeDateUtc?: StringDate
  SuppressSyncAppointmentWithApp: boolean
  ShowSuppressSyncAppointmentWithApp: boolean
  ShowAppointmentState: boolean
  ShowAppointmentAccept: boolean
  AppointmentState: string
  AppointmentAlternativeTimeslot?: StringDate
  AppointmentDeclinedReason: string
  Attachments?: AttachmentListItem[]
  FileId: number
}

export interface ViewContactRequestDto {
  encryptedData: string
  __Schema?: ViewContactRequestSchema
  hashedCacheKey: Guid
}

export interface ViewContactRequestSchema {
  FileId: number
  RecordId: number
}

export interface ViewContactResponseDto {
  encryptedData: string
  __Schema?: ViewContactResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface ViewContactResponseSchema {
  Contact?: ViewContactRecord
  RecordType: RecordType
  ListItems?: CreateContactListItems
}

export interface ViewCorrespondenceRecord {
  RecordId: number
  Participants?: CreateParticipantItem[]
  DocumentTypeId: number
  TemplateId: number
  SupervisorId: number
  SupervisorName: string
  ContactTypeId: number
  LocationId: number
  FromDateUtc: StringDate
  ToDateUtc: StringDate
  Title: string
  ContentBody: string
  AdditonalParameters?: AdditonalParameter[]
  LastChangeDateUtc?: StringDate
  Attachments?: AttachmentListItem[]
  ReadOnly: boolean
  FileId: number
}

export interface ViewCorrespondenceRequestDto {
  encryptedData: string
  __Schema?: ViewCorrespondenceRequestSchema
  hashedCacheKey: Guid
}

export interface ViewCorrespondenceRequestSchema {
  FileId: number
  RecordId: number
  TemplateId: number
}

export interface ViewCorrespondenceResponseDto {
  encryptedData: string
  __Schema?: ViewCorrespondenceResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface ViewCorrespondenceResponseSchema {
  Correspondence?: ViewCorrespondenceRecord
  RecordType: RecordType
  ListItems?: CreateCorrespondenceListItems
  Template?: CorrespondenceTemplate
}

export interface WebDavApprovalRequestDto {
  encryptedData: string
  __Schema?: WebDavApprovalRequestSchema
  hashedCacheKey: Guid
}

export interface WebDavApprovalRequestSchema {
  RecordId: number
}

export interface WebDavApprovalResponseDto {
  encryptedData: string
  __Schema?: WebDavApprovalResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
}

export interface WebDavApprovalResponseSchema {
  RecordId: number
  Success: boolean
  ErrorMessage: string
}

export interface WebDavAttachmentItem {
  dokuAttachmentGuid?: Guid
  state: EWebDavSate
  lastEditDateUtc?: StringDate
  openInWord: boolean
  encryptedData: string
  encryptedDataDateUtc?: StringDate
}

export interface WebDavUpdateRequestSchema {
  RecordId: number
  AttachmentId: number
}

export interface WebDavUpdateRequestSchemaDto {
  encryptedData: string
  __Schema?: WebDavUpdateRequestSchema
  hashedCacheKey: Guid
}

export interface WebDavUpdateResponseDto {
  encryptedData: string
  __Schema?: WebDavUpdateResponseSchema
  dokuUserId: Guid
  syncHandle: Guid
  attachmentMapping?: AttachmentMapping[]
}

export interface WebDavUpdateResponseSchema {
  RecordId: number
  ErrorMessage: string
}
