import { decryptAes } from "@frontend/shared/crypto-new/aesCrypto"

export const getData = async (encrypted: string): Promise<any> => {
  if (!encrypted) {
    return undefined
  }
  const plainResponseData = await decryptAes(encrypted)
  const data = JSON.parse(plainResponseData)
  return data
}
