import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { Schema } from "@frontend/extweb-api/swagger/model/shared"
import { Guid } from "@customTypes"
import { getData } from "@frontend/shared/utils/data.utils"
import { fetchLoopWaitTimeInSec } from "@frontend/shared/constants"

export enum EventQueueEventAction {
  None = 0,
  EncryptionInitialized = 10,
}

export interface EventQueueEvent {
  action: EventQueueEventAction
}

interface EventQueueContextValue {
  subscribe: (
    subscription: (data: DataResult) => void,
    cacheKey: Guid,
    cacheKeyDebug: string,
    loadFunc: () => Promise<void>,
    disableInitialLoad: boolean,
    noCache: boolean
  ) => void
  unsubscribe: (subscription: (data: DataResult) => void) => void
  reloadData: (subscription: (data: DataResult) => void) => void
  eventHandler: (evt: EventQueueEvent) => Promise<void>
}

export interface DataResult {
  hashedCacheKey: string
  data: any
  pendingStartDate: Date | undefined
  createDate: Date | undefined
  expireDate: Date | undefined
  errorMessage: string | undefined
  error: any
}

export const EventQueueContext = createContext<EventQueueContextValue>({
  subscribe: (
    _subscription: (data: DataResult) => void,
    _cacheKey: Guid,
    _cacheKeyDebug: string,
    _loadFunc: () => Promise<void>,
    _disableInitialLoad: boolean
  ) => {},
  unsubscribe: (_subscription: (data: DataResult) => void) => {},
  reloadData: (_subscription: (data: DataResult) => void) => {},
  eventHandler: async (_evt: EventQueueEvent) => {},
})

export interface EventListItem {
  cacheKey: Guid
  cacheKeyDebug: string
  subscription: (data: DataResult) => void
  loadFunc: () => Promise<void>
  loadFuncExecuted: boolean
  disableInitialLoad: boolean
  noCache: boolean
}

export interface PendingRefresh {
  createTimestamp: number | undefined
  expiryTimestamp: number | undefined
  requestData: unknown
}

export interface LoadedCachedItem {
  loaded: boolean
}

export interface CachedDataItem {
  schema: Schema
  expiryTimestamp: number | undefined
  createTimestamp: number | undefined
  cacheKey: Guid
  data: unknown
  pending: boolean
  isAck: boolean
  requestData: unknown
  pendingRefresh: PendingRefresh | undefined
  reloaded: boolean
  errorMessage: string | undefined
  error: any
}

let _fetchIsOngoing = false
let _subscriptions: EventListItem[] = []
const _cachedData: { [cacheKey: string]: CachedDataItem } = {}
const _loaded: { [cacheKey: string]: LoadedCachedItem } = {}
const autoReload = false
export const useEventQueueContext = (): EventQueueContextValue => useContext(EventQueueContext)

export const EventQueueProvider: FC<PropsWithChildren<{}>> = (props) => {
  const api = useExtWebApi()
  const loopEnabledRef = useRef<any>()
  const intervalRef = useRef<any>()
  const intervalThrottleRef = useRef<any>()

  const loopIterator = useCallback(async (): Promise<boolean> => {
    if (_fetchIsOngoing) {
      return true
    }
    _fetchIsOngoing = true
    setTimeout(() => {
      _fetchIsOngoing = false
    }, 10 * 60 * 1000)

    const timestampNow = new Date().getTime()

    const requestCacheKeys: { [key: string]: boolean } = {}

    for (const subscription of _subscriptions) {
      const cacheKey = subscription.cacheKey
      let load = true
      const cachedValue = _cachedData[cacheKey]

      if (subscription.noCache && !subscription.loadFuncExecuted) {
        subscription.loadFuncExecuted = true
        if (cachedValue && !cachedValue.pendingRefresh) {
          cachedValue.pendingRefresh = {} as any
        }
        await subscription.loadFunc()
      }

      if (cachedValue && (!subscription.noCache || (subscription.noCache && !cachedValue.pendingRefresh))) {
        if (cachedValue.expiryTimestamp && cachedValue.expiryTimestamp < timestampNow) {
          if (!cachedValue.pendingRefresh) {
            // auto reload, when expired
            if (!autoReload) {
              load = false
            }
          }
        } else {
          if (!cachedValue.reloaded && !cachedValue.pendingRefresh) {
            load = false
          }
        }
        if (!cachedValue.data) {
          load = true
        }
      }
      if (load) {
        requestCacheKeys[cacheKey] = true
      }
    }
    const requestCacheKeyList = Object.keys(requestCacheKeys)
    if (requestCacheKeyList.length === 0) {
      _fetchIsOngoing = false
      return false
    }

    const syncResponse = await api.syncService.getSyncList_POST({
      cacheKeys: requestCacheKeyList,
    })
    if (!syncResponse.ok) {
      _fetchIsOngoing = false
      return true
    }
    const result1 = syncResponse.result

    const entries = result1?.entries || []

    for (const entry of entries) {
      const plainEntry: CachedDataItem = {
        expiryTimestamp: entry.exiryDateUtc ? new Date(entry.exiryDateUtc + "Z").getTime() : undefined,
        createTimestamp: entry.createDateUtc ? new Date(entry.createDateUtc + "Z").getTime() : undefined,
        cacheKey: entry.cacheKey,
        schema: entry.schema,
        data: undefined,
        isAck: entry.isAck,
        pending: entry.pending,
        requestData: undefined,
        pendingRefresh: undefined,
        reloaded: false,
        errorMessage: undefined,
        error: undefined,
      }
      const subsc = _subscriptions.find((x) => x.cacheKey === entry.cacheKey)
      const initialLoad = !_loaded[entry.cacheKey] && !subsc?.disableInitialLoad
      if (initialLoad) {
        plainEntry.reloaded = true
      }

      if (entry.pendingRefresh) {
        const plainRequestData1 = await getData(entry.pendingRefresh.requestData)
        plainEntry.pendingRefresh = {
          requestData: plainRequestData1,
          createTimestamp: entry.pendingRefresh.createDateUtc
            ? new Date(entry.pendingRefresh.createDateUtc + "Z").getTime()
            : undefined,
          expiryTimestamp: entry.pendingRefresh.exiryDateUtc
            ? new Date(entry.pendingRefresh.exiryDateUtc + "Z").getTime()
            : undefined,
        }
      } else if (initialLoad) {
        plainEntry.pendingRefresh = {
          requestData: undefined,
          createTimestamp: timestampNow,
          expiryTimestamp: undefined,
        }
      } else {
        plainEntry.pendingRefresh = undefined
      }

      try {
        const plainRequestData = await getData(entry.requestData)
        const plainResponseData = await getData(entry.responseData)

        plainEntry.requestData = plainRequestData
        plainEntry.data = plainResponseData
      } catch (e: any) {
        plainEntry.error = e
        plainEntry.errorMessage = "Decryption error"
      }
      _cachedData[entry.cacheKey] = plainEntry
    }

    for (const requestCacheKey of requestCacheKeyList) {
      for (const subscription of _subscriptions) {
        if (subscription.cacheKey !== requestCacheKey) {
          continue
        }
        let load = true
        const cachedValue = _cachedData[requestCacheKey]
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (cachedValue) {
          if (cachedValue.expiryTimestamp && cachedValue.expiryTimestamp >= timestampNow) {
            load = false
          } else {
            if (
              cachedValue.pendingRefresh?.expiryTimestamp &&
              cachedValue.pendingRefresh.expiryTimestamp >= timestampNow
            ) {
              load = false
            }
          }
        }
        const subsc = _subscriptions.find((x) => x.cacheKey === requestCacheKey)

        const initialLoad = !_loaded[requestCacheKey] && !subsc?.disableInitialLoad

        if (
          cachedValue &&
          cachedValue.data &&
          (!subscription.noCache || (!cachedValue.pendingRefresh && subscription.noCache))
        ) {
          try {
            subscription.subscription({
              hashedCacheKey: cachedValue.cacheKey,
              data: cachedValue.data,
              createDate: cachedValue.createTimestamp ? new Date(cachedValue.createTimestamp) : undefined,
              expireDate: cachedValue.expiryTimestamp ? new Date(cachedValue.expiryTimestamp) : undefined,
              pendingStartDate: cachedValue.pendingRefresh?.createTimestamp
                ? new Date(cachedValue.pendingRefresh.createTimestamp)
                : initialLoad
                ? new Date(timestampNow)
                : undefined,
              error: cachedValue.error,
              errorMessage: cachedValue.errorMessage,
            })
          } catch (e: any) {
            console.log(e)
          }
        }

        if (load || initialLoad) {
          if (!subscription.loadFuncExecuted) {
            subscription.loadFuncExecuted = true
            await subscription.loadFunc()
          }
        }
        if (!_loaded[requestCacheKey]) {
          _loaded[requestCacheKey] = {
            loaded: true,
          }
        }
      }
    }

    _fetchIsOngoing = false
    return true
  }, [api])

  const doLoop = useCallback(() => {
    if (!loopEnabledRef.current) {
      return
    }
    clearInterval(intervalRef.current)
    intervalRef.current = setInterval(() => {
      loopIterator().then((continueBool) => {
        if (!continueBool) {
          clearInterval(intervalRef.current)
        }
      })
    }, fetchLoopWaitTimeInSec * 1000)

    // throttle
    if (!intervalThrottleRef.current) {
      intervalThrottleRef.current = setInterval(() => {
        void loopIterator()
        clearInterval(intervalThrottleRef.current)
        intervalThrottleRef.current = undefined
      }, 100)
    }
  }, [intervalRef, intervalThrottleRef, loopIterator])

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [intervalRef])

  const eventHandler = useCallback(
    async (evt: EventQueueEvent) => {
      switch (evt.action) {
        case EventQueueEventAction.EncryptionInitialized:
          loopEnabledRef.current = true
      }
    },
    [loopEnabledRef]
  )

  const subscribe = useCallback(
    (
      subscription: (data: DataResult) => void,
      cacheKey: Guid,
      cacheKeyDebug: string,
      loadFunc: () => Promise<void>,
      disableInitialLoad = false,
      noCache = false
    ) => {
      const asyncFn = async () => {
        _subscriptions.push({
          cacheKey: cacheKey,
          cacheKeyDebug: cacheKeyDebug,
          disableInitialLoad: disableInitialLoad,
          subscription: subscription,
          loadFunc: loadFunc,
          loadFuncExecuted: false,
          noCache: noCache,
        })

        const cachedValue = _cachedData[cacheKey]

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!noCache && cachedValue && cachedValue.data) {
          const utcNow = new Date().getUTCDate()
          if (cachedValue.expiryTimestamp && cachedValue.expiryTimestamp < utcNow) {
            delete _cachedData[cacheKey]
          }
          subscription({
            hashedCacheKey: cachedValue.cacheKey,
            data: cachedValue.data,
            createDate: cachedValue.createTimestamp ? new Date(cachedValue.createTimestamp) : undefined,
            expireDate: cachedValue.expiryTimestamp ? new Date(cachedValue.expiryTimestamp) : undefined,
            pendingStartDate: cachedValue.pendingRefresh?.createTimestamp
              ? new Date(cachedValue.pendingRefresh.createTimestamp)
              : undefined,
            error: cachedValue.error,
            errorMessage: cachedValue.errorMessage,
          })
        }
        doLoop()
      }
      void asyncFn()
    },
    [doLoop]
  )

  const unsubscribe = useCallback((subscription: (data: DataResult) => void) => {
    const newSubscriptions: EventListItem[] = []
    for (const subscriptionItem of _subscriptions) {
      if (subscriptionItem.subscription !== subscription) {
        newSubscriptions.push(subscriptionItem)
      }
    }
    if (newSubscriptions.length !== _subscriptions.length) {
      _subscriptions = newSubscriptions
    }
  }, [])

  const reload = useCallback(
    (subscription: (data: DataResult) => void) => {
      const mSubscription = _subscriptions.find((x) => x.subscription === subscription)
      if (!mSubscription) {
        return
      }
      const cachedValue = _cachedData[mSubscription.cacheKey]
      if (!cachedValue) {
        return
      }
      const timestampNow = new Date().getTime()
      mSubscription.subscription({
        hashedCacheKey: cachedValue.cacheKey,
        data: cachedValue.data,
        createDate: cachedValue.createTimestamp ? new Date(cachedValue.createTimestamp) : undefined,
        expireDate: cachedValue.expiryTimestamp ? new Date(cachedValue.expiryTimestamp) : undefined,
        pendingStartDate: new Date(timestampNow),
        error: cachedValue.error,
        errorMessage: cachedValue.errorMessage,
      })
      mSubscription.loadFunc().then((result) => {
        cachedValue.reloaded = true
        doLoop()
      })
    },
    [doLoop]
  )

  return (
    <EventQueueContext.Provider
      value={{
        subscribe: subscribe,
        unsubscribe: unsubscribe,
        reloadData: reload,
        eventHandler: eventHandler,
      }}
    >
      {props.children}
    </EventQueueContext.Provider>
  )
}
