import { createContext, useContext } from "react"

export interface ConfirmationDialogOptions {
  title: string
  text: string
  agreeText?: string
  discardText?: string
  onConfirm: () => void
  onCancel?: () => void
}

export interface ConfirmationDialogContextValue {
  showOkDialog: (options: ConfirmationDialogOptions) => void
  showYesNoDialog: (options: ConfirmationDialogOptions) => void
}

const defaultValue: ConfirmationDialogContextValue = {
  showOkDialog: () => {},
  showYesNoDialog: () => {},
}

export const ConfirmationDialogContext = createContext<ConfirmationDialogContextValue>(defaultValue)
export const useConfirmationDialogContext = (): ConfirmationDialogContextValue => useContext(ConfirmationDialogContext)
