import { cryptoLib } from "@frontend/shared/crypto-new/CryptoLib"

let _pkdbfKey5: CryptoKey

export const encryptAes = async (message: string): Promise<string> => {
  return await cryptoLib.encryptAes(_pkdbfKey5, message)
}
export const decryptAes = async (message: string): Promise<string> => {
  return await cryptoLib.decryptAes(_pkdbfKey5, message)
}

export const encryptModel = async <T>(model: T): Promise<string> => {
  return await cryptoLib.encryptAes(_pkdbfKey5, JSON.stringify(model))
}
export const decryptModel = async <T>(message: string): Promise<T> => {
  const jsonString = await cryptoLib.decryptAes(_pkdbfKey5, message)
  const model = JSON.parse(jsonString) as T
  return model
}
export const setCryptoKey = (pkdbfKey5: CryptoKey): void => {
  _pkdbfKey5 = pkdbfKey5
}
;(window as any).decrypt = decryptAes
