/*
https://react-dropzone.js.org/#section-previews
https://react-dropzone-uploader.js.org/docs/props
https://react-dropzone.js.org/#src
 */

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import Dropzone, { IFileWithMeta, Input, IUploadParams, StatusValue, SubmitButton } from "./dropzone/Dropzone"
import "./dropzone/styles.css"

import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import {
  AttachmentDeleteRequestSchema,
  AttachmentType,
  AttachmentUploadRequestDto,
  AttachmentUploadRequestSchema,
  AttachmentUploadResponseSchema,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { encryptAes } from "@frontend/shared/crypto-new/aesCrypto"
import { CorrespondenceAndContact } from "@frontend/extweb-lib/views/details/models"
import { ValidationSimpleResults } from "@frontend/shared/validation/base-form-validation/base-form-validation"
import { MyFile } from "@frontend/extweb-lib/components/file-upload/my-file"
import { useGeneralSync } from "@frontend/shared/hooks/useGeneralSync.hook"
import { newUuid } from "@frontend/shared/utils/new-uuid"
import { MyPreview } from "@frontend/extweb-lib/components/file-upload/my-preview"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"

interface MyFileUploaderProps {
  recordId: number
  form: CorrespondenceAndContact
  modifyForm: Dispatch<SetStateAction<CorrespondenceAndContact>>
  formErrors: ValidationSimpleResults<CorrespondenceAndContact>
  allowedAttachmentTypes: AttachmentType[] | undefined
  readOnly?: boolean
}

const maxSizeMB = 10
const maxSizeBytes = maxSizeMB * 1024 * 1024
const apiUrl = import.meta.env.VITE_REACT_APP_BASE_URL as string
const getUpdateParams = async (file: IFileWithMeta, recordId: number) => {
  file.meta.uuid = newUuid()
  const model: AttachmentUploadRequestSchema = {
    RecordId: recordId,
    FileName: file.file.name,
    AttachmentGuid: file.meta.uuid,
  }

  const cacheHashKey = hashedCacheKey(file.meta.uuid)

  file.meta.cacheKey = cacheHashKey

  const jsonDataStr = JSON.stringify(model)
  const encryptedData = await encryptAes(jsonDataStr)

  const uploadModel: AttachmentUploadRequestDto = {
    encryptedData: encryptedData,
    hashedCacheKey: cacheHashKey,
    fileKey: file.file.name,
  }

  const ret: IUploadParams = {
    url: apiUrl + "/api/v1/attachements/upload",
    fields: uploadModel as any,
  }

  return ret
}
export const MyFileUploader = (props: MyFileUploaderProps) => {
  const { t } = useTranslation()
  const myFiles = useRef<IFileWithMeta[]>([])
  const [accept, setAccept] = useState<string>("")
  const [refreshFiles, setRefreshFiles] = useState<IFileWithMeta[]>([])
  const [myStates, subscribeToCacheKey] = useGeneralSync(1)
  const api = useExtWebApi()

  useEffect(() => {
    let _accept = ""
    if (props.allowedAttachmentTypes) {
      for (const type of props.allowedAttachmentTypes) {
        if (_accept) {
          _accept += ", "
        }
        _accept += "." + type.FileExtension
      }
    }
    setAccept(_accept)
  }, [props.allowedAttachmentTypes])

  const modifyForm = props.modifyForm
  useEffect(() => {
    for (const file of myFiles.current) {
      const fState = myStates[file.meta.cacheKey]
      if (fState?.event.isLoaded && fState.data) {
        const data = fState.data as AttachmentUploadResponseSchema

        if (file.meta.refreshed) {
          continue
        }
        file.meta.refreshed = true

        setRefreshFiles((prev) => {
          const current = prev.find((x) => x.meta.uuid === file.meta.uuid)
          const newArray = [...prev]
          if (current) {
            const newItem = {
              ...current,
            }
            newItem.meta.errorMessage = data.ErrorMessage
            newItem.meta.name = data.FileName
            const index = prev.indexOf(current)
            newArray.splice(index, 1)
          } else {
            newArray.push(file)
          }

          if (data.ErrorMessage) {
            file.meta.uploadState = "error"
            file.meta.errorMessage = data.ErrorMessage
          } else if (data.AttachmentId) {
            file.meta.uploadState = "attached"
          }
          return newArray
        })

        if (data.AttachmentId && !data.ErrorMessage) {
          modifyForm((prev) => {
            const newAttachments = [...(prev.Attachments || [])]

            if (!newAttachments.find((x) => x.Id === data.AttachmentId)) {
              const fileExt = file.meta.name.split(".").pop()
              newAttachments.push({
                Name: file.meta.name,
                CanBeDeleted: true,
                Id: data.AttachmentId,
                Extension: fileExt || "",
                AttachmentGuid: file.meta.uuid,
              })
            }

            const dataAttachment = newAttachments.find((x) => x.Id === data.AttachmentId)
            if (dataAttachment) {
              dataAttachment.Name = data.FileName
            }

            const newForm: CorrespondenceAndContact = {
              ...prev,
              Attachments: newAttachments,
            }
            return newForm
          })

          file.remove()
        }
      }
    }
  }, [modifyForm, myStates, myFiles])

  const removeFile = async (attachmentId: number) => {
    const model: AttachmentDeleteRequestSchema = {
      AttachmentId: attachmentId,
    }

    const jsonDataStr = JSON.stringify(model)
    const cacheHashKey = hashedCacheKey(jsonDataStr)
    const encryptedData = await encryptAes(jsonDataStr)

    await api.attachmentApiService.deleteAttachment_DELETE({
      encryptedData: encryptedData,
      hashedCacheKey: cacheHashKey,
    })
    modifyForm((prev) => {
      const newAttachments = [...(prev.Attachments || [])]

      const attachm = newAttachments.find((x) => x.Id === attachmentId)
      if (attachm) {
        const idx = newAttachments.indexOf(attachm)
        newAttachments.splice(idx, 1)
      }

      const newForm: CorrespondenceAndContact = {
        ...prev,
        Attachments: newAttachments,
      }
      return newForm
    })
  }
  /*
  https://react-dropzone-uploader.js.org/docs/api
  https://github.com/fortana-co/react-dropzone-uploader/blob/master/src/Preview.tsx
   */
  if (!accept) {
    return <div></div>
  }
  return (
    <div className={"dzu-filearea"}>
      {(props.form.Attachments || []).map((x) => (
        <MyFile
          key={`${x.Id}-${x.AttachmentGuid}`}
          attachment={x}
          remove={() => {
            void removeFile(x.Id)
          }}
        />
      ))}
      <Dropzone
        disabled={props.readOnly}
        inputContent={t("details.selectFiles")}
        inputWithFilesContent={t("details.selectMoreFiles")}
        accept={accept}
        autoUpload={true}
        canRemove={false}
        maxSizeBytes={maxSizeBytes}
        getUploadParams={async (file) => {
          return await getUpdateParams(file, props.recordId)
        }}
        onChangeStatus={(
          file: IFileWithMeta,
          status: StatusValue,
          allFiles: IFileWithMeta[]
        ): { meta: { [name: string]: any } } | void => {
          if (status === "done") {
            if (!file.meta.uploadState) {
              myFiles.current.push(file)
              subscribeToCacheKey(undefined, file.meta.cacheKey)
            }
          }
        }}
        PreviewComponent={MyPreview}
        SubmitButtonComponent={SubmitButton}
        InputComponent={Input}
        onSubmit={(successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
          for (const successFile of successFiles) {
            successFile.restart()
          }
        }}
        fileWithMetaUpdate={refreshFiles}
      />
    </div>
  )
}
