import React, { useEffect, useState } from "react"
import { styles } from "./DialogStyles"
import { FormHelperText, Grid, Input, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CreateParticipantItem, InvitationState } from "@frontend/extweb-api/swagger/model/dataModel"
import { participationStatuses } from "@frontend/extweb-lib/views/details/dialog.data"
import { SelectableParticipant } from "./SelectableParticipant"
import { SelectedParticipant } from "./SelectedParticipant"

export interface SelectableListItem {
  ParticipantId: string
  Name: string
  InvitationState?: InvitationState
  Selected?: boolean
  PersonId: number
}

interface ContactParticipantSelectProps {
  hideParticipantSelection?: boolean
  selections: SelectableListItem[]
  participants: CreateParticipantItem[]
  setParticipants: (participants: CreateParticipantItem[]) => void
  changeParticipantStatus: boolean
  error: string | undefined
  readOnly?: boolean
}

export const ParticipantSelect = (props: ContactParticipantSelectProps) => {
  const { t } = useTranslation()

  const [filteredParticipants, setFilteredParticipants] = useState<SelectableListItem[]>([])
  const [search, setSearch] = useState<string>()

  useEffect(() => {
    const filtered = (props.selections || []).filter((participant) => {
      if (
        props.participants?.find(
          (x) => x.PersonId === participant.PersonId && x.ParticipantId === participant.ParticipantId
        )
      ) {
        return false
      }
      if (search) {
        return (participant.Name || "").toLowerCase().includes(search.toLowerCase())
      }
      return true
    })
    setFilteredParticipants(filtered)
  }, [search, props.selections, props.participants])

  return (
    <Stack sx={styles.secondStepContainer}>
      {!props.hideParticipantSelection && (
        <Stack sx={styles.secondStepLeftBox}>
          <Typography variant="subtitle1" fontSize="16px" borderBottom="none" margin={0}>
            {t("details.selectableParticipants")}
          </Typography>
          <Input onChange={(e) => setSearch(e.target.value)} />
          {filteredParticipants.map((selectableParticipant) => (
            <SelectableParticipant
              key={selectableParticipant.PersonId}
              selectableParticipant={selectableParticipant}
              name={selectableParticipant.Name}
              readOnly={props.readOnly}
              onAdd={(participant) => {
                const p = [...(props.participants || [])]
                p.push({
                  ParticipantId: participant.ParticipantId,
                  PersonId: participant.PersonId,
                  InvitationState: participant.InvitationState || InvitationState.None,
                })
                props.setParticipants(p)
              }}
            />
          ))}
        </Stack>
      )}
      <Stack sx={styles.secondStepRightBox}>
        <Grid container gap="16px" alignItems="center" height="40px">
          <Grid item xs={5}>
            <Typography variant="subtitle1" fontSize="16px" borderBottom="none" margin={0} width="50%">
              {t("details.addedParticipants") + " *"}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            {props.changeParticipantStatus && (
              <Typography variant="subtitle1" fontSize="16px" borderBottom="none" margin={0} width="50%">
                {t("details.participationStatus")}
              </Typography>
            )}
          </Grid>
        </Grid>
        {/*} {hasFieldError("participants") ? (
          <Typography fontSize="14px" color="red">
            {errors.participants && errors.participants[0]?.error}
          </Typography>
        ) : (
        */}
        {(props.participants ?? []).map((participant1) => {
          const participant = (props.selections || []).find(
            (x) => x.PersonId === participant1.PersonId && x.ParticipantId === participant1.ParticipantId
          )
          if (!participant) {
            return null
          }

          const participantStatus = (props.participants || []).find(
            (x) => x.PersonId === participant1.PersonId && x.ParticipantId === participant1.ParticipantId
          )
          if (!participantStatus) {
            return null
          }

          return (
            <SelectedParticipant
              key={participant.PersonId}
              name={participant.Name}
              readOnly={props.readOnly}
              participant={participant}
              participationStatus={participationStatuses}
              participantStatus={participantStatus.InvitationState}
              changeParticipantStatus={props.changeParticipantStatus}
              onChange={(changedParticipant, key, value) => {
                const p = [...(props.participants || [])]
                const changedItem = p.find(
                  (x) =>
                    x.PersonId === changedParticipant.PersonId && x.ParticipantId === changedParticipant.ParticipantId
                )
                if (changedItem) {
                  const index = p.indexOf(changedItem)
                  if (index > -1) {
                    p[index] = {
                      ...p[index],
                      InvitationState: value as InvitationState,
                    }
                  }
                }

                props.setParticipants(p)
              }}
              onRemove={(removedParticipant) => {
                const p = [...(props.participants || [])]
                const removed = p.find(
                  (x) =>
                    x.PersonId === removedParticipant.PersonId && x.ParticipantId === removedParticipant.ParticipantId
                )
                if (removed) {
                  const index = p.indexOf(removed)
                  if (index > -1) {
                    p.splice(index, 1)
                  }
                }

                props.setParticipants(p)
              }}
              noRemoval={props.hideParticipantSelection}
            />
          )
        })}
        {!!props.error && props.participants.length < 1 && <FormHelperText>{props.error}</FormHelperText>}
      </Stack>
    </Stack>
  )
}
