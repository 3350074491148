const fileOverview = {
  fileOverview: "Aktenübersicht",
  fileNumber: "Aktenzahl",
  clientName: "Klient:innenname",
  lastPersonalContact: "Letzter pers. Kontakt",
  lastReport: "Letzter Bericht",
  personalData: "Pers. Daten",
  processEmployee: "Proz. Mitarbeiter:in",
  involved: "Beteiligte",
  furnishings: "Einrichtung",
  department: "Abteilung",
  fewerOptions: "Weniger optionen",
  moreOptions: "Mehr optionen",
  reset: "Zurücksetzen",
  search: "Suchen",
  performanceType: "Leistungstyp",
  clientDateBirth: "Geburtsdatum des Klienten",
  clientPhoneNumber: "Telefonnummer des Klienten",
  clerk: "Sachbearbeiter:in",
  includeClosedFiles: "Abgeschlossene Akten einbeziehen",
  includeFilesCompletedBySocialWorkers: "Soz.arbeiterisch erledigte Akten einbeziehen",
  rowsPerPage: "Einträge pro Seite:",
  of: "von",
  noTableEntries: "Keine Einträge mit diesen Suchkriterien vorhanden.",
  myDocuments: "Meine Akten",
}

export default fileOverview
