import React, { memo } from "react"
import { IconButton, Tooltip } from "@mui/material"
import { RecordItem } from "@frontend/extweb-api/swagger/model/dataModel"
import ApprovalIcon from "@mui/icons-material/Approval"
import { LoadingMeta } from "@frontend/extweb-lib/components/LoadingMeta/LoadingMeta"
import { useTranslation } from "react-i18next"
import { useConfirmationDialogContext } from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"
import { requests } from "@frontend/shared/hooks/requests"

export interface RecordActionsProps {
  fileId: number
  recordItem: RecordItem
  reloadRecordList: () => void
}

export const RecordActions = memo((props: RecordActionsProps) => {
  const { showYesNoDialog } = useConfirmationDialogContext()
  const { t } = useTranslation()
  const reset = 1
  const approval = requests.useWebDavapproval(reset)

  const item = props.recordItem

  return (
    <>
      {item.CanRequestApproval && (
        <>
          {approval.event.isLoading && <LoadingMeta event={approval.event} asIcon={true} />}
          {!approval.event.isLoading && (
            <Tooltip title={t("details.submitForApproval")}>
              <IconButton
                sx={{ height: "42px", width: "42px" }}
                onClick={() => {
                  showYesNoDialog({
                    title: t("details.messageFromPage"),
                    text: t("details.submitCorrespondenceForApproval"),
                    onConfirm: () =>
                      approval
                        .requestAsync({
                          RecordId: item.RecordId,
                        })
                        .then((r) => {
                          props.reloadRecordList()
                        }),
                  })
                }}
              >
                <ApprovalIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  )
})
