import React, { useEffect, useState } from "react"
import { Alert, Stack } from "@mui/material"
import { AttachmentItem, AttachmentListItem, RecordItem } from "@frontend/extweb-api/swagger/model/dataModel"
import { FileDownload } from "@frontend/extweb-lib/components/file-download/file-download"
import { decryptAes } from "@frontend/shared/crypto-new/aesCrypto"
import { format } from "date-fns"

export interface RecordDownloadProps {
  fileId: number
  recordItem: RecordItem
  atachmentDetails: AttachmentItem[]
}

interface AttachmentRender {
  item: AttachmentListItem
  details: AttachmentItem | undefined
  errorMessage: string | undefined
  info: string | undefined
  openInWord: boolean
}

export const RecordDownload = (props: RecordDownloadProps) => {
  const [attachmentDetails, setAttachmentDetails] = useState<AttachmentRender[]>([])

  useEffect(() => {
    const t = async () => {
      const nAttachements: AttachmentRender[] = []
      const details = props.atachmentDetails || []
      for (const attachment of props.recordItem.Attachments || []) {
        const detail = details.find((x) => x.dokuAttachmentGuid === attachment.AttachmentGuid)
        let em: string | undefined = undefined
        let info: string = ""
        let openInWord = false
        if (detail) {
          if (detail.webDav?.encryptedData) {
            try {
              const data = await decryptAes(detail.webDav?.encryptedData)
              const dataObj = JSON.parse(data)
              if (dataObj?.ErrorMessage) {
                em = dataObj.ErrorMessage
              }
            } catch (e: any) {
              console.error(e)
            }
          }
          if (detail.webDav?.lastEditDateUtc) {
            info += format(new Date(`${detail.webDav?.lastEditDateUtc}`), "dd.MM HH:ii")
          }
          if (detail.webDav?.openInWord) {
            openInWord = true
          }
        }
        nAttachements.push({
          item: attachment,
          details: detail,
          errorMessage: em,
          info: info,
          openInWord: openInWord,
        })
      }
      setAttachmentDetails(nAttachements)
    }
    void t()
  }, [props.atachmentDetails, props.recordItem.Attachments])

  const item = props.recordItem
  return (
    <Stack sx={{ gap: "5px" }}>
      <div className={"Test"} dangerouslySetInnerHTML={{ __html: item.Title }} />
      {!!item.Attachments?.length && (
        <Stack flexDirection="row" gap="10px" sx={{ flexWrap: "wrap" }}>
          {attachmentDetails.map((x) => (
            <div key={x.item.Id}>
              {x.errorMessage ? <Alert severity="error">{x.errorMessage}</Alert> : null}
              {x.info ? <Alert severity="info">{x.info}</Alert> : null}
              {x.openInWord ? <Alert severity="info">Open In Word</Alert> : null}
              <FileDownload key={"a-" + x.item.Id} attachment={x.item} />
            </div>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
