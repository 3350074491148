import { FormControl, FormHelperText, TextField } from "@mui/material"
import React from "react"
import { styles } from "@frontend/extweb-lib/views/details/components/dialog/DialogStyles"

interface TextEditorProps {
  label: string
  value: string | undefined
  error: string | undefined
  onChange: (value: string) => void
  isFormChecked?: boolean
  readOnly?: boolean
  rows?: number
  required?: boolean
}

export const TextInput = ({
  label,
  value,
  isFormChecked,
  error,
  onChange,
  readOnly,
  rows,
  required,
}: TextEditorProps) => {
  return (
    <FormControl fullWidth variant="standard" sx={styles.inputFieldsWidth}>
      <TextField
        id="outlined-multiline-static"
        label={label}
        multiline={!!rows}
        error={error !== undefined}
        rows={rows}
        required={required}
        inputProps={{
          readOnly: !!readOnly,
          disabled: !!readOnly,
        }}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
      {isFormChecked && !!error && <FormHelperText sx={{ color: "red" }}>{error}</FormHelperText>}
    </FormControl>
  )
}
