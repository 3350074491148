/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { AcceptAppointmentRequestDto } from "../model/dataModel"
import { UpsertContactRequestDto } from "../model/dataModel"
import { ViewContactRequestDto } from "../model/dataModel"

export class ContactApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {AcceptAppointmentRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // ContactController.AcceptAppointment  (BgbwExtWeb.Web.Controllers)
  // apiV1ContactsAcceptAppointmentPost
  public acceptAppointment_POST(
    body?: AcceptAppointmentRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/contacts/accept-appointment`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"AcceptAppointmentRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Contact", "apiV1ContactsAcceptAppointmentPost", httpOptions)
  }

  /**
   *
   * @param {ViewContactRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // ContactController.GetViewContact  (BgbwExtWeb.Web.Controllers)
  // apiV1ContactsPost
  public getViewContact_POST(body?: ViewContactRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/contacts`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"ViewContactRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Contact", "apiV1ContactsPost", httpOptions)
  }

  /**
   *
   * @param {UpsertContactRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // ContactController.UpsertContact  (BgbwExtWeb.Web.Controllers)
  // apiV1ContactsUpsertPost
  public upsertContact_POST(body?: UpsertContactRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/contacts/upsert`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"UpsertContactRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Contact", "apiV1ContactsUpsertPost", httpOptions)
  }
}
