import { Stack, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import React from "react"
import { styles } from "./DialogStyles"
import { useTranslation } from "react-i18next"

interface SelectableParticipantProps<T> {
  name: string
  selectableParticipant: T
  onAdd: (participant: T) => void
  readOnly?: boolean
}

export const SelectableParticipant = <T,>(props: SelectableParticipantProps<T>) => {
  const { t } = useTranslation()

  return (
    <Stack key={props.name} sx={styles.selectableParticipants}>
      <Typography variant="body1" fontSize="16px">
        {props.name}
      </Typography>
      {!props.readOnly && (
        <Stack
          sx={styles.selectableParticipant}
          onClick={props.readOnly ? undefined : () => props.onAdd(props.selectableParticipant)}
        >
          <Typography color="#0048B5" fontSize="13px" fontWeight="700">
            {t("details.add")}
          </Typography>
          <AddCircleOutlineIcon fontSize="small" style={{ color: "#0048B5" }} />
        </Stack>
      )}
    </Stack>
  )
}
