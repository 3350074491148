import { format, parse } from "date-fns"
import { DateState } from "@frontend/extweb-lib/views/details/models"
import { TFunction } from "react-i18next"

export interface FromToResult {
  fromUtc: string
  toUtc: string
}

export interface FromToResult1 {
  fromUtc: Date | undefined
  toUtc: Date | undefined
}

export interface FromTo {
  result: FromToResult | undefined
  errors: { [key: string]: string } | undefined
}

export const getFromTo = (
  t: TFunction<"translation", undefined>,
  datePicker: DateState,
  fromToRequired: boolean
): FromTo => {
  const errors: { [key: string]: string } = {}
  const result: FromToResult1 = {
    fromUtc: undefined,
    toUtc: undefined,
  }
  let fromDate: Date | undefined

  try {
    if (!datePicker.fromDate) {
      errors["FromDateUtc"] = t("details.pleaseChooseFromDate")
    } else {
      fromDate = datePicker.fromDate
      if (!isValidDate(fromDate)) {
        errors["FromDateUtc"] = t("details.pleaseChooseFromDate")
      }
    }
  } catch (e: any) {
    errors["FromDateUtc"] = t("details.pleaseChooseFromDate")
  }

  if (fromDate) {
    try {
      if (!datePicker.fromTime) {
        if (fromToRequired) {
          errors["FromTimeUtc"] = t("details.pleaseChooseFromTime")
        } else {
          result.fromUtc = addTimeToDate("00:00", fromDate)
        }
      } else {
        result.fromUtc = addTimeToDate(datePicker.fromTime, fromDate)
      }
    } catch (e: any) {
      errors["FromTimeUtc"] = t("details.pleaseChooseFromTime")
    }
    try {
      if (!datePicker.toTime) {
        if (fromToRequired) {
          errors["ToTimeUtc"] = t("details.pleaseChooseToTime")
        } else {
          result.toUtc = addTimeToDate("00:00", fromDate)
        }
      } else {
        result.toUtc = addTimeToDate(datePicker.toTime, fromDate)
      }
    } catch (e: any) {
      errors["ToTimeUtc"] = t("details.pleaseChooseToTime")
    }
  }

  if (result.fromUtc === undefined || result.toUtc === undefined) {
    if (!datePicker.fromTime) {
      errors["FromTimeUtc"] = t("details.pleaseChooseFromTime")
    }
    if (!datePicker.toTime) {
      errors["ToTimeUtc"] = t("details.pleaseChooseFromTime")
    }
  }

  if (result.fromUtc && result.toUtc) {
    if (isEndTimeBeforeStartTime(result.fromUtc, result.toUtc)) {
      errors["ToTimeUtc"] = t("details.pleaseChooseBiggerEndTime")
    }
  }

  if (Object.keys(errors).length > 0) {
    return {
      result: undefined,
      errors: errors,
    }
  }
  return {
    result: {
      toUtc: result.toUtc!.toISOString(),
      fromUtc: result.fromUtc!.toISOString(),
    },
    errors: undefined,
  }
}

const isValidDate = (date: Date) => {
  return !isNaN(date.getTime()) || date
}

const addTimeToDate = (time: string, date: Date) => {
  const parsedTime = parse(time, "HH:mm", new Date())

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    parseInt(format(parsedTime, "HH")),
    parseInt(format(parsedTime, "mm"))
  )
}

const isEndTimeBeforeStartTime = (startTime: Date, endTime: Date) => {
  return startTime.getTime() > endTime.getTime()
}
