import { Autocomplete, FormControl, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { styles } from "@frontend/extweb-lib/views/details/components/dialog/DialogStyles"
import { SelectValue } from "@frontend/extweb-api/swagger/model/dataModel"

export interface SeperatedDropdownProps {
  label: string
  value: string
  id: number
  parentId: number
  groupItems: SelectValue[]
  onChange: (parentId: number, value: number) => void
  errors: { [key: string]: string }
  isFormChecked: boolean
  readOnly?: boolean
}

export const SeperatedDropdown = (props: SeperatedDropdownProps) => {
  const label = (props.label || "").split("\n")

  const parentItems = props.groupItems
  const [childItems, setChildItems] = useState<SelectValue[]>()
  const [currentParentVal, setCurrentParentVal] = useState<SelectValue | null>(null)
  const [currenChildVal, setCurrenChildVal] = useState<SelectValue | null>(null)

  const parentError = props.errors["p" + props.id]
  const error = props.errors[props.id]

  useEffect(() => {
    const _parentVal = parentItems?.find((x) => x.Id == props.parentId)
    let _childItem: SelectValue | undefined = undefined

    if (props.value) {
      _childItem = _parentVal?.Values?.find((x) => "" + x.Id == props.value)
    }
    if (!_childItem) {
      _childItem = _parentVal?.Values?.find((x) => "" + x.IsDefault)
    }
    setChildItems(_parentVal?.Values)
    setCurrentParentVal(_parentVal || null)

    setCurrenChildVal(_childItem || null)
  }, [parentItems, props.groupItems, props.parentId, props.value])

  return (
    <>
      <FormControl variant="standard" fullWidth sx={styles.inputFieldsWidth}>
        <Autocomplete
          value={currentParentVal}
          disabled={props.readOnly}
          onChange={(e, value) => {
            const _parentVal = parentItems?.find((x) => x.Id == value?.Id)
            let _childItem: SelectValue | undefined = undefined
            if (props.value) {
              _childItem = _parentVal?.Values?.find((x) => "" + x.Id == props.value)
            }
            if (!_childItem) {
              _childItem = _parentVal?.Values?.find((x) => "" + x.IsDefault)
            }
            if (!_childItem) {
              _childItem = _parentVal?.Values?.[0]
            }
            setCurrentParentVal(_parentVal || null)
            setChildItems(_parentVal?.Values)
            setCurrenChildVal(_childItem || null)
            props.onChange(_parentVal?.Id || 0, _childItem?.Id || 0)
          }}
          getOptionLabel={(option) => option.Value}
          isOptionEqualToValue={(a, b) => a.Id === b.Id}
          options={parentItems || []}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.Id + ""}>
                {option.Value}
              </li>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={true}
              variant="standard"
              label={label[0] || ""}
              placeholder={props.label}
              error={typeof parentError === "string"}
              helperText={!!parentError && parentError}
            />
          )}
          autoSelect={true}
          autoComplete={true}
          autoHighlight={true}
          openOnFocus={true}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth sx={styles.inputFieldsWidth}>
        <Autocomplete
          value={currenChildVal}
          disabled={props.readOnly}
          onChange={(e, value) => {
            setCurrenChildVal(value)
            props.onChange(currentParentVal?.Id || 0, value?.Id || 0)
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.Id + ""}>
                {option.Value}
              </li>
            )
          }}
          getOptionLabel={(option) => option.Value}
          isOptionEqualToValue={(a, b) => a.Id === b.Id}
          options={childItems || []}
          renderInput={(params) => (
            <TextField
              {...params}
              required={true}
              variant="standard"
              label={label[1] || label[0] || ""}
              placeholder={props.label}
              error={typeof error === "string"}
              helperText={!!error && error}
            />
          )}
          autoSelect={true}
          autoComplete={true}
          autoHighlight={true}
          openOnFocus={true}
        />
      </FormControl>
    </>
  )
}
