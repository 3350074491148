import { Box, Grid, Typography } from "@mui/material"
import Logo from "../../../assets/logo.png"
import { useTranslation } from "react-i18next"
import React from "react"
import LoginInputs from "@frontend/extweb-lib/views/login/components/login-inputs/LoginInputs"
import { makeStyles } from "tss-react/mui"

const PADDING = 32

const useStyles = makeStyles()((theme) => ({
  pageContainer: {
    height: "100vh",
    padding: `${PADDING}px`,
  },
  layout: {
    minHeight: `calc(100vh - ${PADDING * 2}px) `,
  },
  loginInputsContainer: {
    width: "425px",
  },

  blueContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "26px",
  },
}))

const Login: React.FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Box className={classes.pageContainer}>
      <Grid container direction={"row"}>
        <Grid
          item
          container
          direction={"row"}
          alignItems="center"
          justifyContent="center"
          xs={7}
          className={classes.layout}
        >
          <Grid item container direction={"column"} className={classes.loginInputsContainer}>
            <div>
              <img src={Logo} />
            </div>
            <div>
              <Typography variant={"h3"}>{t("login.login")}</Typography>
            </div>
            <div>
              <Typography>{t("login.welcomeText")}</Typography>
            </div>
            <LoginInputs />
          </Grid>
        </Grid>
        <Grid item xs={5} className={classes.blueContainer}></Grid>
      </Grid>
    </Box>
  )
}

export default Login
