import React from "react"
import { Box, TableCell } from "@mui/material"
import { MainTableHeadCellProps } from "./main-table-config"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { ETableSortType } from "@frontend/extweb-lib/enums/e-table-sort-type"

const styles = {
  container: { fontWeight: "700", fontSize: "14px", borderBottom: "1px solid #848484" },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}

const MainTableHeadCell = <T,>({ columnItem }: MainTableHeadCellProps<T>) => {
  const { label, withSort, columnForSort, handleSort } = columnItem

  return (
    <TableCell key={columnItem.key} sx={styles.container}>
      {withSort && handleSort ? (
        <Box sx={styles.boxContainer}>
          {label}
          {columnForSort === ETableSortType.Ascending ? (
            <ArrowDownwardIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={() => handleSort(columnItem.key as keyof T, ETableSortType.Descending)}
            />
          ) : (
            <ArrowUpwardIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={() => handleSort(columnItem.key as keyof T, ETableSortType.Ascending)}
            />
          )}
        </Box>
      ) : (
        label
      )}
    </TableCell>
  )
}

export default MainTableHeadCell
