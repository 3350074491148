import { SyncApiService } from "@frontend/extweb-api/swagger/api/sync.api.service"
import { AuthenticationApiService } from "@frontend/extweb-api/swagger/api/authentication.api.service"
import { ContactApiService } from "@frontend/extweb-api/swagger/api/contact.api.service"
import { CorrespondenceApiService } from "@frontend/extweb-api/swagger/api/correspondence.api.service"
import { FileListApiService } from "@frontend/extweb-api/swagger/api/fileList.api.service"
import { FileRecordListApiService } from "@frontend/extweb-api/swagger/api/fileRecordList.api.service"
import { HttpClient } from "@frontend/extweb-api/client/http-client"
import { AttachmentApiService } from "./swagger/api/attachment.api.service"
import { WebDavFileApiService } from "@frontend/extweb-api/swagger/api/webDavFile.api.service"

export default class ExtWebApi {
  public readonly syncService: SyncApiService
  public readonly authenticationApiService: AuthenticationApiService
  public readonly contactApiService: ContactApiService
  public readonly correspondenceApiService: CorrespondenceApiService
  public readonly fileListApiService: FileListApiService
  public readonly fileRecordListApiService: FileRecordListApiService
  public readonly attachmentApiService: AttachmentApiService
  public readonly webDavFileApiService: WebDavFileApiService

  constructor(httpClient: HttpClient) {
    this.syncService = new SyncApiService(httpClient)
    this.authenticationApiService = new AuthenticationApiService(httpClient)
    this.contactApiService = new ContactApiService(httpClient)
    this.correspondenceApiService = new CorrespondenceApiService(httpClient)
    this.attachmentApiService = new AttachmentApiService(httpClient)
    this.fileListApiService = new FileListApiService(httpClient)
    this.fileRecordListApiService = new FileRecordListApiService(httpClient)
    this.fileRecordListApiService = new FileRecordListApiService(httpClient)
    this.webDavFileApiService = new WebDavFileApiService(httpClient)
  }
}
