import { AuthenticationApiService } from "@frontend/extweb-api/swagger/api/authentication.api.service"
import { Keystore } from "@frontend/shared/crypto-new/Keystore"
import { cryptoLib } from "@frontend/shared/crypto-new/CryptoLib"
import { TFunction } from "react-i18next"
import {
  LoginCreateState,
  LoginResponseDto,
  LoginStateDto,
  MfaAuthState,
} from "@frontend/extweb-api/swagger/model/accessControl"
import {
  CurrentSession,
  InitEncryptionRequest,
  InitEncryptionResult,
  MfaServiceResult,
  StartPollResult,
} from "../models/auth.models"
import { setCryptoKey } from "@frontend/shared/crypto-new/aesCrypto"
import { Guid } from "@customTypes"
import { ResponseWrap } from "@dccs/http-client"

const currentInMemory: CurrentSession = {
  currentPrivateKey: undefined,
  currentPublicKey: undefined,
}

export class AuthService {
  private loginDetails: LoginStateDto | undefined

  public constructor(
    private readonly storeKey: string,
    private readonly authenticationApiService: AuthenticationApiService,
    private readonly t: TFunction
  ) {}

  public async getLoginDetails(): Promise<LoginStateDto | undefined> {
    if (this.loginDetails) {
      return this.loginDetails
    }
    const result = await this.authenticationApiService.getLoginDetails_POST()

    this.loginDetails = result.result
    return this.loginDetails
  }

  public async login(
    username: string | undefined,
    password: string | undefined,
    stayLogged: boolean,
    stayLoginUsername: string | undefined,
    stayLoginToken: string | undefined
  ): Promise<MfaServiceResult> {
    if (!stayLoginUsername || !stayLoginToken) {
      if (!username || !password) {
        return {
          mfaResult: {
            errorMessage: this.t("login.usernameOrPasswordInvalid"),
          },
        }
      }
    }

    const loginDetails = await this.getLoginDetails()
    if (!loginDetails) {
      return {
        mfaResult: {
          errorMessage: this.t("login.generalError"),
        },
      }
    }

    if (!currentInMemory.currentPrivateKey || !currentInMemory.currentPublicKey) {
      const newKeyPair = await cryptoLib.generateKeys()
      currentInMemory.currentPrivateKey = newKeyPair.privateKey
      currentInMemory.currentPublicKey = await cryptoLib.exportPublicKey(newKeyPair.publicKey)
    }
    let loginResult: ResponseWrap<LoginResponseDto> | undefined
    if (stayLoginUsername && stayLoginToken) {
      const usernameHash = cryptoLib.sha256(stayLoginUsername.toLowerCase().trim())
      loginResult = await this.authenticationApiService.authenticate_POST({
        publicKey: currentInMemory.currentPublicKey!,
        usernameHash: usernameHash,
        passwordHash: "",
        stayLoggedIn: stayLogged,
        stayLoggedInToken: stayLoginToken,
      })
    } else {
      if (!username || !password) {
        return {
          mfaResult: {
            errorMessage: this.t("login.usernameOrPasswordInvalid"),
          },
        }
      }
      const usernameHash = cryptoLib.sha256((username || "").toLowerCase().trim())
      const passwordHash = cryptoLib.sha256(password + loginDetails.publicClientSalt)
      loginResult = await this.authenticationApiService.authenticate_POST({
        publicKey: currentInMemory.currentPublicKey!,
        usernameHash: usernameHash,
        passwordHash: passwordHash,
        stayLoggedIn: stayLogged,
        stayLoggedInToken: undefined as unknown as string,
      })
    }

    if (!loginResult?.ok || !loginResult?.result) {
      return {
        mfaResult: {
          errorMessage: this.t("login.generalError"),
        },
      }
    }

    const result = loginResult.result

    if (result.state === LoginCreateState.UserNameOrPasswordInvalid) {
      return {
        mfaResult: {
          errorMessage: this.t("login.usernameOrPasswordInvalid"),
        },
      }
    }
    if (result.state === LoginCreateState.MfaCreated && result.sessionId) {
      const keystore = new Keystore(this.storeKey)
      const code = cryptoLib.showThumbNailFromPublicKey(result.sessionId, currentInMemory.currentPublicKey)
      await keystore.putKey("kpr-" + result.sessionId, currentInMemory.currentPrivateKey!)
      await keystore.putData("kpu-" + result.sessionId, currentInMemory.currentPublicKey)
      currentInMemory.currentPrivateKey = undefined
      currentInMemory.currentPublicKey = undefined

      return {
        mfaResult: {
          pending: true,
          sessionId: result.sessionId,
        },
        code: code,
      }
    }
    return {
      mfaResult: {
        errorMessage: this.t("login.generalError"),
      },
    }
  }

  public async logout(): Promise<MfaServiceResult> {
    await this.authenticationApiService.logout_GET()

    return {
      mfaResult: {
        pending: false,
      },
    }
  }

  public async loginPoll(sessionId: string): Promise<MfaServiceResult> {
    const result = await this.authenticationApiService.authenticatePoll_POST({
      sessionId: sessionId,
    })
    if (result.result) {
      const authResult = result.result
      switch (authResult.state) {
        case MfaAuthState.None:
        case MfaAuthState.NotAvailable:
          return {
            mfaResult: {
              errorMessage: this.t("login.expired"),
            },
          }
        case MfaAuthState.Pending:
          return {
            mfaResult: {
              pending: true,
            },
          }
        case MfaAuthState.Declined:
          return {
            mfaResult: {
              errorMessage: this.t("login.mfaDeclined"),
            },
          }
        case MfaAuthState.Approved:
          return {
            mfaResult: {
              approved: true,
              encryptedPayload: authResult.encryptedPayload,
              encryptedUserDetails: authResult.encryptedUserDetails,
              sessionId: sessionId,
              stayLoggedInToken: authResult.stayLoggedInToken,
              pending: false,
              errorMessage: undefined,
            },
          }
        case MfaAuthState.Ignored:
          return {
            mfaResult: {
              errorMessage: this.t("login.mfaExpired"),
            },
          }
        case MfaAuthState.Expired:
          return {
            mfaResult: {
              errorMessage: this.t("login.mfaExpired"),
            },
          }
        case MfaAuthState.Revoked:
          return {
            mfaResult: {
              errorMessage: this.t("login.generalError"),
            },
          }
      }
    }

    return {
      mfaResult: {
        errorMessage: this.t("login.generalError"),
      },
    }
  }

  public async startPoll(sessionId: Guid): Promise<StartPollResult> {
    const keystore = new Keystore(this.storeKey)

    const currentPublicKey = await keystore.loadData<string>("kpu-" + sessionId)
    if (currentPublicKey) {
      const code = cryptoLib.showThumbNailFromPublicKey(sessionId, currentPublicKey)

      return {
        code: code,
      }
    }
    return {
      errorMessage: this.t("login.generalError"),
    }
  }

  public async initEncryption(request: InitEncryptionRequest): Promise<InitEncryptionResult> {
    try {
      const f = await this._initEncryption(request)
      return f
    } catch (e: any) {
      console.log(e)
      return {
        success: false,
        errorMessage: e.message,
      }
    }
  }

  public async remove(sessionId: Guid | null): Promise<void> {
    const keystore = new Keystore(this.storeKey)
    if (sessionId) {
      await keystore.remove("kpr-" + sessionId)
      await keystore.remove("kpu-" + +sessionId)
    }
    await keystore.removeOldKeys()
  }

  public async _initEncryption(request: InitEncryptionRequest): Promise<InitEncryptionResult> {
    const keystore = new Keystore(this.storeKey)
    const privateKey = await keystore.loadKey("kpr-" + request.sessionId)

    if (!privateKey || !request.encryptedPayload) {
      return {
        success: false,
        errorMessage: this.t("login.generalError"),
      }
    }
    const base64encoded = await cryptoLib.decryptRSA(privateKey, request.encryptedPayload)
    if (!base64encoded) {
      return {
        success: false,
        errorMessage: this.t("login.generalError"),
      }
    }
    const aesCrypto = await cryptoLib.getAesCryptoKey(base64encoded)

    if (!aesCrypto) {
      return {
        success: false,
        errorMessage: this.t("login.generalError"),
      }
    }
    setCryptoKey(aesCrypto)
    return {
      success: true,
    }
  }
}
