import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import {
  AttachmentDownloadRequestSchema,
  AttachmentDownloadResponseDto,
  AttachmentListItem,
} from "@frontend/extweb-api/swagger/model/dataModel"
import React, { useCallback, useEffect } from "react"
import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import { encryptAes } from "@frontend/shared/crypto-new/aesCrypto"
import { ExtWebFileState } from "@frontend/extweb-api/swagger/model/extWebDocuments"
import { Badge, Chip, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import CircularProgress from "@mui/material/CircularProgress"
import { alertHint, apiUrl, classes } from "./file-base"

export interface FileDownloadWaitingProps {
  isWebDav: boolean
  icon: JSX.Element
  attachment: AttachmentListItem
}

export const FileDownloadWaiting = (props: FileDownloadWaitingProps) => {
  const api = useExtWebApi()
  const reset = 1
  const [attachmentResponse, doRequestAttachment] = useSync(
    reset,
    api.attachmentApiService,
    api.attachmentApiService.getAttachment_POST,
    null as unknown as AttachmentDownloadResponseDto
  )

  const checkFileExists = useCallback(
    async (cacheHashKey: string) => {
      const fileExists = await api.attachmentApiService.fileExists_POST(cacheHashKey)
      if (fileExists.result?.exists) {
        return true
      }
      return false
    },
    [api]
  )

  useEffect(() => {
    const model: AttachmentDownloadRequestSchema = {
      AttachmentId: props.attachment.Id,
    }
    doRequestAttachment(model)
  }, [doRequestAttachment, props.attachment.Id])

  const requestAttachment = async () => {
    const model: AttachmentDownloadRequestSchema = {
      AttachmentId: props.attachment.Id,
    }
    const cacheKeyFull = JSON.stringify(model)
    const cacheHashKey = hashedCacheKey(api.attachmentApiService.getAttachment_POST.name + "-" + cacheKeyFull)

    if (!props.isWebDav) {
      const opened = window.open(`${apiUrl}/attachements/${cacheHashKey}/${props.attachment.Name}`)
      if (!opened) {
        alertHint()
      }
      return
    } else {
      const jsonDataStr = JSON.stringify(model)
      const encryptedData = await encryptAes(jsonDataStr)

      const fileExists = await api.webDavFileApiService.requestFile_POST({
        encryptedData: encryptedData,
        hashedCacheKey: cacheHashKey,
        dokuAttachmentId: props.attachment.Id,
        dokuAttachmentGuid: props.attachment.AttachmentGuid,
      })
      const result = fileExists.result
      if (result && result.state === ExtWebFileState.Available) {
        const opened = window.open(result.url + "/" + props.attachment.Name)
        if (!opened) {
          alertHint()
        }
        return
      }
    }
  }

  useEffect(() => {
    const t = async () => {
      const d = attachmentResponse.data as any
      if (attachmentResponse.isLoaded && (d?.AttachmentId || d?.Success)) {
        if (!props.isWebDav) {
          const fileExists = await checkFileExists(attachmentResponse.hashedCacheKey)
          if (fileExists) {
            const opened = window.open(
              `${apiUrl}/attachements/${attachmentResponse.hashedCacheKey}/${props.attachment.Name}`
            )
            if (!opened) {
              alertHint()
            }
          }
        } else {
          const fileExists = await api.webDavFileApiService.requestFile_POST({
            encryptedData: null as unknown as string,
            hashedCacheKey: attachmentResponse.hashedCacheKey,
            dokuAttachmentId: props.attachment.Id,
            dokuAttachmentGuid: props.attachment.AttachmentGuid,
          })
          const result = fileExists.result
          if (!result || result.state === ExtWebFileState.Pending) {
            return
          }
          if (result.state === ExtWebFileState.Available) {
            const opened = window.open(result.url + "/" + props.attachment.Name)
            if (!opened) {
              alertHint()
            }
            return
          }

          console.error(result)
        }
      }
      if (!attachmentResponse.data) {
        return
      }
    }
    void t()
  }, [
    props.attachment.AttachmentGuid,
    props.attachment.Id,
    props.isWebDav,
    api.webDavFileApiService,
    checkFileExists,
    attachmentResponse.isLoaded,
    attachmentResponse.data,
    attachmentResponse.hashedCacheKey,
    props.attachment.Name,
  ])

  if (attachmentResponse.isLoading) {
    return (
      <Tooltip title={props.attachment.Name}>
        <Chip
          variant="outlined"
          sx={classes.chip}
          onClick={() => {
            void requestAttachment()
          }}
          label={
            <span style={classes.chipContainer}>
              <Badge sx={classes.badge}>{props.icon}</Badge>
              <span style={classes.title}>{props.attachment.Name}</span>
              {props.isWebDav && (
                <Badge sx={classes.badge}>
                  <EditIcon sx={classes.icon1} />
                </Badge>
              )}
              <CircularProgress size={12} sx={{ marginTop: "4px" }} />
            </span>
          }
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip title={props.attachment.Name}>
      <Chip
        variant="outlined"
        sx={classes.chip}
        onClick={() => {
          void requestAttachment()
        }}
        label={
          <span style={classes.chipContainer}>
            <Badge sx={classes.badge}>{props.icon}</Badge>
            <span style={classes.title}>{props.attachment.Name}</span>
            {props.isWebDav && (
              <Badge sx={classes.badge}>
                <EditIcon sx={classes.icon1} />
              </Badge>
            )}
          </span>
        }
      />
    </Tooltip>
  )
}
