/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate, ModelObject } from "@customTypes"

export type ApprovalResponse = 0 | 10 | 20 | 30

export const ApprovalResponse = {
  None: 0 as ApprovalResponse,
  Error: 10 as ApprovalResponse,
  Expired: 20 as ApprovalResponse,
  Accepted: 30 as ApprovalResponse,
}
export interface ApproveMfaResponse {
  resultState: ApprovalResponse
  errorMessage: string
}

export type AuthState = 0 | 10 | 20

export const AuthState = {
  None: 0 as AuthState,
  Public: 10 as AuthState,
  LoggedIn: 20 as AuthState,
}
export interface ExtWebLoginRequestDto {
  usernameHash: string
  passwordHash: string
  publicKey: string
  stayLoggedIn: boolean
  stayLoggedInToken: string
}

export interface ExtWebPollRequest {
  sessionId: Guid
}

export type LoginCreateState = 0 | 10 | 20 | 30

export const LoginCreateState = {
  None: 0 as LoginCreateState,
  UserNameOrPasswordInvalid: 10 as LoginCreateState,
  MfaCreated: 20 as LoginCreateState,
  StayLoggedInInvalid: 30 as LoginCreateState,
}
export interface LoginResponseDto {
  state: LoginCreateState
  sessionId?: Guid
}

export interface LoginStateDto {
  state: AuthState
  sessionId?: Guid
  publicClientSalt: string
  encryptedPayload: string
  encryptedUserDetails: string
}

export type MfaAuthState = 0 | 10 | 20 | 30 | 40 | 60 | 70 | 80 | 90

export const MfaAuthState = {
  None: 0 as MfaAuthState,
  NotAvailable: 10 as MfaAuthState,
  Pending: 20 as MfaAuthState,
  Declined: 30 as MfaAuthState,
  Approved: 40 as MfaAuthState,
  Ignored: 60 as MfaAuthState,
  Expired: 70 as MfaAuthState,
  Revoked: 80 as MfaAuthState,
  Error: 90 as MfaAuthState,
}
export interface MfaStateDto {
  state: MfaAuthState
  encryptedPayload: string
  encryptedUserDetails: string
  stayLoggedInToken: string
}
