import { Guid } from "@customTypes"
import CryptoES from "crypto-es"

const lookupHashKeys: { [key: string]: Guid } = {}

export const hashedCacheKey = (value: string): Guid => {
  const res = lookupHashKeys[value]
  if (res) {
    return res
  }
  const md5Hash = CryptoES.MD5(value).toString().toLowerCase()

  const uuidHash =
    md5Hash.substring(0, 8) +
    "-" +
    md5Hash.substring(8, 12) +
    "-" +
    md5Hash.substring(12, 16) +
    "-" +
    md5Hash.substring(16, 20) +
    "-" +
    md5Hash.substring(20)

  lookupHashKeys[value] = uuidHash
  return uuidHash
}
