const login = {
  login: "Log in",
  welcomeText: "Willkommen bei BGBW!",
  email: "Email",
  password: "Passwort",
  username: "Username",
  forgotPassword: "Passwort vergessen?",
  stayLoggedIn: "Eingeloggt bleiben",
  logInActionButton: "Einloggen",
  usernameOrPasswordInvalid: "E-Mail oder Passwort inkorrekt.",
  mfaError: "Fehler",
  pendingMessage: "Bitte bestätigen  Sie in der App mit dem Code",
  sessionExpired: "Sie waren 30 Minuten inaktiv - bitte melden Sie sich erneut an.",
  generalError: "Ein unerwarteter Fehler ist aufgetreten",
  expired: "Multi Faktor Authentififzierung abelaufen.",
  ignored: "Multi Faktor Authentififzierung ignoriert.",
  revoked: "Multi Faktor Authentififzierung gesperrt.",
  mfaDeclined: "Login wurde durch die Multi Faktor Authentififzierung abgelehnt.",
  mfaPending: "mfaPending",
  mfaApproved: "mfaApproved",
  mfaExpired: "mfaExpired",
  backToLogin: "Login",
  logout: "Log Out",
  logoutProgress: "Abmeldung wird durchgeführt.",
  logoutSuccessful: "Sie wurden erfolgreich abgemeldet.",
  logoutConfirmationTitle: "Abmeldung",
  logoutConfirmation: "Wollen Sie sich wirklich von ExtWeb abmelden?",
  logoutConfirmationOk: "Abmelden",
  logoutConfirmationCancel: "Abbrechen",
  switchUser: "Mit anderen user anmelden",
}

export default login
