import { Button, Grid, Stack, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import React from "react"
import { useTranslation } from "react-i18next"
import { FileSearchModel } from "@frontend/extweb-lib/views/file-overview/FileOverview"

export const styles = {
  calendar: {
    "& .MuiButtonBase-root": {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
  },
  buttonsContainer: { flexDirection: "row", gap: "16px", height: "31px" },
  moreOptionsButton: {
    flexDirection: "row",
    gap: "4px",
    color: "#0048B5",
    width: "max-content",
    textTransform: "none",
  },
}

interface SearchProps {
  searchData: FileSearchModel
  onResetSearchFields: () => void
  onChange: (name: string, value: string | number | boolean | null) => void
  onSearch: () => void
  // selectedTab: number
}

const Search = ({ searchData, onChange, onSearch, onResetSearchFields }: SearchProps) => {
  const { t } = useTranslation()
  // const [moreOptions, setMoreOptions] = useState(false)

  // const handleMoreOptions = () => {
  //   setMoreOptions((prev) => !prev)
  // }
  /*
  const handlePhoneNumberKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key
    if (key === "Backspace") return

    const isValidKey = /^[0-9]$/.test(key)
    if (!isValidKey) {
      e.preventDefault()
    }
  }*/

  const renderFirstRow = () => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <TextField
                fullWidth
                label={t("fileOverview.fileNumber")}
                variant="standard"
                value={searchData.fileName}
                onChange={(e) => onChange("fileName", e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t("fileOverview.clientName")}
                variant="standard"
                value={searchData.name}
                onChange={(e) => onChange("name", e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={2}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="furnishings-label">{t("fileOverview.furnishings")}</InputLabel>
                <Select
                  labelId="furnishings-label"
                  value={searchData.constitutionId === 0 ? "" : searchData.constitutionId}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e) => onChange("constitutionId", e.target.value)}
                >
                  {(testData.organisations || []).map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="department-label">{t("fileOverview.department")}</InputLabel>
                <Select
                  labelId="department-label"
                  value={searchData.departmentId === 0 ? "" : searchData.departmentId}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e) => onChange("departmentId", e.target.value)}
                >
                  {(testData.organisations?.[0]?.departmentsAndTeams || []).map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={2}>
              <Button variant="text" sx={styles.moreOptionsButton} onClick={() => handleMoreOptions()}>
                <Typography variant="subtitle2" fontWeight="700">
                  {moreOptions ? t("fileOverview.fewerOptions") : t("fileOverview.moreOptions")}
                </Typography>
                {moreOptions ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item>
          <Stack sx={styles.buttonsContainer}>
            <Button variant="outlined" sx={{ textTransform: "none" }} onClick={() => onResetSearchFields()}>
              {t("fileOverview.reset")}
            </Button>

            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: "none", background: "#0048B5" }}
              onClick={onSearch}
            >
              {t("fileOverview.search")}
              <SearchIcon sx={{ paddingLeft: "4px" }} />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  // const renderSecondRow = () => {
  //   return (
  //     <Grid container justifyContent="space-between" alignItems="center" gap="16px">
  //       <Grid item xs={9}>
  //         <Grid container spacing={2} alignItems="center">
  //           <Grid item xs={2}>
  //             <FormControl variant="standard" fullWidth>
  //               <InputLabel id="performancetype-label"> {t("fileOverview.performanceType")}</InputLabel>
  //               <Select
  //                 labelId="performancetype-label"
  //                 value={searchData.serviceTypeId === 0 ? "" : searchData.serviceTypeId}
  //                 IconComponent={ExpandMoreIcon}
  //                 onChange={(e) => onChange("serviceTypeId", e.target.value)}
  //               >
  //                 <MenuItem value={10}>Ten</MenuItem>
  //                 <MenuItem value={20}>Twenty</MenuItem>
  //                 <MenuItem value={30}>Thirty</MenuItem>
  //               </Select>
  //             </FormControl>
  //           </Grid>
  //           <Grid item xs={2.5}>
  //             <FormControl fullWidth variant="standard">
  //               <DatePicker
  //                 label={t("fileOverview.clientDateBirth")}
  //                 format="dd.MM.yyyy"
  //                 value={searchData.birthday ? parseISO(new Date(searchData.birthday).toISOString()) : null}
  //                 slotProps={{ textField: { variant: "standard", error: false } }}
  //                 onChange={(value) => value && onChange("birthday", value.toDateString())}
  //                 sx={styles.calendar}
  //               />
  //             </FormControl>
  //           </Grid>
  //           <Grid item xs={2.5}>
  //             <TextField
  //               fullWidth
  //               label={t("fileOverview.clientPhoneNumber")}
  //               variant="standard"
  //               onKeyDown={handlePhoneNumberKeyDown}
  //               value={searchData.phoneNumber === "" ? countryNumber : searchData.phoneNumber}
  //               inputProps={{
  //                 maxLength: 17,
  //               }}
  //               onChange={(e) => onChange("phoneNumber", e.target.value)}
  //             />
  //           </Grid>
  //           <Grid item xs={3}>
  //             <Autocomplete
  //               disablePortal
  //               // disabled={selectedTab === 0}
  //               options={dummyUsers.map((user) => `${user.lastName}, ${user.firstName}`)}
  //               value={searchData.processSupervisorName && withCommaNameFormat(searchData.processSupervisorName)}
  //               popupIcon={<ExpandMoreIcon />}
  //               onChange={(_, value) => onChange("processSupervisorName", value)}
  //               renderInput={(params) => <TextField {...params} variant="standard" label={t("fileOverview.clerk")} />}
  //             />
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   )
  // }

  // const renderThirdRow = () => {
  //   return (
  //     <Grid item xs={12}>
  //       <FormControlLabel
  //         control={<Checkbox />}
  //         label={t("fileOverview.includeClosedFiles")}
  //         checked={searchData.includeClosedFiles}
  //         onChange={(_, checked) => onChange("includeClosedFiles", checked)}
  //       />
  //       <FormControlLabel
  //         control={<Checkbox />}
  //         label={t("fileOverview.includeFilesCompletedBySocialWorkers")}
  //         checked={searchData.includeDoneFiles}
  //         onChange={(_, checked) => onChange("includeDoneFiles", checked)}
  //       />
  //     </Grid>
  //   )
  // }

  return (
    <Stack gap="16px">
      <form
        id="my-form-id"
        onSubmit={(e) => {
          e.preventDefault()
          onSearch()
        }}
      >
        {renderFirstRow()}
        {/* {moreOptions && renderSecondRow()}
      {moreOptions && renderThirdRow()} */}
      </form>
    </Stack>
  )
}

export default Search
