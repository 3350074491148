import React from "react"

import { formatBytes, formatDuration } from "./dropzone/utils"
import { IPreviewProps } from "./dropzone/Dropzone"
import cancelImg from "./dropzone/assets/cancel.svg"
import removeImg from "./dropzone/assets/remove.svg"
import restartImg from "./dropzone/assets/restart.svg"
import CircularProgress from "@mui/material/CircularProgress"

const iconByFn = {
  cancel: { backgroundImage: `url(${cancelImg})` },
  remove: { backgroundImage: `url(${removeImg})` },
  restart: { backgroundImage: `url(${restartImg})` },
}

export const MyPreview = (props: IPreviewProps) => {
  const {
    className,
    imageClassName,
    style,
    imageStyle,
    fileWithMeta: { cancel, remove, restart },
    meta: { name = "", percent = 0, size = 0, previewUrl, status, duration, validationError },
    isUpload,
    canCancel,
    canRemove,
    canRestart,
    extra: { minSizeBytes },
  } = props

  let title = `${name || "?"}, ${formatBytes(size)}`
  if (duration) {
    title = `${title}, ${formatDuration(duration)}`
  }

  if (status === "error_file_size" || status === "error_validation" || props.meta.errorMessage) {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {!!props.meta.errorMessage && <span>{props.meta.errorMessage}</span>}
        {status === "error_file_size" && <span>{size < minSizeBytes ? "File too small" : "File too big"}</span>}
        {status === "error_validation" && <span>{String(validationError)}</span>}
        {canRemove && <span className="dzu-previewButton" style={iconByFn.remove} onClick={remove} />}
      </div>
    )
  }

  if (status === "error_upload_params" || status === "exception_upload" || status === "error_upload") {
    title = `${title} (upload failed)`
  }
  if (status === "aborted") title = `${title} (cancelled)`

  return (
    <div className={className} style={style}>
      <span className="dzu-previewFileName">{title}</span>
      <div className="dzu-previewStatusContainer">
        {status === "done" && props.meta.uploadState !== "doku" && (
          <span style={{ marginRight: "10px" }}>Uploading to Doku</span>
        )}
        {isUpload && <progress max={100} value={status === "done" || status === "headers_received" ? 100 : percent} />}

        {!isUpload &&
          status !== "preparing" &&
          status !== "getting_upload_params" &&
          status !== "uploading" &&
          canRemove && (
            <span className="dzu-previewButton" onClick={remove}>
              Entfernen
            </span>
          )}
        {["error_upload_params", "exception_upload", "error_upload", "aborted", "ready"].includes(status) &&
          canRestart && (
            <span className="dzu-previewButton" onClick={restart}>
              Hochladen
            </span>
          )}
        {((status === "done" && props.meta.uploadState !== "doku") || status === "uploading") && (
          <CircularProgress size={12} sx={{ marginLeft: "10px" }} />
        )}
      </div>
    </div>
  )
}
