import {
  UpsertContactRecord,
  UpsertCorrespondenceRecord,
  ViewContactRecord,
  ViewCorrespondenceRecord,
} from "@frontend/extweb-api/swagger/model/dataModel"

export const getNewUpsertContactRecord = (fileId: number): UpsertContactRecord => {
  const upsertContactRecord: UpsertContactRecord = {
    FileId: fileId,
    RecordId: 0,
    Participants: [],
    SupervisorId: 0,
    LocationId: 0,
    ContactTypeId: 0,
    FromDateUtc: undefined as unknown as string,
    ToDateUtc: undefined as unknown as string,
    ContentBody: undefined as unknown as string,
    LastChangeDateUtc: undefined,
    SuppressSyncAppointmentWithApp: false,
    ShowSuppressSyncAppointmentWithApp: false,
    ShowAppointmentState: false,
    ShowAppointmentAccept: false,
    AppointmentState: undefined as unknown as string,
    AppointmentAlternativeTimeslot: undefined,
    AppointmentDeclinedReason: undefined as unknown as string,
    Attachments: [],
    SupervisorName: undefined as unknown as string,
    ClientHasToApproveAppointmentChecked: false,
    ClientHasToApproveAppointmentShow: false,
  }
  return upsertContactRecord
}

export const getUpsertContactRecordFromView = (contact: ViewContactRecord): UpsertContactRecord => {
  const upsertContactRecord: UpsertContactRecord = {
    FileId: contact.FileId,
    RecordId: contact.RecordId,
    Participants: contact.Participants,
    SupervisorId: contact.SupervisorId,
    LocationId: contact.LocationId,
    ContactTypeId: contact.ContactTypeId,
    FromDateUtc: contact.FromDateUtc,
    ToDateUtc: contact.ToDateUtc,
    ContentBody: contact.ContentBody,
    LastChangeDateUtc: contact.LastChangeDateUtc,
    SuppressSyncAppointmentWithApp: contact.SuppressSyncAppointmentWithApp,
    ShowSuppressSyncAppointmentWithApp: contact.ShowSuppressSyncAppointmentWithApp,
    ShowAppointmentState: contact.ShowAppointmentState,
    ShowAppointmentAccept: contact.ShowAppointmentAccept,
    AppointmentState: contact.AppointmentState,
    AppointmentAlternativeTimeslot: contact.AppointmentAlternativeTimeslot,
    AppointmentDeclinedReason: contact.AppointmentDeclinedReason,
    Attachments: contact.Attachments,
    ClientHasToApproveAppointmentShow: contact.ClientHasToApproveAppointmentShow,
    ClientHasToApproveAppointmentChecked: contact.ClientHasToApproveAppointmentChecked,
    SupervisorName: contact.SupervisorName,
  }
  return upsertContactRecord
}

export const getNewUpsertCorrespondenceRecord = (fileId: number): UpsertCorrespondenceRecord => {
  const upsertCorrespondenceRecord: UpsertCorrespondenceRecord = {
    FileId: fileId,
    RecordId: 0,
    Participants: [],
    DocumentTypeId: 0,
    TemplateId: 0,
    SupervisorId: 0,
    ContactTypeId: 0,
    LocationId: 0,
    FromDateUtc: undefined as unknown as string,
    ToDateUtc: undefined as unknown as string,
    Title: undefined as unknown as string,
    ContentBody: undefined as unknown as string,
    AdditonalParameters: [],
    LastChangeDateUtc: undefined,
    Attachments: [],
    ReadOnly: false,
    SupervisorName: undefined as unknown as string,
  }
  return upsertCorrespondenceRecord
}

export const getUpsertCorrespondenceRecordFromView = (
  correspondence: ViewCorrespondenceRecord
): UpsertCorrespondenceRecord => {
  const upsertCorrespondenceRecord: UpsertCorrespondenceRecord = {
    FileId: correspondence.FileId,
    RecordId: correspondence.RecordId,
    Participants: correspondence.Participants,
    DocumentTypeId: correspondence.DocumentTypeId,
    TemplateId: correspondence.TemplateId,
    SupervisorId: correspondence.SupervisorId,
    ContactTypeId: correspondence.ContactTypeId,
    LocationId: correspondence.LocationId,
    FromDateUtc: correspondence.FromDateUtc,
    ToDateUtc: correspondence.ToDateUtc,
    Title: correspondence.Title,
    ContentBody: correspondence.ContentBody,
    AdditonalParameters: correspondence.AdditonalParameters,
    LastChangeDateUtc: correspondence.LastChangeDateUtc,
    Attachments: correspondence.Attachments,
    ReadOnly: correspondence.ReadOnly,
    SupervisorName: correspondence.SupervisorName,
  }
  return upsertCorrespondenceRecord
}
