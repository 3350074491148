import Table from "@frontend/extweb-lib/components/main-table/MainTable"
import Search from "@frontend/extweb-lib/views/file-overview/components/search/Search"
import { Box, Stack } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { MainTableColumnsConfig } from "@frontend/extweb-lib/components/main-table/main-table-config"
import useTable from "@frontend/extweb-lib/hooks/useTable.hook"
import { useTranslation } from "react-i18next"
import { FileListItemSchema, FileListResponseSchema } from "@frontend/extweb-api/swagger/model/dataModel"
import { Title } from "@frontend/extweb-lib/components/title/Title"
import { useNavigate } from "react-router-dom"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import { LoadingMeta } from "@frontend/extweb-lib/components/LoadingMeta/LoadingMeta"

const styles = {
  container: { background: "#FFFFFF", padding: "32px", paddingBottom: 0 },
  tableContainer: { padding: "32px" },
  searchContainer: { padding: "16px 0" },
}

// const tabs = ["Meine Akten", "Alle Akten"]

export interface FileSearchModel {
  fileName: string
  name: string
}

const initialFileSearchQuery: FileSearchModel = {
  fileName: "",
  name: "",
}

const FileOverview = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchData, setSearchData] = useState(initialFileSearchQuery)
  const [appliedSearchData, setAppliedSearchData] = useState(initialFileSearchQuery)
  const api = useExtWebApi()
  const reset = 1
  const [fileList, loadFileList] = useSync(
    reset,
    api.fileListApiService,
    api.fileListApiService.getFileList_POST,
    null as unknown as FileListResponseSchema
  )

  const filterSearch = useCallback(
    (items: FileListItemSchema[]) => {
      const { fileName, name } = appliedSearchData
      const trimmedFileNumber = fileName.trim().toLowerCase()
      const trimmedClientInnerName = name.trim().toLowerCase()

      const filteredData = items.filter((item) => {
        if (trimmedFileNumber) {
          if (!item.FileNumber.toLowerCase().includes(trimmedFileNumber)) {
            return false
          }
        }
        if (trimmedClientInnerName) {
          let found = false
          if (item.ClientDetails?.LastName?.toLowerCase().includes(trimmedClientInnerName)) {
            found = true
          }
          if (!found && item.ClientDetails?.FirstName?.toLowerCase().includes(trimmedClientInnerName)) {
            found = true
          }
          if (!found) {
            return false
          }
        }
        return true
      })
      return filteredData
    },
    [appliedSearchData]
  )

  const { tableData, tableColumnsSort, handleTableSort, handleTableDataUpdate } = useTable(
    fileList?.data?.Files,
    filterSearch
  )

  useEffect(() => {
    loadFileList({
      Page: 1,
      PageSize: 1000,
      Query: {},
    })
  }, [loadFileList])

  const handleSearch = () => {
    setAppliedSearchData(searchData)
  }

  const handleChange = (name: string, value: string | number | boolean | null) => {
    if (typeof value === "number" || typeof value === "boolean") {
      setSearchData((previous) => ({ ...previous, [name]: value }))
      return
    }
    setSearchData((previous) => ({ ...previous, [name]: value }))
  }

  const handleResetSearchFields = () => {
    setSearchData(initialFileSearchQuery)
  }

  const handleRowClickNavigation = (item: FileListItemSchema) => {
    navigate(`/details/${item.FileId}`)
  }

  const dummyColumns: MainTableColumnsConfig<FileListItemSchema>[] = [
    {
      label: t("fileOverview.fileNumber"),
      withSort: true,
      columnForSort: tableColumnsSort.FileNumber,
      handleSort: handleTableSort,
      key: "fileNumber",
      value: (item) => item.FileNumber,
    },
    {
      label: t("fileOverview.clientName"),
      key: "clientName",
      value: (item) => `${item.ClientDetails?.LastName}, ${item.ClientDetails?.FirstName}`,
    },
  ]

  return (
    <Box>
      <Box sx={styles.container}>
        <Stack gap="12px">
          <Title text={t("fileOverview.myDocuments")} />
          {/* {renderTabs()} */}
        </Stack>
        <Box sx={styles.searchContainer}>
          <Search
            searchData={searchData}
            onChange={handleChange}
            onSearch={handleSearch}
            onResetSearchFields={handleResetSearchFields}
          />
        </Box>
      </Box>
      <Box sx={styles.tableContainer}>
        <LoadingMeta eventMeta={fileList} />

        {fileList.isLoaded && (
          <Table data={tableData} columns={dummyColumns} onRowClickNavigation={handleRowClickNavigation} />
        )}
      </Box>
    </Box>
  )
}

export default FileOverview
