import React, { PropsWithChildren, useCallback, useState } from "react"
import {
  ConfirmationDialogContext,
  ConfirmationDialogContextValue,
  ConfirmationDialogOptions,
} from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"
import ConfirmationDialog from "@frontend/shared/components/confirmation-dialog/ConfirmationDialog"
import { ConfirmationDialogProps } from "@frontend/shared/components/confirmation-dialog/ConfirmationDialog.types"
import { useTranslation } from "react-i18next"

const ConfirmationDialogContextProvider: React.FC<PropsWithChildren<{}>> = (props: PropsWithChildren<{}>) => {
  const { t } = useTranslation()

  const closeDialog = useCallback((): void => {
    setDialogProps((prevState) => ({
      ...prevState,
      open: false,
    }))
  }, [])

  const [dialogProps, setDialogProps] = useState<ConfirmationDialogProps>({
    open: false,
    dialogTitleText: "",
    dialogText: "",
    onCancel: closeDialog,
  })

  const showOkDialog = useCallback(
    (options: ConfirmationDialogOptions) => {
      setDialogProps((prevState) => ({
        ...prevState,
        open: true,
        dialogTitleText: options.title,
        dialogText: options.text,
        showDiscardButton: false,
        onConfirm: () => {
          options.onConfirm()
          closeDialog()
        },
      }))
    },
    [closeDialog]
  )

  const showYesNoDialog = useCallback(
    (options: ConfirmationDialogOptions) => {
      setDialogProps((prevState) => ({
        ...prevState,
        open: true,
        dialogTitleText: options.title,
        dialogText: options.text,
        agreeText: options.agreeText || t("details.yes"),
        discardText: options.discardText || t("details.no"),
        showDiscardButton: true,
        onConfirm: () => {
          options.onConfirm()
          closeDialog()
        },
        onCancel: () => {
          options.onCancel?.()
          closeDialog()
        },
      }))
    },
    [closeDialog, t]
  )

  const contextValue: ConfirmationDialogContextValue = {
    showOkDialog,
    showYesNoDialog,
  }

  return (
    <>
      <ConfirmationDialogContext.Provider value={contextValue}>{props.children}</ConfirmationDialogContext.Provider>
      <ConfirmationDialog data-testid={"confirmation-dialog-test-id"} {...dialogProps} />
    </>
  )
}

export default ConfirmationDialogContextProvider
