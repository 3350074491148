import { Autocomplete, FormControl, TextField } from "@mui/material"
import React from "react"
import { SelectValue } from "@frontend/extweb-api/swagger/model/dataModel"
import { styles } from "@frontend/extweb-lib/views/details/components/dialog/DialogStyles"

interface SelectProps {
  label: string
  value: string
  items: SelectValue[]
  error: string | undefined
  isFormChecked: boolean
  onChange: (value: string) => void
  readOnly?: boolean
}

export const Select = ({ items, error, isFormChecked, label, value, onChange, readOnly }: SelectProps) => {
  return (
    <FormControl variant="standard" fullWidth sx={styles.inputFieldsWidth}>
      <Autocomplete
        value={value ? items.find((x) => "" + x.Id == value) : null}
        disabled={readOnly}
        onChange={(e, value) => onChange(value ? "" + value.Id : "")}
        getOptionLabel={(option) => option.Value}
        isOptionEqualToValue={(a, b) => a.Id === b.Id}
        options={items || []}
        renderInput={(params) => (
          <TextField
            {...params}
            required={true}
            variant="standard"
            label={label}
            placeholder={label}
            error={typeof error === "string"}
            helperText={!!error && error}
          />
        )}
        autoSelect={true}
        autoComplete={true}
        autoHighlight={true}
        openOnFocus={true}
      />
    </FormControl>
  )
}
