import { ErrorMessage } from "@frontend/extweb-api/swagger/model/dataModel"

export const hasAnyError = (
  error: string | ErrorMessage[] | undefined,
  error2: string | ErrorMessage[] | undefined
) => {
  return hasError(error) || hasError(error2)
}
export const hasError = (error: string | ErrorMessage[] | undefined) => {
  return error || error?.length === 0
}

export const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0
}

export const parseGenericError = (error: string): string => {
  if (error?.includes("msg text:")) {
    const extractedText = error.match(/msg text:(.*): Msg parameters/)
    if (extractedText && extractedText.length > 1) {
      return extractedText[1].trim()
    }
  }

  return error
}
