import React from "react"
import { EventMeta } from "@frontend/shared/models/EventResponseDto"
import CircularProgress from "@mui/material/CircularProgress"
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import { format } from "date-fns"

export interface LoadingInfoProps {
  hideIcon?: boolean
  noDetailsBox?: boolean
}

export interface LoadingMetaProps extends LoadingInfoProps {
  event: EventMeta
  noDetails?: boolean
  noDetailsBox?: boolean
  asIcon?: boolean
}

export const timestampReadFormat = "dd.MM.yy HH:mm:ss"
export const timestampDateReadFormat = "HH:mm:ss"

export const LoadingInfo = (props: LoadingInfoProps) => {
  return (
    <Box>
      <Stack direction="row">
        <div>
          <Tooltip title={"Aktualisierung läuft"}>
            <IconButton size={"small"} sx={{ height: 50, width: 50 }}>
              <CircularProgress size={30} />
            </IconButton>
          </Tooltip>
        </div>
      </Stack>
    </Box>
  )
}

export const LoadingMeta = (props: LoadingMetaProps) => {
  const meta = props.event

  if (props.noDetailsBox) {
    if (meta.isLoading) {
      return (
        <Box
          sx={{
            alignSelf: "center",
            width: "50px",
            marginTop: "60px",
          }}
        >
          <LoadingInfo {...props} />
        </Box>
      )
    }
    return null
  }

  if (props.asIcon) {
    if (meta.isLoading) {
      return (
        <IconButton sx={{ height: "42px", width: "42px" }}>
          <CircularProgress size={12} />
        </IconButton>
      )
    }
    return null
  }

  if (props.noDetails) {
    if (meta.isLoading) {
      return <LoadingInfo {...props} />
    }
    return null
  }

  return (
    <Box>
      <Stack direction="row">
        {!meta.isLoaded && meta.isLoading && <LoadingInfo {...props} />}
        {meta.isLoaded && (
          <>
            {meta.createDate && (
              <Typography variant="body2" sx={{ alignSelf: "center" }}>
                Datenstand vom {format(meta.createDate, "dd.MM.yy")}, {format(meta.createDate, "HH:mm:ss")}
              </Typography>
            )}
            {meta.refreshDate && (
              <div>
                <Tooltip title={"Aktualisierung läuft seit: " + format(meta.refreshDate, timestampReadFormat)}>
                  <IconButton size={"small"} sx={{ height: 30, width: 30 }}>
                    <CircularProgress size={12} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {!meta.refreshDate && (
              <div>
                <Tooltip title={"Aktualisieren"}>
                  <IconButton
                    size={"small"}
                    sx={{ height: 30, width: 30 }}
                    onClick={() => {
                      meta.reload()
                    }}
                  >
                    <RefreshIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </Stack>
    </Box>
  )
}
