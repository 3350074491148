import React from "react"
import removeImg from "@frontend/extweb-lib/components/file-upload/dropzone/assets/remove.svg"
import { FileDownload } from "@frontend/extweb-lib/components/file-download/file-download"
import { AttachmentListItem } from "@frontend/extweb-api/swagger/model/dataModel"

export interface MyFileProps {
  attachment: AttachmentListItem
  remove: () => void
}

const iconByFn = {
  remove: { backgroundImage: `url(${removeImg})` },
}
export const MyFile = (props: MyFileProps) => {
  return (
    <div className={"dzu-previewContainer"} style={{}}>
      <span className="dzu-previewFileName">{props.attachment.Name}</span>
      <div className="dzu-previewStatusContainer">
        <FileDownload key={"a-" + props.attachment.Id} attachment={props.attachment} />
        {props.attachment.CanBeDeleted && (
          <span className="dzu-previewButton" style={iconByFn.remove} onClick={props.remove} />
        )}
      </div>
    </div>
  )
}
