import { FormControl, Grid, MenuItem, Select, Stack, Typography } from "@mui/material"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import { styles } from "./DialogStyles"
import React from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { InvitationState } from "@frontend/extweb-api/swagger/model/dataModel"
import { ParticipationStatus, participationStatuses } from "../../dialog.data"

interface SelectedParticipantProps<T> {
  participationStatus: ParticipationStatus[] | undefined
  name: string
  participant: T
  participantStatus?: InvitationState | undefined
  changeParticipantStatus: boolean
  onChange: (participant: T, key: string, value: string | number) => void
  onRemove: (participant: T) => void
  readOnly?: boolean
  noRemoval?: boolean
}

export const SelectedParticipant = <T,>(props: SelectedParticipantProps<T>) => {
  const { t } = useTranslation()

  const renderParticipationStatusMenuItem = (
    status: { label: string; value: InvitationState; icon: JSX.Element },
    index: number
  ) => {
    return (
      <MenuItem key={index} value={status.value}>
        <Stack sx={styles.participantStatus}>
          {status.icon}
          <Typography overflow="hidden" fontSize="14px">
            {t(status.label as any)}
          </Typography>
        </Stack>
      </MenuItem>
    )
  }

  if (!props.participant) {
    return null
  }

  return (
    <Grid key={props?.name} container gap="16px" alignItems="center" height="40px">
      <Grid item xs={5}>
        <Typography variant="body1" fontSize="16px">
          {props?.name}
        </Typography>
      </Grid>
      {props.changeParticipantStatus && (
        <Grid item xs={4}>
          {props.participant && (
            <FormControl variant="standard" fullWidth>
              <Select
                disabled={props.readOnly}
                value={props.participantStatus || ""}
                IconComponent={ExpandMoreIcon}
                sx={{ background: "#F8F8F8" }}
                onChange={(e) =>
                  props.participant && props.onChange(props.participant, "status", parseInt(`${e.target.value}`, 10))
                }
              >
                {participationStatuses.map((status, index) => renderParticipationStatusMenuItem(status, index))}
              </Select>
            </FormControl>
          )}
        </Grid>
      )}
      <Grid item xs={2}>
        {!props.readOnly && !props.noRemoval && (
          <Stack
            sx={styles.participantRemoveBox}
            onClick={props.readOnly ? undefined : () => props.participant && props.onRemove(props.participant)}
          >
            <Typography color="#0048B5" fontSize="13px" fontWeight="700">
              {t("details.remove")}
            </Typography>
            <RemoveCircleOutlineOutlinedIcon fontSize="small" style={{ color: "#0048B5" }} />
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
