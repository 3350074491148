import { Autocomplete, FormControl, Grid, Stack, TextField } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { styles } from "../DialogStyles"

import { CreateContactListItems, UpsertContactRecord } from "@frontend/extweb-api/swagger/model/dataModel"
import { TimePicker } from "@frontend/extweb-lib/components/time-picker/TimePicker"
import { DatePicker } from "@frontend/extweb-lib/components/date-picker/DatePicker"
import { TextEditor } from "@frontend/extweb-lib/components/text-editor/TextEditor"
import { ValidationSimpleResults } from "@frontend/shared/validation/base-form-validation/base-form-validation"
import { add, format, parse, parseISO } from "date-fns"
import { DateState } from "../../../models"
import { AdditionalErrors } from "../../../models"
import { useTranslation } from "react-i18next"

interface ContactFormDocumentProps {
  isLoading?: boolean
  readOnly?: boolean
  selections: CreateContactListItems

  contactForm: UpsertContactRecord
  setDateState: React.Dispatch<React.SetStateAction<DateState>>
  setContactForm: React.Dispatch<React.SetStateAction<UpsertContactRecord>>
  contactFormErrors: ValidationSimpleResults<UpsertContactRecord & AdditionalErrors>
}

export const ContactForm = (props: ContactFormDocumentProps) => {
  const [fromDate, setFromDate] = useState<Date>()
  const [fromTime, setFromTime] = useState<string>()
  const [toTime, setToTime] = useState<string>()
  const [toTimeChanged, setToTimeChanged] = useState(false)
  const { t } = useTranslation()
  const { readOnly } = props
  const timeInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    const from = parseISO(props.contactForm.FromDateUtc)
    if (!isNaN(from.getTime())) {
      setFromTime(format(from, "HH:mm"))
      setFromDate(from)
    }
    const to = parseISO(props.contactForm.ToDateUtc)
    if (!isNaN(to.getTime())) {
      setToTime(format(to, "HH:mm"))
    }
  }, [props.contactForm?.FromDateUtc, props.contactForm?.ToDateUtc])

  const modifyContactForm = (values: Partial<UpsertContactRecord>) => {
    props.setContactForm((prev) => ({
      ...prev,
      ...values,
    }))
  }

  return (
    <Stack sx={styles.thirdStepContainer}>
      <Grid container spacing={2}>
        {/*
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                required={true}
                variant="standard"
                disabled={readOnly}
                error={!!props.contactForm.Title}
                helperText={!!props.contactForm.Title && props.contactForm.Title}
                label={t("details.titel")}
                value={props.contactForm.Title||''}
                onChange={(e) => {
                  props.setContactForm((prev) => ({
                    ...prev,
                    Title: e.target.value
                  }))
                }}

              />
            </Grid>
          </Grid>
        </Grid>
        */}
        <Grid item xs={4}>
          <DatePicker
            tabIndex={20}
            initialOpen={!fromDate}
            autoFocus={true}
            error={props.contactFormErrors["FromDateUtc"]}
            label="Datum *"
            readOnly={readOnly}
            value={fromDate}
            onClose={() => {
              if (timeInputRef.current) {
                setTimeout(() => {
                  timeInputRef.current?.focus()
                }, 100)
              }
            }}
            onChange={(value) => {
              setFromDate(value)

              props.setDateState((prev) => ({
                ...prev,
                fromDate: value,
              }))
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            tabIndex={30}
            label={"Von" + " *"}
            inputRef={timeInputRef}
            readOnly={readOnly}
            error={props.contactFormErrors["FromTimeUtc"]}
            value={fromTime || "00:00"}
            onChange={(value) => {
              setFromTime(value)
              props.setDateState((prev) => ({
                ...prev,
                fromTime: value,
              }))
              let _toTime: string | undefined
              if (value) {
                if (!toTimeChanged) {
                  const ftime = parse(value, "HH:mm", new Date())
                  const toTime = add(ftime, {
                    hours: 1,
                  })
                  _toTime = format(toTime, "HH:mm")
                  setToTime(_toTime)
                  props.setDateState((prev) => ({
                    ...prev,
                    toTime: _toTime,
                  }))
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            tabIndex={40}
            readOnly={readOnly}
            error={props.contactFormErrors["ToTimeUtc"]}
            label={"Bis" + " *"}
            value={toTime || ""}
            onChange={(value) => {
              setToTime(value)
              props.setDateState((prev) => ({
                ...prev,
                toTime: value,
              }))
              setToTimeChanged(true)
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              tabIndex={50}
              disabled={readOnly}
              value={
                props.contactForm?.LocationId
                  ? {
                      Id: props.contactForm?.LocationId || 0,
                      Name: props.selections.Locations?.find((x) => x.Id == props.contactForm?.LocationId)?.Name || "",
                    }
                  : null
              }
              onChange={(e, value) => modifyContactForm({ LocationId: value ? value.Id : 0 })}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(a, b) => a.Id === b.Id}
              options={props.selections.Locations || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  variant="standard"
                  label={t("details.location")}
                  placeholder={t("details.location")}
                  error={!!props.contactFormErrors.LocationId}
                  helperText={!!props.contactFormErrors.LocationId && props.contactFormErrors.LocationId}
                />
              )}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              disabled={readOnly}
              value={
                props.contactForm?.ContactTypeId
                  ? {
                      Id: props.contactForm?.ContactTypeId || 0,
                      Name:
                        props.selections.ContactTypes?.find((x) => x.Id == props.contactForm?.ContactTypeId)?.Name ||
                        "",
                    }
                  : null
              }
              tabIndex={60}
              onChange={(e, value) => modifyContactForm({ ContactTypeId: value ? value.Id : 0 })}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(a, b) => a.Id === b.Id}
              options={props.selections.ContactTypes || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  variant="standard"
                  label={t("details.contactForm")}
                  placeholder={t("details.contactForm")}
                  error={!!props.contactFormErrors.ContactTypeId}
                  helperText={!!props.contactFormErrors.ContactTypeId && props.contactFormErrors.ContactTypeId}
                />
              )}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <FormControl variant="standard" sx={styles.textarea}>
        <TextEditor
          tabIndex={80}
          readOnly={readOnly}
          label={"Inhalt"}
          withToolbar={true}
          error={undefined}
          isFormChecked={false}
          value={props.contactForm?.ContentBody}
          onChange={(value) => modifyContactForm({ ContentBody: value })}
        />
      </FormControl>
    </Stack>
  )
}
