import { messagesEn } from "./en"
import { messagesDe } from "./de"

export const resources = {
  en: {
    translation: messagesEn,
  },
  de: {
    translation: messagesDe,
  },
}
