/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate, ModelObject } from "@customTypes"

export interface ExtWebFileResponseDto {
  state: ExtWebFileState
  fileName: string
  url: string
  errorMessage: string
}

export type ExtWebFileState = 0 | 10 | 20 | 30

export const ExtWebFileState = {
  None: 0 as ExtWebFileState,
  Error: 10 as ExtWebFileState,
  Pending: 20 as ExtWebFileState,
  Available: 30 as ExtWebFileState,
}
