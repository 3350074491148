/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { AttachmentDeleteRequestDto } from "../model/dataModel"
import { AttachmentDownloadRequestDto } from "../model/dataModel"
import { AttachmentUploadFileResponseDto } from "../model/dataModel"
import { FileExistsResponse } from "../model/dataModel"
import { ModelEnum } from "../model/modelEnum"
import { StringStringValuesKeyValuePair } from "../model/stringStringValuesKeyValuePair"

export class AttachmentApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {AttachmentDeleteRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AttachmentController.DeleteAttachment  (BgbwExtWeb.Web.Controllers)
  // apiV1AttachementsDelete
  public deleteAttachment_DELETE(
    body?: AttachmentDeleteRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/attachements`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "DELETE" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"AttachmentDeleteRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Attachment", "apiV1AttachementsDelete", httpOptions)
  }

  /**
   *
   * @param {Guid} hashedCacheKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AttachmentController.FileExists  (BgbwExtWeb.Web.Controllers)
  // apiV1AttachementsHashedCacheKeyExistsPost
  public fileExists_POST(hashedCacheKey: Guid, options: any = {}): Promise<ResponseWrap<FileExistsResponse>> {
    // verify required parameter 'hashedCacheKey' is not null or undefined
    if (hashedCacheKey === null || hashedCacheKey === undefined) {
      throw new RequiredError(
        "hashedCacheKey",
        "Required parameter hashedCacheKey was null or undefined when calling apiV1AttachementsHashedCacheKeyExistsPost."
      )
    }
    const _path = `/api/v1/attachements/{hashedCacheKey}/exists`.replace(
      `{${"hashedCacheKey"}}`,
      encodeURIComponent(String(hashedCacheKey))
    )
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<FileExistsResponse>(
      "Attachment",
      "apiV1AttachementsHashedCacheKeyExistsPost",
      httpOptions
    )
  }

  /**
   *
   * @param {AttachmentDownloadRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AttachmentController.GetAttachment  (BgbwExtWeb.Web.Controllers)
  // apiV1AttachementsPost
  public getAttachment_POST(body?: AttachmentDownloadRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/attachements`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"AttachmentDownloadRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Attachment", "apiV1AttachementsPost", httpOptions)
  }

  /**
   *
   * @param {StringStringValuesKeyValuePair[]} [formCollection]
   * @param {string} [FileKey]
   * @param {string} [EncryptedData]
   * @param {number} [__Schema_RecordId]
   * @param {string} [__Schema_FileName]
   * @param {ModelEnum} [__Schema__Version]
   * @param {ModelEnum} [__Schema__SchemaType]
   * @param {number} [__Schema__ResponseType]
   * @param {Guid} [HashedCacheKey]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AttachmentController.UploadFile  (BgbwExtWeb.Web.Controllers)
  // apiV1AttachementsUploadPost
  public uploadFile_POST(
    formCollection?: StringStringValuesKeyValuePair[],
    FileKey?: string,
    EncryptedData?: string,
    __Schema_RecordId?: number,
    __Schema_FileName?: string,
    __Schema__Version?: ModelEnum,
    __Schema__SchemaType?: ModelEnum,
    __Schema__ResponseType?: number,
    HashedCacheKey?: Guid,
    options: any = {}
  ): Promise<ResponseWrap<AttachmentUploadFileResponseDto>> {
    const _path = `/api/v1/attachements/upload`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any
    const formParams = new url.URLSearchParams()

    if (formCollection) {
      formParams.set("formCollection", formCollection.join(COLLECTION_FORMATS[""]))
    }

    if (FileKey !== undefined) {
      formParams.set("FileKey", FileKey as any)
    }

    if (EncryptedData !== undefined) {
      formParams.set("EncryptedData", EncryptedData as any)
    }

    if (__Schema_RecordId !== undefined) {
      formParams.set("__Schema.RecordId", __Schema_RecordId as any)
    }

    if (__Schema_FileName !== undefined) {
      formParams.set("__Schema.FileName", __Schema_FileName as any)
    }

    if (__Schema__Version !== undefined) {
      formParams.set("__Schema._Version", __Schema__Version as any)
    }

    if (__Schema__SchemaType !== undefined) {
      formParams.set("__Schema._SchemaType", __Schema__SchemaType as any)
    }

    if (__Schema__ResponseType !== undefined) {
      formParams.set("__Schema._ResponseType", __Schema__ResponseType as any)
    }

    if (HashedCacheKey !== undefined) {
      formParams.set("HashedCacheKey", HashedCacheKey as any)
    }

    headerParameter["Content-Type"] = "application/x-www-form-urlencoded"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    requestOptions.body = formParams.toString()

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetch<AttachmentUploadFileResponseDto>(
      "Attachment",
      "apiV1AttachementsUploadPost",
      httpOptions
    )
  }

  /**
   *
   * @param {Guid} hashedCacheKey
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AttachmentController.DownloadDocumentFile  (BgbwExtWeb.Web.Controllers)
  // attachementsHashedCacheKeyDownloadFilenameGet
  public downloadDocumentFile_GET(
    hashedCacheKey: Guid,
    filename: string,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    // verify required parameter 'hashedCacheKey' is not null or undefined
    if (hashedCacheKey === null || hashedCacheKey === undefined) {
      throw new RequiredError(
        "hashedCacheKey",
        "Required parameter hashedCacheKey was null or undefined when calling attachementsHashedCacheKeyDownloadFilenameGet."
      )
    }
    // verify required parameter 'filename' is not null or undefined
    if (filename === null || filename === undefined) {
      throw new RequiredError(
        "filename",
        "Required parameter filename was null or undefined when calling attachementsHashedCacheKeyDownloadFilenameGet."
      )
    }
    const _path = `/attachements/{hashedCacheKey}/download/{filename}`
      .replace(`{${"hashedCacheKey"}}`, encodeURIComponent(String(hashedCacheKey)))
      .replace(`{${"filename"}}`, encodeURIComponent(String(filename)))
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "GET" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse(
      "Attachment",
      "attachementsHashedCacheKeyDownloadFilenameGet",
      httpOptions
    )
  }

  /**
   *
   * @param {Guid} hashedCacheKey
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // AttachmentController.OpenDocumentFile  (BgbwExtWeb.Web.Controllers)
  // attachementsHashedCacheKeyFilenameGet
  public openDocumentFile_GET(
    hashedCacheKey: Guid,
    filename: string,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    // verify required parameter 'hashedCacheKey' is not null or undefined
    if (hashedCacheKey === null || hashedCacheKey === undefined) {
      throw new RequiredError(
        "hashedCacheKey",
        "Required parameter hashedCacheKey was null or undefined when calling attachementsHashedCacheKeyFilenameGet."
      )
    }
    // verify required parameter 'filename' is not null or undefined
    if (filename === null || filename === undefined) {
      throw new RequiredError(
        "filename",
        "Required parameter filename was null or undefined when calling attachementsHashedCacheKeyFilenameGet."
      )
    }
    const _path = `/attachements/{hashedCacheKey}/{filename}`
      .replace(`{${"hashedCacheKey"}}`, encodeURIComponent(String(hashedCacheKey)))
      .replace(`{${"filename"}}`, encodeURIComponent(String(filename)))
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "GET" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Attachment", "attachementsHashedCacheKeyFilenameGet", httpOptions)
  }
}
