const details = {
  progress: "Verlauf",
  recordType: "Verlaufstyp",
  createNewHistoryEntry: "Neuen Verlaufseintrag erstellen",
  progressOf: "Verlauf von",
  progressUpTo: "Verlauf bis",
  protEmployee: "Prot. Mitarbeiter:in",
  content: "Inhalt",
  participants: "Beteiligte & Teilnahme",
  date: "Datum",
  location: "Ort",
  contactForm: "Kontaktform",
  supervisor: "Zuständige/r Mitarbeiter/in",
  titel: "Titel",
  chooseRecordType: "Verlaufstyp wählen",
  addStakeholders: "Beteiligte hinzufügen",
  documentContact: "Kontakt dokumentieren",
  pleaseChooseRecord: "Bitte wählen Sie welcher Verlaufstyp hinzugefügt werden soll.",
  pleaseChooseDocumentType: "Bitte wählen Sie welche Dokumenten Vorlage hinzugefügt werden soll.",
  pleaseChooseTemplate: "Bitte wählen Sie welche Vorlage hinzugefügt werden soll.",
  pleaseChooseParticipants: "Bitte wählen Sie Beteiligte aus.",
  pleaseChooseFromDate: "Bitte wählen Sie ein Datum.",
  pleaseChooseFromTime: "Bitte wählen Sie eine Start Uhrzeit.",
  pleaseChooseBiggerEndTime: "Bitte wählen Sie eine Ende Uhrzeit größer als die Start Uhrzeit.",
  pleaseChooseToTime: "Bitte wählen Sie eine Ende Uhrzeit.",
  pleaseChooseTitle: "Bitte wählen Sie einen Titel.",
  pleaseChooseLocation: "Bitte wählen Sie einen Ort.",
  pleaseChooseSupervisor: "Bitte wählen Sie eine/n zuständige/n Mitarbeiter/in.",
  closeWindow: "Fenster schließen",
  pleaseChooseContactType: "Bitte wählen Sie eine Form.",
  contact: "Kontakt",
  deadline: "Termin",
  correspodence: "Schriftverkehr",
  cancel: "Abbrechen",
  nextStep: "Nächster Schritt",
  previousStep: "Vorheriger Schritt",
  add: "Hinzufügen",
  remove: "Entfernen",
  from: "Von",
  to: "Bis",
  addedParticipants: "Hinzugefügte Beteiligte",
  participationStatus: "Teilnahmestatus",
  selectableParticipants: "Auswählbare Beteiligte",
  femaleEmployee: "Mitarbeiterin",
  formContact: "Kontaktform",
  atLeastOneParticipantMsg: "Es muss mindestens ein/e Beteiligte/r hinzugefügt werden.",
  participated: "Anwesend",
  wasExcused: "War entschuldigt",
  noExcused: "keine Entschuldigung",
  saveChanges: "Änderungen speichern",
  deleteHistoryEntry: "Verlaufseintrag Löschen",
  newHistory: "Neuer Verlauf",
  editContact: "Kontakt bearbeiten",
  editCorrespondence: "Schriftverkehr bearbeiten",
  type: "Typ",
  template: "Vorlage",
  additionalParameters: "Zusatzparameter",
  selectFiles: "Files auswählen",
  selectMoreFiles: "Files auswählen",
  formSaving: "Formular wird gespeichert, bitte warten...",
  successSave: "Erfolgreich gespeichert",
  unexpectedError: "Ein unbekannter Fehler ist aufgetreten: ",
  suppressSyncAppointmentWithApp: "Termin nicht mit Klient/in synchronisieren",
  clientHasToApproveAppointment: "Muss von Klient/in bestätigt werden",
  appointment: "Termin",
  stateAppointment: "Status Termin",
  stateAppointmentReason: "Begründung",
  appointmentAlternativeTimeslot: "Gegenvorschlag",
  acceptAppointmentAlternativeTimeslot: "Annehmen",
  submitForApproval: "Zur Genehmigung vorlegen",
  submitCorrespondenceForApproval: "Den Schriftverkehr zur Genehmigung vorlegen",
  deleteHistoryEntryConfirm: "Wollen Sie den Verlauf wirklich Löschen?",
  editHistoryEntry: "Verlaufseintrag bearbeiten",
  yes: "Ja",
  ok: "Ok",
  no: "Nein",
  messageFromPage: "Meldung von webseite",
  httpError: {
    title: "Lade Fehler",
    text: "Ein Lade Fehler ist aufgetreten. Ungespeicherte Daten können so verloren gehen.",
    agreeText: "Seite reloaden",
    discardText: "Abbrechen",
  },
  httpSessionError: {
    title: "Session abgelaufen",
    text: "Die Session ist abgelaufen. Sie müssen sich neu einloggen. Achtung: ungespeicherte Daten können so verloren gehen.",
    agreeText: "Neu einloggen",
    discardText: "Abbrechen",
  },
}

export default details
