export const styles = {
  inputFieldsWidth: { width: "100%" },
  closeIcon: {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "6px",
    background: "black",
    color: "white",
    cursor: "pointer",
  },
  dialogBox: { height: "85vh", overflowY: "auto", marginTop: "35px" },
  dialogContent: { height: "100%" },
  titleAndStepperBox: { gap: "16px", padding: "0px 32px 28px 32px", marginTop: "-7px" },
  stepperBox: { width: "100%" },
  fullWidthBorder: { height: "1px", background: "#DBDBDB", width: "100%" },
  stepContent: { flexGrow: 1, justifyContent: "space-between", padding: "16px 32px 28px 32px" },
  buttonsBox: { flexDirection: "row", justifyContent: "space-between" },
  leftButtonsBox: { flexDirection: "row", gap: "8px" },
  cancelButton: {
    border: "1px solid rgba(0, 0, 0, 0.50)",
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "2px",
  },
  nextButton: { padding: "6px 16px", textTransform: "none", color: "#FFF", borderRadius: "2px" },
  secondStepContainer: { flexDirection: "row", gap: "48px", paddingBottom: "16px" },
  secondStepLeftBox: { width: "50%", background: "#F8F8F8", padding: "16px", gap: "16px", height: "min-content" },
  selectableParticipants: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
  },
  selectableParticipant: { flexDirection: "row", alignItems: "center", gap: "4px", cursor: "pointer" },
  secondStepRightBox: { width: "50%", padding: "16px", gap: "16px" },
  title: { flexDirection: "row" },
  participantStatus: {
    paddingX: "4px",
    alignItems: "center",
    justifyContent: "left",
    flexDirection: "row",
    gap: "8px",
  },
  participantRemoveBox: { flexDirection: "row", alignItems: "center", gap: "4px", cursor: "pointer" },
  thirdStepContainer: {
    gap: "24px",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "32px",
  },
  textarea: {
    display: "flex",
    gap: "8px",
    flex: 1,
    "& .css-1oh4azu-MuiInputBase-root-MuiFilledInput-root:before": {
      background: "none",
    },

    "& .css-1oh4azu-MuiInputBase-root-MuiFilledInput-root:hover:before": {
      background: "none",
    },

    "& .css-1oh4azu-MuiInputBase-root-MuiFilledInput-root": {
      background: "none",
    },
  },
  textField: { background: "#F9F9F9", height: "100%" },

  muiProgress: {
    "& .MuiStepLabel-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
    },
    "& .MuiStep-root": {
      padding: 0,
    },
  },
  calendar: {},
  time: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
  },
  disabledBox: {
    // pointerEvents: "none"
  },
  appointmentBox: {
    marginTop: "33px",
  },
}
