import React, { useCallback, useEffect, useState } from "react"
import { Badge, Chip, Tooltip } from "@mui/material"
import { WordSvgIcon } from "@frontend/extweb-lib/icons/WordSvgIcon"
import { AttachmentDownloadRequestSchema, AttachmentListItem } from "@frontend/extweb-api/swagger/model/dataModel"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import { PdfSvgicon } from "@frontend/extweb-lib/icons/PdfSvgIcon"
import EditIcon from "@mui/icons-material/Edit"
import { encryptAes } from "@frontend/shared/crypto-new/aesCrypto"
import { ExtWebFileState } from "@frontend/extweb-api/swagger/model/extWebDocuments"
import { FileDownloadWaiting } from "@frontend/extweb-lib/components/file-download/file-download-waiting"
import { alertHint, apiUrl, classes } from "./file-base"

export interface FileDownloadProps {
  attachment: AttachmentListItem
}

export const FileDownload = (props: FileDownloadProps) => {
  const api = useExtWebApi()

  const [waitForAttachment, setWaitForAttachment] = useState(false)
  const [fileExists, setFileExists] = useState(false)
  const [isWebDav, setIsWebDav] = useState(false)
  const [icon, setIcon] = useState(PdfSvgicon)

  const checkFileExists = useCallback(
    async (cacheHashKey: string) => {
      const fileExists = await api.attachmentApiService.fileExists_POST(cacheHashKey)
      if (fileExists.result?.exists) {
        return true
      }
      return false
    },
    [api]
  )
  useEffect(() => {
    if (props.attachment.Extension === "docx" && !props.attachment.CanBeDeleted) {
      setIsWebDav(true)
    }
    if (props.attachment.Extension === "docx") {
      setIcon(WordSvgIcon)
    }
    if (props.attachment.Extension === "pdf") {
      setIcon(PdfSvgicon)
    }
  }, [props.attachment.Extension, props.attachment.CanBeDeleted])

  const requestAttachment = async () => {
    const model: AttachmentDownloadRequestSchema = {
      AttachmentId: props.attachment.Id,
    }
    const cacheKeyFull = JSON.stringify(model)
    const cacheHashKey = hashedCacheKey("useAttachment-" + cacheKeyFull)

    if (!isWebDav) {
      const fileExists = await checkFileExists(cacheHashKey)
      if (fileExists) {
        setFileExists(true)
        const opened = window.open(`${apiUrl}/attachements/${cacheHashKey}/${props.attachment.Name}`)
        if (!opened) {
          alertHint()
        }
        return
      }
      setWaitForAttachment(true)
    } else {
      const jsonDataStr = JSON.stringify(model)
      const encryptedData = await encryptAes(jsonDataStr)

      const fileExists = await api.webDavFileApiService.requestFile_POST({
        encryptedData: encryptedData,
        hashedCacheKey: cacheHashKey,
        dokuAttachmentId: props.attachment.Id,
        dokuAttachmentGuid: props.attachment.AttachmentGuid,
      })
      const result = fileExists.result
      if (!result || result.state === ExtWebFileState.Pending) {
        setWaitForAttachment(true)
        return
      }
      if (result.state === ExtWebFileState.Available) {
        setFileExists(true)
        const opened = window.open(result.url + "/" + props.attachment.Name)
        if (!opened) {
          alertHint()
        }
        return
      }

      console.error(result)
    }
  }

  if (waitForAttachment) {
    return <FileDownloadWaiting attachment={props.attachment} icon={icon} isWebDav={isWebDav} />
  }

  return (
    <Tooltip title={props.attachment.Name}>
      <Chip
        variant="outlined"
        sx={classes.chip}
        onClick={() => {
          void requestAttachment()
        }}
        label={
          <span style={classes.chipContainer}>
            <Badge sx={classes.badge}>{icon}</Badge>
            <span style={classes.title}>{props.attachment.Name}</span>
            {isWebDav && (
              <Badge sx={classes.badge}>
                <EditIcon sx={classes.icon1} />
              </Badge>
            )}
          </span>
        }
      />
    </Tooltip>
  )
}
