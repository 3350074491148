import {
  AttachmentDownloadRequestSchema,
  AttachmentDownloadResponseDto,
  CreateCorrespondenceMetaRequestSchema,
  CreateCorrespondenceMetaResponseSchema,
  DeleteRecordRequestSchema,
  DeleteRecordResponseSchema,
  FileListRequestSchema,
  FileListResponseSchema,
  FileRecordListRequestSchema,
  FileRecordListResponseSchema,
  SendToAppRequestSchema,
  SendToAppResponseSchema,
  UpsertContactRequestSchema,
  UpsertContactResponseSchema,
  UpsertCorrespondenceRequestSchema,
  UpsertCorrespondenceResponseSchema,
  ViewContactRequestSchema,
  ViewContactResponseSchema,
  ViewCorrespondenceRequestSchema,
  ViewCorrespondenceResponseSchema,
  WebDavApprovalRequestSchema,
  WebDavApprovalResponseSchema,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import ExtWebApi from "@frontend/extweb-api/ext-web-api"

const requestsX = {
  useCorrespondenceMeta: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.correspondenceApiService.createCorrespondenceMeta_POST(body, options),
  useViewContact: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.contactApiService.getViewContact_POST(body, options),
  useViewCorrespondence: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.correspondenceApiService.viewCorrespondence_POST(body, options),
  useUpsertContact: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.contactApiService.upsertContact_POST(body, options),
  useRecordList: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.fileRecordListApiService.getFileRecordList_POST(body, options),
  useUpsertCorrespondence: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.correspondenceApiService.upsertCorrespondence_POST(body, options),
  useAppointmentAccept: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.contactApiService.acceptAppointment_POST(body, options),
  useDeleteRecord: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.fileRecordListApiService.deleteRecord_POST(body, options),
  useSendToApp: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.fileRecordListApiService.sendToApp_POST(body, options),
  useWebDavapproval: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.webDavFileApiService.webDavApproval_POST(body, options),
  useFileList: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.fileListApiService.getFileList_POST(body, options),
  useAttachment: (api: ExtWebApi, body: any | undefined, options: any) =>
    api.attachmentApiService.getAttachment_POST(body, options),
}

export const requests = {
  useCorrespondenceMeta: (refreshIterator: number | string) => {
    return useSync<CreateCorrespondenceMetaRequestSchema, CreateCorrespondenceMetaResponseSchema>(
      refreshIterator,
      requestsX.useCorrespondenceMeta,
      0
    )
  },
  useViewContact: (refreshIterator: number | string) => {
    return useSync<ViewContactRequestSchema, ViewContactResponseSchema>(refreshIterator, requestsX.useViewContact, 1)
  },
  useViewCorrespondence: (refreshIterator: number | string) => {
    return useSync<ViewCorrespondenceRequestSchema, ViewCorrespondenceResponseSchema>(
      refreshIterator,
      requestsX.useViewCorrespondence,
      2
    )
  },
  useUpsertContact: (refreshIterator: number | string) => {
    return useSync<UpsertContactRequestSchema, UpsertContactResponseSchema>(
      refreshIterator,
      requestsX.useUpsertContact,
      3
    )
  },
  useRecordList: (refreshIterator: number | string) => {
    return useSync<FileRecordListRequestSchema, FileRecordListResponseSchema>(
      refreshIterator,
      requestsX.useRecordList,
      4
    )
  },
  useUpsertCorrespondence: (refreshIterator: number | string) => {
    return useSync<UpsertCorrespondenceRequestSchema, UpsertCorrespondenceResponseSchema>(
      refreshIterator,
      requestsX.useUpsertCorrespondence,
      5
    )
  },
  useAppointmentAccept: (refreshIterator: number | string) => {
    return useSync<SendToAppRequestSchema, SendToAppResponseSchema>(refreshIterator, requestsX.useAppointmentAccept, 6)
  },
  useDeleteRecord: (refreshIterator: number | string) => {
    return useSync<DeleteRecordRequestSchema, DeleteRecordResponseSchema>(refreshIterator, requestsX.useDeleteRecord, 7)
  },
  useSendToApp: (refreshIterator: number | string) => {
    return useSync<SendToAppRequestSchema, SendToAppResponseSchema>(refreshIterator, requestsX.useSendToApp, 8)
  },
  useWebDavapproval: (refreshIterator: number | string) => {
    return useSync<WebDavApprovalRequestSchema, WebDavApprovalResponseSchema>(
      refreshIterator,
      requestsX.useWebDavapproval,
      9
    )
  },
  useFileList: (refreshIterator: number | string) => {
    return useSync<FileListRequestSchema, FileListResponseSchema>(refreshIterator, requestsX.useFileList, 10)
  },
  useAttachment: (refreshIterator: number | string) => {
    return useSync<AttachmentDownloadRequestSchema, AttachmentDownloadResponseDto>(
      refreshIterator,
      requestsX.useAttachment,
      11
    )
  },
}
