import { Dialog } from "@mui/material"
import React, { useState } from "react"
import { NewRecord, NewRecordDialogProps } from "./NewRecord"

export const NewRecordDialog = (props: NewRecordDialogProps) => {
  const [closeable, setCloseable] = useState(false)
  const close = () => {
    if (!closeable) {
      return
    }
    props.close()
  }

  return (
    <Dialog sx={{ zIndex: 999 }} onClose={close} open={props.isOpen} fullWidth maxWidth="lg">
      {props.isOpen && <NewRecord {...props} setCloseable={setCloseable} />}
    </Dialog>
  )
}
