import home from "@frontend/shared/contexts/language-context/translations/en/home"
import login from "@frontend/shared/contexts/language-context/translations/en/login"
import header from "@frontend/shared/contexts/language-context/translations/en/header"
import fileOverview from "@frontend/shared/contexts/language-context/translations/en/file-overview"
import details from "@frontend/shared/contexts/language-context/translations/de/details"

export const messagesEn = {
  testMessage: "Eng",
  home,
  login: login,
  header,
  fileOverview,
  details,
}
