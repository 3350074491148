import { EventMeta, EventResponseDto } from "@frontend/shared/models/EventResponseDto"
import { useCallback, useEffect, useRef, useState } from "react"
import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import { DataResult, useEventQueueContext } from "@frontend/shared/contexts/event-queue-context/EventQueueContext"
import { ResponseWrap } from "@dccs/http-client"
import { encryptAes } from "@frontend/shared/crypto-new/aesCrypto"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import ExtWebApi from "@frontend/extweb-api/ext-web-api"
import { getBaseName } from "@frontend/shared/utils/path.utils"
import { useConfirmationDialogContext } from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"
import { useTranslation } from "react-i18next"

const initialEvent: EventMeta = {
  isLoaded: false,
  isLoading: false,
  createDate: undefined,
  expireDate: undefined,
  refreshDate: undefined,
  hashedCacheKey: "",
  reload: () => {},
}

export const useSync = <TRequest, TResponse>(
  refreshIterator: number | string,
  apiCallback: (api: ExtWebApi, body: any | undefined, options: any) => Promise<ResponseWrap<Response>>,
  debugNum: number
): EventResponseDto<TRequest, TResponse> => {
  const { subscribe, unsubscribe, reloadData } = useEventQueueContext()
  const { showYesNoDialog } = useConfirmationDialogContext()
  const { t } = useTranslation()

  const [eventResult, setEventResult] = useState<EventMeta>(initialEvent)
  const [data, setData] = useState<TResponse>()
  const api = useExtWebApi()

  const doUnsubscribe = useCallback(
    (subscription: (data: DataResult) => void) => {
      unsubscribe(subscription)
      setEventResult(initialEvent)
    },
    [unsubscribe]
  )

  const subscription = useCallback(
    (data: DataResult) => {
      setEventResult({
        isLoaded: data.data,
        isLoading: false,
        createDate: data.createDate,
        expireDate: data.expireDate,
        refreshDate: data.pendingStartDate,
        hashedCacheKey: data.hashedCacheKey,
        reload: () => {
          reloadData(subscription)
        },
      })
      setData(data.data)
    },
    [reloadData]
  )

  useEffect(() => {
    if (!refreshIterator) {
      return
    }
    return () => {
      doUnsubscribe(subscription)
    }
  }, [doUnsubscribe, subscription, refreshIterator])

  const subscribeCallbackAsync = useCallback(
    async (
      model: TRequest | undefined,
      disableInitialLoad: boolean = false,
      cacheKey: string | number | undefined | null = undefined,
      noCache = false
    ) => {
      if (!refreshIterator) {
        return
      }
      doUnsubscribe(subscription)

      if (!model) {
        return
      }
      let cacheKeyFull: string | undefined

      if (cacheKey !== undefined && cacheKey !== null) {
        cacheKeyFull = apiCallback.name + "-" + cacheKey
      } else {
        cacheKeyFull = apiCallback.name + "-" + JSON.stringify(model)
      }

      const cacheHashKey = hashedCacheKey(cacheKeyFull)
      const promiseToResolve = new Promise<TResponse>((resolve, reject) => {
        const subscriptionCallback = async (): Promise<void> => {
          const jsonDataStr = JSON.stringify(model)
          const encryptedData = await encryptAes(jsonDataStr)

          const body: any = {
            encryptedData: encryptedData,
            hashedCacheKey: cacheHashKey,
          }
          const result = await apiCallback(api, body, {})
          if (!result.ok) {
            if (result.statusCode === 401 || result.statusCode === 403) {
              showYesNoDialog({
                title: t("details.httpSessionError.title"),
                agreeText: t("details.httpSessionError.agreeText"),
                text: t("details.httpSessionError.text"),
                discardText: t("details.httpSessionError.discardText"),
                onConfirm: () => {
                  gotoLoginPage()
                },
                onCancel: () => {},
              })
            } else {
              showYesNoDialog({
                title: t("details.httpError.title"),
                agreeText: t("details.httpError.agreeText"),
                text: t("details.httpError.text"),
                discardText: t("details.httpError.discardText"),
                onConfirm: () => {
                  document.location.reload()
                },
                onCancel: () => {},
              })
            }

            throw new Error("Loading was wrong")
          }
        }
        setEventResult((old) => ({
          isLoaded: false,
          isLoading: true,
          createDate: old.createDate,
          expireDate: old.expireDate,
          refreshDate: old.refreshDate,
          hashedCacheKey: old.hashedCacheKey,
          reload: () => {
            reloadData(subscription)
          },
        }))
        subscribe(subscription, cacheHashKey, cacheKeyFull || "", subscriptionCallback, disableInitialLoad, noCache)
      })

      return promiseToResolve
    },
    [showYesNoDialog, t, reloadData, doUnsubscribe, api, apiCallback, subscribe, subscription, refreshIterator]
  )

  const subscribeCallback = useCallback(
    async (
      model: TRequest | undefined,
      disableInitialLoad: boolean = false,
      cacheKey: string | number | undefined | null = undefined,
      noCache = false
    ) => {
      void subscribeCallbackAsync(model, disableInitialLoad, cacheKey, noCache)
    },
    [subscribeCallbackAsync]
  )

  const responseModel = useRef<EventResponseDto<TRequest, TResponse>>({} as any)
  responseModel.current.request = subscribeCallback
  responseModel.current.event = eventResult
  responseModel.current.data = data
  responseModel.current.requestAsync = subscribeCallbackAsync

  return responseModel.current
}

export const gotoLoginPage = () => {
  const currentPathName = document.location.pathname
  const baseName = getBaseName()
  const successRedirect = currentPathName.substring(baseName.length - 1)
  let url = document.location.protocol + "//" + document.location.host + baseName + "auth?expired"
  if (successRedirect && successRedirect.length > 1) {
    url += "&success=" + encodeURIComponent(successRedirect)
    document.location.href = url
  }
  document.location.href = url
}
