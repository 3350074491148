import { Box, Button, Grid, Typography } from "@mui/material"
import Logo from "../../../assets/logo.png"
import { useTranslation } from "react-i18next"
import React, { useEffect } from "react"
import { makeStyles } from "tss-react/mui"
import { useExtWebUserContext } from "@frontend/extweb-lib/contexts/user-context/ExtWebUserContext"
import { useNavigate } from "react-router-dom"
import { useConfirmationDialogContext } from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"

const PADDING = 32

const useStyles = makeStyles()((theme) => ({
  pageContainer: {
    height: "100vh",
    padding: `${PADDING}px`,
  },
  layout: {
    minHeight: `calc(100vh - ${PADDING * 2}px) `,
  },
  loginInputsContainer: {
    width: "425px",
  },
  loginButton: {
    width: "100%",
    textTransform: "none",
    fontSize: 13,
  },
  blueContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "26px",
  },
}))

const Logout: React.FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { showYesNoDialog } = useConfirmationDialogContext()

  const backToLogin = async () => {
    navigate("/auth")
  }

  const { logoutSuccess, doLogout } = useExtWebUserContext()

  useEffect(() => {
    void doLogout()

    /*
    // auto refresh
    setTimeout(() => {
      const baseName = getBaseName()
      const url =
        document.location.protocol +
        "//" +
        document.location.host +
        baseName +
        "auth"

      document.location.href = url
    }, 5 * 1000)
    */
  }, [t, showYesNoDialog, doLogout, logoutSuccess])

  return (
    <Box className={classes.pageContainer}>
      <Grid container direction={"row"}>
        <Grid
          item
          container
          direction={"row"}
          alignItems="center"
          justifyContent="center"
          xs={7}
          className={classes.layout}
        >
          <Grid item container direction={"column"} className={classes.loginInputsContainer}>
            <div>
              <img src={Logo} alt="Logo" />
            </div>
            <div>
              <Typography variant={"h3"}>{t("login.logout")}</Typography>
            </div>
            <div>
              {logoutSuccess ? (
                <Typography>{t("login.logoutSuccessful")}</Typography>
              ) : (
                <Typography>{t("login.logoutProgress")}</Typography>
              )}
            </div>
            {logoutSuccess && (
              <>
                <br /> <br />
                <Button
                  variant="contained"
                  color={"primary"}
                  className={classes.loginButton}
                  onClick={() => {
                    backToLogin()
                  }}
                >
                  {t("login.backToLogin")}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={5} className={classes.blueContainer}></Grid>
      </Grid>
    </Box>
  )
}

export default Logout
