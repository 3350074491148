import localforage from "localforage"

export class Keystore {
  private store: LocalForage

  constructor(name: string) {
    this.store = localforage.createInstance({ name })
  }

  public async loadKey(keyHash: string): Promise<CryptoKey | undefined> {
    const key = await this.store.getItem<CryptoKey>(keyHash)
    return key || undefined
  }

  public async removeOldKeys(): Promise<void> {
    const allKeys = await this.store.keys()
    for (const key of allKeys) {
      if (key.startsWith("kpr-") || key.startsWith("kpu-")) {
        await this.store.removeItem(key)
      }
    }
  }

  public async putKey(keyHash: string, key: CryptoKey): Promise<void> {
    await this.store.setItem<CryptoKey>(keyHash, key)
  }
  public async putData<T>(keyHash: string, data: T): Promise<void> {
    await this.store.setItem<T>(keyHash, data)
  }
  public async loadData<T>(keyHash: string): Promise<T | undefined> {
    const key = await this.store.getItem<T>(keyHash)
    return key || undefined
  }

  public async remove(keyHash: string): Promise<void> {
    await this.store.removeItem(keyHash)
  }
}
