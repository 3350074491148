import React, { memo, useMemo } from "react"
import MainTableHeadCell from "./MainTableHeadCell"
import MainTableRow from "./MainTableRow"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import Paper from "@mui/material/Paper"
import { Box, TableCell, TablePagination, Typography } from "@mui/material"
import { MainTableProps } from "./main-table-config"
import usePagination from "@frontend/extweb-lib/hooks/usePagination.hook"
import { useTranslation } from "react-i18next"
import MainTableGroupRow from "./MainTableGroupRow"
import { groupItemsByDate } from "@frontend/extweb-lib/utils/object-utils"

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "100%",
    padding: "8px 16px 0 16px",
    boxShadow: "none",
    minHeight: "430px",
    "& .MuiTableCell-root": {
      verticalAlign: "top",
    },
    "& .highlight td": {
      background: "#F6FAFF",
    },
  },
  paginationContainer: { display: "flex", justifyContent: "start", paddingTop: "16px", paddingBottom: "24px" },
  noPaddingPagination: {
    "& .MuiToolbar-root": {
      paddingLeft: 0,
    },
  },
}

export const MainTable = memo<MainTableProps<any>>(({ data, columns, groupRendering, onRowClickNavigation }) => {
  const { t } = useTranslation()
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination()

  const pagedItems = useMemo(() => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }, [page, rowsPerPage, data])

  return (
    <TableContainer sx={styles.container} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {groupRendering && (
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              ></TableCell>
            )}
            {columns.map((columnItem, index) => (
              <MainTableHeadCell key={columnItem.key} columnItem={columnItem} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupRendering ? (
            <MainTableGroupRow data={data} page={page} rowsPerPage={rowsPerPage} columns={columns} />
          ) : (
            pagedItems.map((item, index) => (
              <MainTableRow key={index} item={item} columns={columns} onRowClickNavigation={onRowClickNavigation} />
            ))
          )}
        </TableBody>
      </Table>
      {pagedItems.length < 1 && <Typography variant="body2">{t("fileOverview.noTableEntries")}</Typography>}
      <Box sx={styles.paginationContainer}>
        <TablePagination
          sx={styles.noPaddingPagination}
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={groupRendering ? Object.keys(groupItemsByDate(data, "DateUtc")).length : data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("fileOverview.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return (
              <span>
                {from}-{to} {t("fileOverview.of")} {count}
              </span>
            )
          }}
        />
      </Box>
    </TableContainer>
  )
})
