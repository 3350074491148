import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { ConfirmationDialogProps } from "./ConfirmationDialog.types"

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  dialogText,
  dialogTitleText,
  discardText,
  agreeText,
  showDiscardButton = true,
  showAgreeButton = true,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{dialogTitleText}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-wrap" }} id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showDiscardButton && (
          <Button data-testid="dialog-disagree-button" onClick={onCancel} color="secondary" autoFocus>
            {discardText != null ? discardText : t("details.no")}
          </Button>
        )}
        {showAgreeButton && (
          <Button data-testid="dialog-agree-button" onClick={onConfirm} color="primary" variant={"contained"}>
            {agreeText != null ? agreeText : t("details.ok")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
