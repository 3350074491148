import React, { useEffect } from "react"
import { IconButton, Tooltip } from "@mui/material"
import {
  FileRecordListResponseSchema,
  RecordItem,
  SendToAppResponseSchema,
  WebDavApprovalResponseSchema,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import ApprovalIcon from "@mui/icons-material/Approval"
import { LoadingMeta } from "@frontend/extweb-lib/components/LoadingMeta/LoadingMeta"
import { useTranslation } from "react-i18next"
import { useConfirmationDialogContext } from "@frontend/shared/contexts/confirmation-dialog-context/ConfirmationDialogContext"

export interface RecordActionsProps {
  fileId: number
  recordItem: RecordItem
}

export const RecordActions = (props: RecordActionsProps) => {
  const { showYesNoDialog } = useConfirmationDialogContext()
  const { t } = useTranslation()
  const api = useExtWebApi()
  const reset = 1

  const [recordList, loadRecordList] = useSync(
    reset,
    api.fileRecordListApiService,
    api.fileRecordListApiService.getFileRecordList_POST,
    null as unknown as FileRecordListResponseSchema
  )

  const [sendToAppResponse, doSendToApp] = useSync(
    reset,
    api.fileRecordListApiService,
    api.fileRecordListApiService.sendToApp_POST,
    null as unknown as SendToAppResponseSchema
  )
  const [approvalResponse, approvalRequest] = useSync(
    reset,
    api.webDavFileApiService,
    api.webDavFileApiService.webDavApproval_POST,
    null as unknown as WebDavApprovalResponseSchema
  )

  useEffect(() => {
    if (!approvalResponse.isLoaded) {
      return
    }
    // reload table
    loadRecordList(
      {
        FileId: props.fileId,
        Page: 1,
        PageSize: 10000,
      },
      false,
      undefined,
      true
    )
  }, [loadRecordList, approvalResponse.isLoaded, props.fileId])

  useEffect(() => {
    if (!sendToAppResponse.isLoaded) {
      return
    }
    // reload table
    loadRecordList(
      {
        FileId: props.fileId,
        Page: 1,
        PageSize: 10000,
      },
      false,
      undefined,
      true
    )
  }, [loadRecordList, sendToAppResponse.isLoaded, props.fileId])

  const item = props.recordItem

  return (
    <>
      {item.CanRequestApproval && (
        <>
          {approvalResponse.isLoading && <LoadingMeta eventMeta={approvalResponse} asIcon={true} />}
          {!approvalResponse.isLoading && (
            <Tooltip title={t("details.submitForApproval")}>
              <IconButton
                sx={{ height: "42px", width: "42px" }}
                onClick={() => {
                  showYesNoDialog({
                    title: t("details.messageFromPage"),
                    text: t("details.submitCorrespondenceForApproval"),
                    onConfirm: () =>
                      approvalRequest({
                        RecordId: item.RecordId,
                      }),
                  })
                }}
              >
                <ApprovalIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  )
}
