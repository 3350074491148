import { InvitationState, RecordType } from "@frontend/extweb-api/swagger/model/dataModel"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

export const recordTypeOptions = [
  { value: RecordType.PastContact, label: "details.contact" },
  { value: RecordType.Correspondence, label: "details.correspodence" },
]

export interface ParticipationStatus {
  label: string
  value: InvitationState
  icon: JSX.Element
}
export const participationStatuses: ParticipationStatus[] = [
  {
    label: "details.participated",
    value: InvitationState.Accepted,
    icon: <CheckCircleOutlineIcon style={{ color: "#177321", width: "24px", height: "24px" }} />,
  },
  {
    label: "details.wasExcused",
    value: InvitationState.Cancelled,
    icon: <CancelOutlinedIcon style={{ color: "#D92121", width: "24px", height: "24px" }} />,
  },
  {
    label: "details.noExcused",
    value: InvitationState.Declined,
    icon: <NotInterestedIcon style={{ color: "#767676", width: "24px", height: "24px" }} />,
  },
]
