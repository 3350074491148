// @ts-ignore
// @ts-ignore
// @ts-ignore

import { createTheme, Theme } from "@mui/material"

// @ts-ignore
declare module "tss-react/mui" {
  type DefaultTheme = Theme
}

const defaultTheme = createTheme()

const primaryColor = "#0048B5"

const extWebTheme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: `Roboto`,
    subtitle1: {
      marginTop: defaultTheme.spacing(4),
      marginBottom: defaultTheme.spacing(2),
      fontSize: 18.72,
      fontWeight: 600,
      borderBottom: "1px solid",
      borderColor: "#007994",
    },
    subtitle2: {
      fontSize: 16,
    },
    h3: {
      fontSize: "40px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "140%",
      fontFamily: `EB Garamond`,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
})

export default extWebTheme
