import React, { ReactElement } from "react"
import { Box } from "@mui/material"
import Header from "../header/Header"

interface ContentProps {
  children: ReactElement
}

export const Content = ({ children }: ContentProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />
      <Box sx={{ flex: 1, background: "#F6FAFF" }}>{children}</Box>
    </Box>
  )
}
