// eslint-disable-next-line no-undef
import axios, { Method } from "axios"
import { getBaseName } from "@frontend/shared/utils/path.utils"

export interface HttpOptions {
  url: string
  options: any
  tryAfterJwt?: boolean
}

export interface ResponseWrap<TReturn> {
  ok: boolean
  noConnection: boolean
  result: TReturn | undefined
  error?: string
  statusCode: number
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = "RequiredError" as const

  constructor(public field: string, msg?: string) {
    super(msg ?? "")
  }
}

/**
 *
 * @export
 */
export const COLLECTION_FORMATS: any = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
}

const defaultContentType = "application/json"

export class HttpClient {
  constructor() {}

  public async fetch<TReturn>(serviceName: string, name: string, options: HttpOptions): Promise<ResponseWrap<TReturn>> {
    const { apiUrl, fetchOptions } = this.getApiUrl(options)
    let statusCode = 0
    try {
      const response = await axios({
        method: fetchOptions.method as Method,
        url: options.url,
        baseURL: apiUrl,
        headers: fetchOptions.headers,
        data: fetchOptions.body,
      })

      return {
        ok: true,
        noConnection: true,
        result: response.data as TReturn,
        statusCode: response.status,
      }
    } catch (err: any) {
      if (err.response?.status === 401) {
        const currentPathName = document.location.pathname
        const baseName = getBaseName()
        const successRedirect = currentPathName.substring(baseName.length - 1)
        let url = document.location.protocol + "//" + document.location.host + baseName + "auth?expired"
        if (successRedirect && successRedirect.length > 1) {
          url += "&success=" + encodeURIComponent(successRedirect)
          document.location.href = url
        }
        document.location.href = url
      }
      statusCode = err.response?.status || 0
      console.error(err.message)
    }

    return {
      ok: false,
      noConnection: true,
      result: undefined,
      statusCode: statusCode,
    }
  }

  public async fetchWithoutResponse(
    serviceName: string,
    name: string,
    options: HttpOptions
  ): Promise<ResponseWrap<Response>> {
    const { apiUrl, fetchOptions } = this.getApiUrl(options)
    let statusCode = 0

    try {
      const response = await axios({
        method: fetchOptions.method as Method,
        url: options.url,
        baseURL: apiUrl,
        headers: fetchOptions.headers,
        data: fetchOptions.body,
      })

      return {
        ok: true,
        noConnection: true,
        result: undefined,
        statusCode: response.status,
      }
    } catch (err: any) {
      statusCode = err.response?.status || 0
      console.error(err.message)
    }

    return {
      ok: false,
      noConnection: true,
      result: undefined,
      statusCode: statusCode,
    }
  }

  private getApiUrl(options: HttpOptions) {
    // @ts-ignore
    const apiUrl = import.meta.env.VITE_REACT_APP_BASE_URL as string

    const fetchOptions = options.options as RequestInit

    fetchOptions.headers = {}
    if (!fetchOptions.headers["Content-Type"]) {
      fetchOptions.headers["Content-Type"] = defaultContentType
    }
    if (!fetchOptions.headers.Accept) {
      fetchOptions.headers.Accept = defaultContentType
    }

    return { apiUrl, fetchOptions }
  }
}
