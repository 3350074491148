/* eslint-disable */
/**
 * BgbwMobile.ExtWeb
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from "@customTypes"
import url from "url"
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@dccs/http-client"

import { CreateCorrespondenceMetaRequestDto } from "../model/dataModel"
import { UpsertCorrespondenceRequestDto } from "../model/dataModel"
import { ViewCorrespondenceRequestDto } from "../model/dataModel"

export class CorrespondenceApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {CreateCorrespondenceMetaRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // CorrespondenceController.CreateCorrespondenceMeta  (BgbwExtWeb.Web.Controllers)
  // apiV1CorrespondencesCreateMetaPost
  public createCorrespondenceMeta_POST(
    body?: CreateCorrespondenceMetaRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/correspondences/create-meta`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"CreateCorrespondenceMetaRequestDto" !== "string" ||
      requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Correspondence", "apiV1CorrespondencesCreateMetaPost", httpOptions)
  }

  /**
   *
   * @param {ViewCorrespondenceRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // CorrespondenceController.ViewCorrespondence  (BgbwExtWeb.Web.Controllers)
  // apiV1CorrespondencesPost
  public viewCorrespondence_POST(
    body?: ViewCorrespondenceRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/correspondences`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"ViewCorrespondenceRequestDto" !== "string" || requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Correspondence", "apiV1CorrespondencesPost", httpOptions)
  }

  /**
   *
   * @param {UpsertCorrespondenceRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // CorrespondenceController.UpsertCorrespondence  (BgbwExtWeb.Web.Controllers)
  // apiV1CorrespondencesUpsertPost
  public upsertCorrespondence_POST(
    body?: UpsertCorrespondenceRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/api/v1/correspondences/upsert`
    const urlObj = url.parse(_path, true)
    const requestOptions = Object.assign({ method: "POST" }, options)
    const headerParameter = {} as any
    const queryParameter = {} as any

    headerParameter["Content-Type"] = "application/json"

    urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query)
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign({}, headerParameter, options.headers)
    const needsSerialization =
      <any>"UpsertCorrespondenceRequestDto" !== "string" ||
      requestOptions.headers["Content-Type"] === "application/json"
    requestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ""

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    }

    return this.httpClient.fetchWithoutResponse("Correspondence", "apiV1CorrespondencesUpsertPost", httpOptions)
  }
}
