import { EventResponseDto, EventResponseModel } from "@frontend/shared/models/EventResponseDto"
import { useCallback, useEffect, useMemo, useState } from "react"
import { hashedCacheKey } from "@frontend/shared/encryption/cache-key"
import { DataResult, useEventQueueContext } from "@frontend/shared/contexts/event-queue-context/EventQueueContext"
import { ResponseWrap } from "@dccs/http-client"
import { encryptAes } from "@frontend/shared/crypto-new/aesCrypto"
import { Guid } from "@customTypes"

const initialEvent: EventResponseDto<any> = {
  isLoaded: false,
  isLoading: false,
  data: {},
  createDate: undefined,
  expireDate: undefined,
  refreshDate: undefined,
  hashedCacheKey: "",
  reload: () => {},
}

export interface IRequest {
  encryptedData: string
  __Schema?: any
  hashedCacheKey: Guid
}

export const useSync = <TResponse, TRequest extends IRequest, TApi>(
  refreshIterator: number | string,
  apiThis: TApi,
  apiCallback: (body: TRequest | undefined, options: any) => Promise<ResponseWrap<Response>>,
  // Explicit on first generic but infer the second in TS?
  //  https://stackoverflow.com/questions/57453443/explicit-on-first-generic-but-infer-the-second-in-ts
  genericDummy: TResponse | undefined = undefined
): EventResponseModel<TRequest["__Schema"] | undefined, TResponse> => {
  const { subscribe, unsubscribe, reloadData } = useEventQueueContext()
  const [eventResult, setEventResult] = useState<EventResponseDto<any>>(initialEvent)

  const doUnsubscribe = useCallback(
    (subscription: (data: DataResult) => void) => {
      unsubscribe(subscription)
      setEventResult(initialEvent)
    },
    [unsubscribe]
  )

  const subscription = useCallback(
    (data: DataResult) => {
      setEventResult({
        isLoaded: data.data,
        isLoading: false,
        data: data.data,
        createDate: data.createDate,
        expireDate: data.expireDate,
        refreshDate: data.pendingStartDate,
        hashedCacheKey: data.hashedCacheKey,
        reload: () => {
          reloadData(subscription)
        },
      })
    },
    [reloadData]
  )

  useEffect(() => {
    if (!refreshIterator) {
      return
    }
    return () => {
      doUnsubscribe(subscription)
    }
  }, [doUnsubscribe, subscription, refreshIterator])

  const subscribeCallback = useCallback(
    async (
      model: TRequest["__Schema"] | undefined,
      disableInitialLoad: boolean = false,
      cacheKey: string | number | undefined | null = undefined,
      noCache = false
    ) => {
      if (!refreshIterator) {
        return
      }
      doUnsubscribe(subscription)

      if (!model) {
        return
      }
      let cacheKeyFull: string | undefined

      if (cacheKey !== undefined && cacheKey !== null) {
        cacheKeyFull = apiCallback.name + "-" + cacheKey
      } else {
        cacheKeyFull = apiCallback.name + "-" + JSON.stringify(model)
      }

      const cacheHashKey = hashedCacheKey(cacheKeyFull)

      const subscriptionCallback = async (): Promise<void> => {
        const jsonDataStr = JSON.stringify(model)
        const encryptedData = await encryptAes(jsonDataStr)

        const body: any = {
          encryptedData: encryptedData,
          hashedCacheKey: cacheHashKey,
        }
        const result = await apiCallback.apply(apiThis, [body, {}])
        if (!result.ok) {
          throw new Error("Loading was wrong")
        }
      }
      setEventResult((old) => ({
        isLoaded: false,
        isLoading: true,
        data: old.data,
        createDate: old.createDate,
        expireDate: old.expireDate,
        refreshDate: old.refreshDate,
        hashedCacheKey: old.hashedCacheKey,
        reload: () => {
          reloadData(subscription)
        },
      }))
      subscribe(subscription, cacheHashKey, cacheKeyFull, subscriptionCallback, disableInitialLoad, noCache)
    },
    [doUnsubscribe, reloadData, apiThis, apiCallback, subscribe, subscription, refreshIterator]
  )

  const model = useMemo(() => {
    return [eventResult, subscribeCallback] as EventResponseModel<TRequest["__Schema"] | undefined, TResponse>
  }, [eventResult, subscribeCallback])

  return model
}
