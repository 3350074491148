import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material"
import React, { SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { styles } from "../DialogStyles"
import {
  AdditonalParameter,
  AdditonalParameterType,
  CorrespondenceTemplateBase,
  CorrespondenceTemplateParticipants,
  CreateCorrespondenceMetaResponseSchema,
  CreateParticipantItem,
  FileListItemSchema,
  FileRecordListResponseSchema,
  InvitationState,
  RecordType,
  UpsertContactRecord,
  UpsertContactResponseSchema,
  UpsertCorrespondenceRecord,
  UpsertCorrespondenceResponseSchema,
  ViewContactResponseSchema,
  ViewCorrespondenceResponseSchema,
} from "@frontend/extweb-api/swagger/model/dataModel"
import { Title } from "@frontend/extweb-lib/components/title/Title"
import { useSync } from "@frontend/shared/hooks/useSync.hook"
import { useExtWebApi } from "@frontend/extweb-api/api-context/ExtWebApiContext"
import { recordTypeOptions } from "../../../dialog.data"
import { ValidationSimpleResults } from "@frontend/shared/validation/base-form-validation/base-form-validation"
import { LoadingMeta } from "@frontend/extweb-lib/components/LoadingMeta/LoadingMeta"
import { getNewUpsertContactRecord, getNewUpsertCorrespondenceRecord } from "./form.utils"

import { ParticipantSelect } from "../../participant-select/ParticipantSelect"
import { AdditionalForm } from "../../additonal-data/AdditionalForm"
import { AdditionalErrors, DateState, GeneralForm } from "@frontend/extweb-lib/views/details/models"
import { ValidateFormResult, validateNewContactWizard, validateNewCorrespondenceWizard, validateStep } from "./validate"
import { CorrespondenceForm } from "./CorrespondenceForm"
import { ContactForm } from "./ContactForm"
import { hasError } from "@frontend/extweb-lib/views/details/components/dialog/Dialog.utils"
import { format, parseISO } from "date-fns"

export interface NewRecordDialogProps {
  isOpen: boolean
  close: () => void
  recordType: RecordType
  fileId: number
  fileDetails: FileListItemSchema
  newRecordCounter: number
  setCloseable?: (closeable: boolean) => void
}

export const tabs = {
  selectRecordType: 0,
  selectParticipant: 1,
  form: 2,
  additionalData: 3,
}

const getTitleFromParticipants = (participants: CorrespondenceTemplateParticipants[] | undefined): string => {
  if (!participants) {
    return ""
  }
  const ps = [...participants]
  ps.sort((a, b) => {
    return a.Name.localeCompare(b.Name)
  })
  return ps.map((x) => x.Name).join("; ")
}

const getDateFromIso = (date: string): Date | undefined => {
  if (!date) {
    return undefined
  }
  try {
    return parseISO(date)
  } catch (e: any) {
    // not yet ready date string
  }
  return undefined
}
export const NewRecord = (props: NewRecordDialogProps) => {
  const dialogRef = useRef<HTMLDivElement | null>(null)
  const [steps, setSteps] = useState<string[]>([])
  const [isInitialized, setIsInitialized] = useState(false)

  const [nextActivated, setNextActivated] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const previousActiveStepRef = useRef(tabs.selectParticipant)
  const { t } = useTranslation()
  const api = useExtWebApi()

  const [generalForm, setGeneralForm] = useState<GeneralForm>({} as any)
  const [contactForm, setContactForm1] = useState<UpsertContactRecord>({} as any)
  const [correspondenceForm, setCorrespondenceForm1] = useState<UpsertCorrespondenceRecord>({} as any)
  const [isFormChecked, setIsFormChecked] = useState(false)

  const [datePicker, setDatePicker] = useState<DateState>({} as any)
  const [generalFormErrors, setGeneralFormErrors] = useState<ValidationSimpleResults<GeneralForm>>({} as any)
  const [contactFormErrors, setContactFormErrors] = useState<
    ValidationSimpleResults<UpsertContactRecord & AdditionalErrors>
  >({} as any)
  const [correspondenceFormErrors, setCorrespondenceFormErrors] = useState<
    ValidationSimpleResults<UpsertCorrespondenceRecord & AdditionalErrors>
  >({} as any)
  const [additionalFormErrors, setAdditionalFormErrors] = useState<{ [key: number]: string }>({})

  const [documentTemplates, setDocumentTemplates] = useState<CorrespondenceTemplateBase[]>([])

  const setContactForm = useCallback((data: SetStateAction<UpsertContactRecord>) => {
    setContactForm1(data)
  }, [])

  const setCorrespondenceForm = useCallback((data: SetStateAction<UpsertCorrespondenceRecord>) => {
    setCorrespondenceForm1(data)
  }, [])

  const reset = props.newRecordCounter

  // Schriftverkehr / correspondence
  const [correspondenceMetaResponse, requestCorrespondenceMeta] = useSync(
    reset,
    api.correspondenceApiService,
    api.correspondenceApiService.createCorrespondenceMeta_POST,
    null as unknown as CreateCorrespondenceMetaResponseSchema
  )

  const [contactResponse, requestContact] = useSync(
    reset,
    api.contactApiService,
    api.contactApiService.getViewContact_POST,
    null as unknown as ViewContactResponseSchema
  )

  const [correspondenceResponse, requestCorrespondence] = useSync(
    reset,
    api.correspondenceApiService,
    api.correspondenceApiService.viewCorrespondence_POST,
    null as unknown as ViewCorrespondenceResponseSchema
  )

  const [updateContactResponse, requestUpdateContact] = useSync(
    reset,
    api.contactApiService,
    api.contactApiService.upsertContact_POST,
    null as unknown as UpsertContactResponseSchema
  )

  const [recordList, loadRecordList] = useSync(
    reset,
    api.fileRecordListApiService,
    api.fileRecordListApiService.getFileRecordList_POST,
    null as unknown as FileRecordListResponseSchema
  )

  const [updateCorrespondenceResponse, requestUpdateCorrespondence] = useSync(
    reset,
    api.correspondenceApiService,
    api.correspondenceApiService.upsertCorrespondence_POST,
    null as unknown as UpsertCorrespondenceResponseSchema
  )

  const modifyGeneralForm = useCallback((field: keyof GeneralForm, value: string | number) => {
    setGeneralForm((prev) => ({
      ...prev,
      [field]: value,
    }))
  }, [])

  const modifyCorrespondenceForm = useCallback(
    (partial: Partial<UpsertCorrespondenceRecord>) => {
      setCorrespondenceForm((prev) => ({
        ...prev,
        ...partial,
      }))
    },
    [setCorrespondenceForm]
  )

  const modifyContactForm = useCallback(
    (partial: Partial<UpsertContactRecord>) => {
      setContactForm((prev) => ({
        ...prev,
        ...partial,
      }))
    },
    [setContactForm]
  )

  useEffect(() => {
    setGeneralForm((prev) => ({
      ...prev,
      fileId: props.fileId,
      recordId: 0,
      recordType: props.recordType,
    }))
    setCorrespondenceForm({} as any)
    modifyContactForm({} as any)
    setGeneralFormErrors({} as any)
    setContactFormErrors({} as any)
    setDatePicker({} as any)
    setActiveStep(0)
    setDocumentTemplates([])
  }, [props.fileId, props.recordType, reset, setGeneralForm, setCorrespondenceForm, modifyContactForm])

  useEffect(() => {
    const active = validateStep(
      activeStep,
      generalForm,
      contactForm,
      correspondenceForm,
      correspondenceMetaResponse.data
    )
    setNextActivated(active)
  }, [activeStep, generalForm, contactForm, correspondenceForm, correspondenceMetaResponse.data])

  useEffect(() => {
    setGeneralForm((prev) => ({
      ...prev,
      fileId: props.fileId,
      recordId: 0,
      recordType: props.recordType,
    }))
  }, [props.recordType, props.fileId])

  useEffect(() => {
    if (generalForm.fileId === 0) {
      return
    }
    requestCorrespondenceMeta(
      {
        FileId: generalForm.fileId,
      },
      true
    )
    if (generalForm.recordType === RecordType.PastContact) {
      requestContact(
        {
          FileId: generalForm.fileId,
          RecordId: 0,
        },
        true
      )

      return
    }
  }, [requestContact, requestCorrespondenceMeta, generalForm.fileId, generalForm.recordType])

  useEffect(() => {
    const preSelectedAdditionalParameters: AdditonalParameter[] = []

    for (const ap of correspondenceResponse.data.Template?.AdditonalParameters || []) {
      if (ap.ParameterType === AdditonalParameterType.DoubleSelect) {
        let firstValue = ap.Values?.find((x) => x.IsDefault)
        if (!firstValue) {
          firstValue = ap.Values?.[0]
        }
        let childValue = firstValue?.Values?.find((x) => x.IsDefault)
        if (!childValue) {
          childValue = firstValue?.Values?.[0]
        }
        preSelectedAdditionalParameters.push({
          Id: ap.Id,
          ParentId: firstValue?.Id || 0,
          Value: childValue?.Id?.toString() || "",
        })
      }
      if (ap.ParameterType === AdditonalParameterType.Select) {
        const firstValue = ap.Values?.[0]
        preSelectedAdditionalParameters.push({
          Id: ap.Id,
          ParentId: 0,
          Value: firstValue?.Id?.toString() || "",
        })
      }
      if (ap.PresetTime) {
        const date = getDateFromIso(correspondenceForm.FromDateUtc)
        if (date) {
          preSelectedAdditionalParameters.push({
            Id: ap.Id,
            ParentId: 0,
            Value: format(date, "HH:mm"),
          })
        }
      }
      if (ap.PresetDate) {
        const date = getDateFromIso(correspondenceForm.FromDateUtc)
        if (date) {
          preSelectedAdditionalParameters.push({
            Id: ap.Id,
            ParentId: 0,
            Value: date.toISOString(),
          })
        }
      }
    }

    setCorrespondenceForm((prev) => {
      return {
        ...prev,
        AdditonalParameters: preSelectedAdditionalParameters,
      }
    })
  }, [correspondenceResponse.data.Template?.AdditonalParameters, setCorrespondenceForm, correspondenceForm.FromDateUtc])

  useEffect(() => {
    if (correspondenceMetaResponse.data || contactResponse.data) {
      setIsInitialized(true)
    }
  }, [correspondenceMetaResponse.data, contactResponse.data])

  useEffect(() => {
    if (!correspondenceMetaResponse.data?.DocumentTypes || !correspondenceForm.DocumentTypeId) {
      return
    }
    const documentType = correspondenceMetaResponse.data.DocumentTypes.find(
      (x) => x.DocumentTypeId === correspondenceForm.DocumentTypeId
    )
    const _templates = documentType?.Templates || []
    setDocumentTemplates(_templates)
  }, [correspondenceForm.DocumentTypeId, correspondenceMetaResponse.data?.DocumentTypes])

  useEffect(() => {
    if (!props.newRecordCounter) {
      return
    }
    const upsertCorrespondenceRecord = getNewUpsertCorrespondenceRecord(props.fileId)
    setCorrespondenceForm(upsertCorrespondenceRecord)
  }, [setCorrespondenceForm, props.fileId, props.newRecordCounter])

  useEffect(() => {
    if (!props.newRecordCounter) {
      return
    }
    if (!contactResponse.data) {
      return
    }
    const upsertContactRecord = getNewUpsertContactRecord(props.fileId)
    setContactForm(upsertContactRecord)
  }, [setContactForm, props.fileId, contactResponse.data, props.newRecordCounter])

  useEffect(() => {
    if (!correspondenceForm.TemplateId || activeStep >= 1) {
      return
    }
    requestCorrespondence(
      {
        FileId: generalForm.fileId,
        RecordId: 0, // 0 for new records
        TemplateId: correspondenceForm.TemplateId,
      },
      true
    )
  }, [requestCorrespondence, generalForm.fileId, correspondenceForm.TemplateId, activeStep])

  useEffect(() => {
    if (!correspondenceResponse.data) {
      return
    }
    const participantsN: CreateParticipantItem[] = []
    const participants = correspondenceResponse.data.Template?.Participants || []
    if (participants.length == 1) {
      const participant = participants[0]
      participantsN.push({
        ParticipantId: participant.ParticipantId,
        InvitationState: InvitationState.None,
        PersonId: participant.PersonId,
      })
    }

    setCorrespondenceForm((prev) => ({
      ...prev,
      Participants: participantsN,
    }))
  }, [setCorrespondenceForm, correspondenceResponse.data])

  useEffect(() => {
    if (hasError(updateContactResponse.data?.Errors)) {
      const errorsArray = updateContactResponse.data.Errors || []

      const errors = errorsArray.reduce((acc, error) => {
        return { ...acc, [error.Path]: error.Message }
      }, {})

      setContactFormErrors((prev) => {
        return { ...prev, ...errors }
      })
    }

    if (hasError(updateCorrespondenceResponse.data?.Errors)) {
      const errorsArray = updateCorrespondenceResponse.data.Errors || []

      const errors = errorsArray.reduce((acc, error) => {
        return { ...acc, [error.Path]: error.Message }
      }, {})

      setCorrespondenceFormErrors((prev) => {
        return { ...prev, ...errors }
      })
    }
  }, [updateContactResponse.data?.Errors, updateCorrespondenceResponse.data?.Errors])

  useEffect(() => {
    if (updateCorrespondenceResponse.data?.Success || updateContactResponse.data?.Success) {
      // reload table
      loadRecordList(
        {
          FileId: props.fileId,
          Page: 1,
          PageSize: 10000,
        },
        false,
        undefined,
        true
      )
    }
  }, [loadRecordList, props.fileId, updateCorrespondenceResponse.data?.Success, updateContactResponse.data?.Success])

  const isUpdateLoading = updateContactResponse?.isLoading || updateCorrespondenceResponse.isLoading
  const showContactForm = generalForm.recordType === RecordType.PastContact
  const isSuccess = updateContactResponse?.data?.Success || updateCorrespondenceResponse?.data?.Success
  const readOnly = isUpdateLoading
  const closeable = !isUpdateLoading

  const setCloseable = props.setCloseable
  useEffect(() => {
    setCloseable?.(closeable)
  }, [setCloseable, closeable])

  const handleNext = () => {
    let validateFormResult: ValidateFormResult | undefined = undefined
    if (generalForm.recordType === RecordType.PastContact) {
      validateFormResult = validateNewContactWizard(t, activeStep, generalForm, contactForm, datePicker)
    } else if (generalForm.recordType === RecordType.Correspondence) {
      validateFormResult = validateNewCorrespondenceWizard(
        t,
        activeStep,
        generalForm,
        correspondenceForm,
        correspondenceMetaResponse.data,
        datePicker
      )
    }
    if (!validateFormResult) {
      throw new Error("no validateFormResult")
    }

    let hasErrors = false
    if (Object.keys(validateFormResult._generalErrors).length > 0) {
      setGeneralFormErrors(validateFormResult._generalErrors)
      hasErrors = true
    }
    if (generalForm.recordType === RecordType.PastContact) {
      if (Object.keys(validateFormResult._contactFormErrors).length > 0) {
        setContactFormErrors(validateFormResult._contactFormErrors)
        hasErrors = true
      }
    }
    if (generalForm.recordType === RecordType.Correspondence) {
      if (Object.keys(validateFormResult._correspondenceFormErrors).length > 0) {
        setCorrespondenceFormErrors(validateFormResult._correspondenceFormErrors)
        hasErrors = true
      }
    }

    if (activeStep === tabs.additionalData) {
      if (generalForm.recordType === RecordType.Correspondence) {
        const additionalParametersErrors: { [key: string]: string } = {}
        for (const additionalParameter of correspondenceResponse.data.Template?.AdditonalParameters || []) {
          const additionalParameterValue = correspondenceForm.AdditonalParameters?.find(
            (formAp) => formAp.Id === additionalParameter.Id
          )
          if (additionalParameter.ParameterType == AdditonalParameterType.DoubleSelect) {
            if (!additionalParameterValue?.Value || additionalParameterValue?.Value == "0") {
              additionalParametersErrors["" + additionalParameter.Id] = " "
            }
            if (!additionalParameterValue?.ParentId) {
              additionalParametersErrors["p" + additionalParameter.Id] = " "
            }
          } else {
            if (!additionalParameterValue?.Value) {
              additionalParametersErrors[additionalParameter.Id] = " "
            }
          }
        }

        if (Object.keys(additionalParametersErrors).length > 0) {
          setAdditionalFormErrors(additionalParametersErrors)
          hasErrors = true
        } else {
          setAdditionalFormErrors({})
        }
      }
    }

    if (hasErrors) {
      return
    }

    if (activeStep === tabs.selectRecordType) {
      if (generalForm.recordType === RecordType.PastContact) {
        setContactForm((prev) => {
          return {
            ...prev,
            ...{
              Participants: prev.Participants || [],
            },
          }
        })
      }
      if (generalForm.recordType === RecordType.Correspondence) {
        setCorrespondenceForm((prev) => {
          return {
            ...prev,
            ...{
              Participants: prev.Participants || [],
            },
          }
        })
      }
    }

    if (generalForm.recordType === RecordType.Correspondence) {
      if (!correspondenceForm.TemplateId) {
        return
      }
      if (activeStep === tabs.selectRecordType) {
        const isOneParticipant = correspondenceResponse.data.Template?.Participants?.length === 1

        const title = getTitleFromParticipants(correspondenceResponse.data.Template?.Participants)
        setCorrespondenceForm((prev) => ({
          ...prev,
          Title: title,
        }))
        if (isOneParticipant) {
          if (correspondenceForm.DocumentTypeId !== 1) {
            setActiveStep((prevStep) => prevStep + 3)
          } else {
            setActiveStep((prevStep) => prevStep + 2)
          }
        } else {
          setActiveStep((prevStep) => prevStep + 1)
        }
        previousActiveStepRef.current = tabs.additionalData
        return
      }

      if (activeStep === tabs.selectParticipant) {
        previousActiveStepRef.current = tabs.additionalData

        if (correspondenceForm.DocumentTypeId !== 1) {
          setActiveStep((prevStep) => prevStep + 2)
        } else {
          setActiveStep((prevStep) => prevStep + 1)
        }

        const x = correspondenceForm.Participants?.map(
          (x) =>
            correspondenceResponse.data.Template?.Participants?.find(
              (y) => y.ParticipantId == x.ParticipantId
            ) as CorrespondenceTemplateParticipants
        )?.filter((x) => !!x)
        const title = getTitleFromParticipants(x)

        setCorrespondenceForm((prev) => ({
          ...prev,
          Title: title,
        }))
        return
      }
    }

    if (activeStep === tabs.form) {
      if (generalForm.recordType === RecordType.PastContact) {
        const contactNewForm = { ...contactForm }
        contactNewForm.FromDateUtc = validateFormResult.fromToResult!.fromUtc
        contactNewForm.ToDateUtc = validateFormResult.fromToResult!.toUtc
        modifyContactForm(contactNewForm)

        requestUpdateContact(
          {
            Contact: contactNewForm,
            RecordType: RecordType.PastContact,
          },
          false
        )

        return
      }
      if (generalForm.recordType === RecordType.Correspondence) {
        const correspondenceNewForm = { ...correspondenceForm }
        correspondenceNewForm.FromDateUtc = validateFormResult.fromToResult!.fromUtc
        correspondenceNewForm.ToDateUtc = validateFormResult.fromToResult!.toUtc

        modifyCorrespondenceForm(correspondenceNewForm)
      }
    }
    if (activeStep === tabs.additionalData) {
      requestUpdateCorrespondence(
        {
          Correspondence: correspondenceForm,
          IsAppointmentInvite: correspondenceForm.DocumentTypeId === 1,
        },
        false
      )
      if (dialogRef.current) {
        dialogRef.current.scrollTop = 0
      }
      return
    }

    setActiveStep((step) => step + 1)
  }

  const handlePrevious = () => {
    if (
      activeStep === tabs.additionalData &&
      correspondenceForm.TemplateId &&
      correspondenceForm.DocumentTypeId !== 1 &&
      previousActiveStepRef.current === tabs.additionalData
    ) {
      setActiveStep((prevStep) => prevStep - 2)
      return
    }

    setActiveStep((step) => step - 1)
  }

  const renderAlert = () => {
    if (showContactForm) {
      return renderAlertContact()
    }
    return renderAlertCorrespondence()
  }

  const renderAlertCorrespondence = () => {
    if (updateCorrespondenceResponse?.isLoading) {
      return (
        <>
          <Alert severity="info">{t("details.formSaving")}</Alert>
          <LoadingMeta eventMeta={updateCorrespondenceResponse} noDetailsBox={true} />
        </>
      )
    } else if (
      !hasError(updateCorrespondenceResponse.data?.Error) &&
      !hasError(updateCorrespondenceResponse.data?.Errors) &&
      updateCorrespondenceResponse?.data?.Success
    ) {
      return <Alert severity="success">{t("details.successSave")}</Alert>
    } else if (
      hasError(updateCorrespondenceResponse.data?.Error) ||
      hasError(updateCorrespondenceResponse.data?.Errors)
    ) {
      let errorMsg = ""
      const errors = updateContactResponse.data?.Errors?.map((error) => {
        return error.Message
      })
      if (errors) {
        errorMsg = errors.join(", ")
      }
      return (
        <Alert severity="error">
          {t("details.unexpectedError")}
          {updateCorrespondenceResponse.data.Error}
          {errorMsg}
        </Alert>
      )
    }
  }

  const renderAlertContact = () => {
    if (updateContactResponse?.isLoading) {
      return (
        <>
          <Alert severity="info">{t("details.formSaving")}</Alert>
          <LoadingMeta eventMeta={updateContactResponse} noDetailsBox={true} />
        </>
      )
    } else if (
      !hasError(updateContactResponse.data?.Error) &&
      !hasError(updateContactResponse.data?.Errors) &&
      updateContactResponse?.data?.Success
    ) {
      return <Alert severity="success">{t("details.successSave")}</Alert>
    } else if (hasError(updateContactResponse.data?.Error) || hasError(updateContactResponse.data?.Errors)) {
      let errorMsg = ""
      const errors = updateContactResponse.data?.Errors?.map((error) => {
        return error.Message
      })
      if (errors) {
        errorMsg = errors.join(", ")
      }
      return (
        <Alert severity="error">
          {t("details.unexpectedError")}
          {updateContactResponse.data.Error}
          {errorMsg}
        </Alert>
      )
    }
  }

  const renderCurrentStep = () => {
    if (generalForm.recordType === RecordType.PastContact) {
      if (activeStep === 0) {
        return renderFirstStep()
      }
      if (activeStep === 1) {
        if (contactResponse.data.ListItems) {
          return (
            <ParticipantSelect
              readOnly={readOnly}
              error={contactFormErrors.Participants}
              selections={contactResponse.data.ListItems.Participants || []}
              participants={contactForm.Participants || []}
              changeParticipantStatus={false}
              setParticipants={(item) => {
                setContactForm((prev) => ({
                  ...prev,
                  Participants: item,
                }))
              }}
            />
          )
        } else {
          return <LoadingMeta eventMeta={contactResponse} noDetailsBox={true} />
        }
      }
      if (activeStep === 2) {
        if (contactResponse.data.ListItems) {
          return (
            <ContactForm
              readOnly={readOnly}
              isLoading={true}
              setDateState={setDatePicker}
              selections={contactResponse.data.ListItems}
              contactForm={contactForm}
              setContactForm={setContactForm}
              contactFormErrors={contactFormErrors}
            />
          )
        } else {
          return <LoadingMeta eventMeta={contactResponse} noDetailsBox={true} />
        }
      }
    }

    if (generalForm.recordType === RecordType.Correspondence) {
      if (activeStep === 0) {
        return renderFirstStep()
      }
      if (activeStep === 1) {
        if (
          correspondenceResponse.isLoaded &&
          correspondenceResponse.data &&
          correspondenceResponse.data.ListItems &&
          correspondenceResponse.data.Template
        ) {
          return (
            <ParticipantSelect
              readOnly={readOnly}
              error={correspondenceFormErrors["Participants"]}
              selections={correspondenceResponse.data.Template.Participants || []}
              changeParticipantStatus={false}
              participants={correspondenceForm.Participants || []}
              setParticipants={(item) => {
                setCorrespondenceForm((prev) => ({
                  ...prev,
                  Participants: item,
                }))
              }}
            />
          )
        } else {
          return <LoadingMeta eventMeta={correspondenceResponse} noDetailsBox={true} />
        }
      }
      if (activeStep === 2) {
        if (
          correspondenceResponse.isLoaded &&
          correspondenceResponse.data &&
          correspondenceResponse.data.ListItems &&
          correspondenceResponse.data.Template
        ) {
          return (
            <CorrespondenceForm
              readOnly={readOnly}
              setDateState={setDatePicker}
              clientName={
                props.fileDetails.ClientDetails &&
                `${props.fileDetails.ClientDetails?.LastName}, ${props.fileDetails.ClientDetails?.FirstName}`
              }
              selections={correspondenceResponse.data.ListItems}
              correspondenceForm={correspondenceForm}
              setCorrespondenceForm={setCorrespondenceForm}
              correspondenceFormErrors={correspondenceFormErrors}
            />
          )
        } else {
          return <LoadingMeta eventMeta={correspondenceResponse} noDetailsBox={true} />
        }
      }

      if (activeStep === 3) {
        if (
          correspondenceResponse.isLoaded &&
          correspondenceResponse.data &&
          correspondenceResponse.data.ListItems &&
          correspondenceResponse.data.Template
        ) {
          return (
            <AdditionalForm
              readOnly={readOnly}
              isFormChecked={isFormChecked}
              errors={additionalFormErrors}
              additonalParametersList={correspondenceResponse.data.Template.AdditonalParameters || []}
              additonalParameters={correspondenceForm.AdditonalParameters || []}
              change={(value: AdditonalParameter[]) => {
                setCorrespondenceForm((prev) => {
                  return {
                    ...prev,
                    AdditonalParameters: value,
                  }
                })
              }}
            />
          )
        } else {
          return <LoadingMeta eventMeta={correspondenceResponse} noDetailsBox={true} />
        }
      }
    }
  }

  const renderFirstStep = () => {
    return (
      <Stack sx={{ gap: "8px" }}>
        <Typography variant="body2">{t("details.pleaseChooseRecord")}</Typography>
        <RadioGroup
          value={generalForm.recordType}
          onChange={(e) => {
            modifyGeneralForm("recordType", parseInt(e.target.value, 10))
          }}
        >
          {recordTypeOptions.map((recordType) => (
            <FormControlLabel
              key={recordType.label}
              value={recordType.value}
              control={<Radio readOnly={readOnly} />}
              label={<Typography variant="body2">{t(recordType.label as any)}</Typography>}
            />
          ))}
        </RadioGroup>
        {generalForm.recordType === RecordType.Correspondence && (
          <Stack sx={{ gap: "10px", width: "300px" }}>
            <LoadingMeta eventMeta={correspondenceMetaResponse} noDetailsBox={true} />
            {correspondenceMetaResponse.isLoaded && !!correspondenceMetaResponse.data?.DocumentTypes?.length && (
              <>
                <FormControl variant="standard" fullWidth>
                  <Autocomplete
                    readOnly={readOnly}
                    value={
                      correspondenceForm.DocumentTypeId
                        ? {
                            DocumentTypeId: correspondenceForm.DocumentTypeId || 0,
                            Name:
                              correspondenceMetaResponse.data?.DocumentTypes?.find(
                                (x) => x.DocumentTypeId === correspondenceForm.DocumentTypeId
                              )?.Name || "",
                          }
                        : null
                    }
                    onChange={(e, value) =>
                      modifyCorrespondenceForm({
                        DocumentTypeId: value ? value.DocumentTypeId : 0,
                        TemplateId: 0,
                      })
                    }
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(a, b) => a.DocumentTypeId === b.DocumentTypeId}
                    options={correspondenceMetaResponse.data?.DocumentTypes || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        variant="standard"
                        label={t("details.type")}
                        placeholder={t("details.type")}
                        error={!!correspondenceFormErrors.DocumentTypeId}
                        helperText={
                          !!correspondenceFormErrors.DocumentTypeId && correspondenceFormErrors.DocumentTypeId
                        }
                      />
                    )}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    openOnFocus={true}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <Autocomplete
                    readOnly={readOnly}
                    value={
                      correspondenceForm.TemplateId
                        ? {
                            TemplateId: correspondenceForm.TemplateId || 0,
                            Name:
                              documentTemplates?.find((x) => x.TemplateId === correspondenceForm.TemplateId)?.Name ||
                              "",
                            Description: "",
                          }
                        : null
                    }
                    onChange={(e, value) => modifyCorrespondenceForm({ TemplateId: value ? value.TemplateId : 0 })}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(a, b) => a.TemplateId === b.TemplateId}
                    options={documentTemplates || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        variant="standard"
                        label={t("details.template")}
                        placeholder={t("details.template")}
                        error={!!correspondenceFormErrors.TemplateId}
                        helperText={!!correspondenceFormErrors.TemplateId && correspondenceFormErrors.TemplateId}
                      />
                    )}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    openOnFocus={true}
                  />
                </FormControl>
              </>
            )}
          </Stack>
        )}
      </Stack>
    )
  }

  const close = () => {
    if (isUpdateLoading) {
      return
    }
    props.close()
  }

  return (
    <>
      <Box ref={dialogRef} sx={styles.dialogBox}>
        <CloseIcon
          fontSize="small"
          sx={styles.closeIcon}
          onClick={() => {
            close()
          }}
        />
        <Stack sx={styles.dialogContent}>
          <Stack sx={styles.titleAndStepperBox}>
            <Stack gap="8px">
              <Title text={"Neuer Verlauf"} />
              <Box>
                <Typography component="span" variant="body1" fontSize="16px">
                  für
                </Typography>{" "}
                <Typography component="span" variant="subtitle1" border="none" fontSize="16px">
                  {props.fileDetails.FileNumber} | {props.fileDetails.ClientDetails?.LastName},{" "}
                  {props.fileDetails.ClientDetails?.FirstName}
                </Typography>
              </Box>
            </Stack>
            <Stepper activeStep={activeStep} sx={styles.stepperBox}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepButton color="inherit" sx={styles.muiProgress}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {renderAlert()}
          </Stack>
          <div style={styles.fullWidthBorder}></div>
          <Stack sx={isUpdateLoading ? { ...styles.disabledBox, ...styles.stepContent } : styles.stepContent}>
            {!isSuccess && isInitialized && renderCurrentStep()}
            {isSuccess && (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  onClick={() => {
                    close()
                  }}
                  variant="contained"
                >
                  {t("details.closeWindow")}
                </Button>
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
      {!isSuccess && isInitialized && (
        <DialogActions>
          <Stack
            sx={{
              ...styles.buttonsBox,
              width: "100%",
              padding: "0 22px 9px 22px",
            }}
          >
            <Stack sx={styles.leftButtonsBox}>
              {closeable && (
                <Button
                  tabIndex={-11}
                  onClick={() => {
                    props.close()
                  }}
                  sx={styles.cancelButton}
                >
                  <Typography fontSize="14px" fontWeight="700">
                    {t("details.cancel")}
                  </Typography>
                </Button>
              )}

              {activeStep !== 0 && (
                <Button tabIndex={-10} onClick={handlePrevious} sx={styles.cancelButton}>
                  <Typography fontSize="14px" fontWeight="700">
                    {t("details.previousStep")}
                  </Typography>
                </Button>
              )}
            </Stack>
            {activeStep === steps.length - 1 ? (
              <Button onClick={() => {}} sx={styles.nextButton} variant="contained">
                <Typography fontSize="14px" fontWeight="500">
                  {t("details.add")}
                </Typography>
              </Button>
            ) : (
              <Button onClick={handleNext} sx={styles.nextButton} variant="contained">
                <Typography fontSize="14px" fontWeight="500">
                  {t("details.nextStep")}
                </Typography>
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}
    </>
  )
}
